import React from 'react';
import CheckCircleIcon from '@patternfly/react-icons/dist/esm/icons/check-circle-icon';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/esm/icons/exclamation-circle-icon';
import ExclamationTriangleIcon from '@patternfly/react-icons/dist/esm/icons/exclamation-triangle-icon';
import QuestionCircleIcon from '@patternfly/react-icons/dist/esm/icons/question-circle-icon';
import { Spinner } from '@patternfly/react-core';

const GreenLight = '#3E8635';
const YellowLight = '#F0AB00';
const RedLight = '#C9190B';

const GreenDark = '#1E4F18';
const YellowDark = '#795600';
const RedDark = '#A30000';

export const ColorStringFromLogLevel = (s: string, level: string, fullname: string, index) => {
  switch (level.toLowerCase()) {
    case 'trace':
      return <p key={fullname + '_m_' + index}>{s}</p>;
    case 'debug':
      return <p key={fullname + '_m_' + index}>{s}</p>;
    case 'info':
      return <p key={fullname + '_m_' + index}>{s}</p>;
    case 'warning':
      return (
        <p key={fullname + '_m_' + index} style={{ color: YellowLight }}>
          {s}
        </p>
      );
    case 'error':
      return (
        <p key={fullname + '_m_' + index} style={{ color: RedLight }}>
          {s}
        </p>
      );
    default:
      return <p key={fullname + '_m_' + index}>{s}</p>;
  }
};

export const ColorStringFromStatus = (s: string, status: string, fullname: string, index) => {
  switch (status.toLowerCase()) {
    case 'success':
      return (
        <p key={fullname + '_s_' + index} style={{ color: GreenLight }}>
          {s}
        </p>
      );
    case 'deleted':
      return (
        <p key={fullname + '_s_' + index} style={{ color: GreenLight }}>
          {s}
        </p>
      );
    case 'pending':
      return (
        <p key={fullname + '_s_' + index} style={{ color: YellowLight }}>
          {s}
        </p>
      );
    case 'unresponsive':
      return (
        <p key={fullname + '_s_' + index} style={{ color: YellowLight }}>
          {s}
        </p>
      );
    case 'processing':
      return (
        <p key={fullname + '_s_' + index} style={{ color: YellowLight }}>
          {s}
        </p>
      );
    case 'error':
      return (
        <p key={fullname + '_s_' + index} style={{ color: RedLight }}>
          {s}
        </p>
      );
    default:
      return <p key={fullname + '_s_' + index}>{s}</p>;
  }
};

export const GetIconFromStatus = (status: string) => {
  switch (status.toLowerCase()) {
    case 'success':
      return <CheckCircleIcon color={GreenLight} />;
    case 'deleted':
      return <CheckCircleIcon color={GreenLight} />;
    case 'pending':
      return <Spinner size="sm" />;
    case 'unresponsive':
      return <ExclamationTriangleIcon color={YellowLight} />;
    case 'processing':
      return <Spinner size="sm" />;
    case 'error':
      return <ExclamationCircleIcon color={RedLight} />;
    default:
      return <QuestionCircleIcon />;
  }
};

export const LocalizeDate = (date: string | undefined) => {
  if (date === '' || date === undefined) {
    return '';
  }

  var d = new Date(date);

  if (d.valueOf() == 0) {
    return '';
  }

  return d.toLocaleString();
};
