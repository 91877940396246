// This component renders an adjustable sidebar that can be resized by the user. 
// It uses the useSidebar hook to access and modify the sidebar state.
// Key features:
// 	• Drag handle for resizing
// 	• Minimum and maximum width constraints
// 	• Smooth resizing animation




import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSidebar } from './SidebarContext';
import './Sidebar.css';

const AdjustableSidebar: React.FC = () => {
  const { isSidebarOpen, sidebarContent, sidebarWidth, setSidebarWidth, } = useSidebar();
  const [isDragging, setIsDragging] = useState(false);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const dragStartX = useRef<number>(0);
  const dragStartWidth = useRef<number>(sidebarWidth);

  const handleMouseDown = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    setIsDragging(true);
    dragStartX.current = e.clientX;
    dragStartWidth.current = sidebarWidth;
  }, [sidebarWidth]);

  const handleMouseMove = useCallback((e: MouseEvent) => {
    if (!isDragging) return;
    const deltaX = dragStartX.current - e.clientX;
    const newWidth = Math.max(200, Math.min(800, dragStartWidth.current + deltaX));
    setSidebarWidth(newWidth);
  }, [isDragging, setSidebarWidth]);

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    } else {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, handleMouseMove, handleMouseUp]);

  if (!isSidebarOpen) {
    return null;
  }

  return (
    <div
      className={`adjustable-sidebar ${isDragging ? 'dragging' : ''}`}
      style={{ width: `${sidebarWidth}px` }}
      ref={sidebarRef}
    >
      <div
        className="resize-handle"
        onMouseDown={handleMouseDown}
      />
      <div className="sidebar-content">
        {sidebarContent}
      </div>
    </div>
  );
  };

export default AdjustableSidebar;