import * as React from 'react';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import {
  PageSection,
  Card,
  CardHeader,
  CardBody,
  Alert,
  Button,
  Split,
  Title,
  SplitItem,
  Grid,
  GridItem,
  Text,
  TextVariants,
} from '@patternfly/react-core';
import { sortable, headerCol, cellWidth } from '@patternfly/react-table';
import { GetResourcesResponse } from '@mergetb/api/portal/v1/realize_types';
import { ActionList } from '@app/lib/ActionList';
import { useFetch } from 'use-http';

const Resources: React.FunctionComponent = () => {
  const resourceview_last = (localStorage.getItem('resourceview') ?? 'true') === 'true';
  const conf = React.useContext(GeneralSettingsContext);
  const [viewLabel, setViewLabel] = React.useState('View ' + (resourceview_last ? 'Only Available' : 'All'));
  const [resourceView, setResourceView] = React.useState(resourceview_last);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [noResults, setNoResults] = React.useState(false);
  const [totalResources, setTotalResources] = React.useState({
    cores: 0,
    memory: 0,
    freeCores: 0,
    freeMemory: 0,
  });

  const options = { credentials: 'include', cachePolicy: 'no-cache' };
  const { loading, error, get, response } = useFetch(conf.api + '/realize/resources', options, []);
  const [resourcesData, setResourcesData] = React.useState({});

  const { api } = React.useContext(GeneralSettingsContext);

  const columns = [
    { title: 'Facility', cellTransforms: [headerCol()], transforms: [sortable, cellWidth(15)] },
    { title: 'Resource', cellTransforms: [headerCol()], transforms: [sortable, cellWidth(15)] },
    { title: 'Allocation Mode', transforms: [sortable, cellWidth(15)] },
    { title: 'Experiment Nodes', transforms: [sortable, cellWidth(20)] },
    { title: 'Free Cores', transforms: [sortable, cellWidth(10)] },
    { title: 'Free Memory', transforms: [sortable, cellWidth(15)] },
  ];

  const load = React.useCallback(async () => {
    await get();
    if (response.ok) {
      response.json().then((json) => {
        setResourcesData(json);
      });
    } else {
      // setLoadError(resp);
    }
  }, [get, response]);

  React.useEffect(() => {
    load();
  }, [load]);

  const toggleView = () => {
    const newView = !resourceView;
    setViewLabel('View ' + (newView ? 'All' : 'Only Available'));
    localStorage.setItem('resourceview', newView.toString());
    setResourceView(newView);
  };

  const handleSearchResults = (hasResults: boolean) => {
    setNoResults(!hasResults);
  };

  const header = (
    <PageSection>
      <Split>
        <SplitItem>
          <Title headingLevel="h1" size="lg">
            Testbed Resources
          </Title>
        </SplitItem>
        <SplitItem isFilled />
        <SplitItem>
          <Button variant="control" aria-label={viewLabel} onClick={toggleView}>
            {viewLabel}
          </Button>
        </SplitItem>
      </Split>
    </PageSection>
  );

  const mapper = React.useCallback(
    (json) => {
      const rs = GetResourcesResponse.fromJSON(json);
      const rows = [];
      let totalCores = 0;
      let totalMem = 0;
      let totalFreeCores = 0;
      let totalFreeMem = 0;

      rs.resources.forEach((r) => {
        const facility = r.resource?.facility || '';
        const resourceName = r.resource?.id || '';
        let mode = 'Idle';
        const expNodes = [];
        let freeCores = 0;
        let freeMem = 0;

        let resourceCores = 0;
        let resourceMem = 0;
        let usedCores = 0;
        let usedMem = 0;

        r.resource?.procs?.forEach((p) => {
          resourceCores += p.cores;
          totalCores += p.cores;
        });
        r.resource?.memory?.forEach((m) => {
          resourceMem += m.capacity;
          totalMem += m.capacity;
        });

        r.allocated.forEach((a) => {
          usedCores += a.coresUsed;
          usedMem += a.memoryUsed;
          expNodes.push(`${a.rid}.${a.eid}.${a.pid}[${a.node}]`);
        });

        freeCores = resourceCores - usedCores;
        freeMem = resourceMem - usedMem;
        totalFreeCores += freeCores;
        totalFreeMem += freeMem;

        if (r.allocated.length === 0) {
          mode = 'Idle';
        } else if (r.allocated.length >= 2 || r.allocated.every((a) => a.virt)) {
          mode = 'Hypervisor';
        } else {
          mode = 'Bare Metal';
        }

        rows.push({
          facility,
          resource: resourceName,
          allocation_mode: mode,
          experiment_nodes: expNodes.join(', '),
          free_cores: freeCores,
          free_memory: formatBytes(freeMem), // Format the memory here
        });
      });

      setTotalResources({
        cores: totalCores,
        memory: totalMem,
        freeCores: totalFreeCores,
        freeMemory: totalFreeMem,
      });

      // Filter rows based on resourceView
      /// resourceView === true means view all resources else filter to only available.
      const filteredRows = resourceView
        ? rows
        : rows.filter((row) => !(row.free_cores == 0 || parseInt(row.free_memory) == 0));

      return filteredRows;
    },
    [resourceView]
  );

  return (
    <React.Fragment>
      {header}
      <PageSection>
        <Card>
          <CardHeader>Total Resources</CardHeader>
          <CardBody>
            <Grid hasGutter>
              <GridItem span={3}>
                <Text component={TextVariants.h3}>Cores</Text>
                <Text component={TextVariants.p}>{totalResources.cores}</Text>
              </GridItem>
              <GridItem span={3}>
                <Text component={TextVariants.h3}>Memory</Text>
                <Text component={TextVariants.p}>{formatBytes(totalResources.memory)}</Text>
              </GridItem>
              <GridItem span={3}>
                <Text component={TextVariants.h3}>Free Cores</Text>
                <Text component={TextVariants.p}>{totalResources.freeCores}</Text>
              </GridItem>
              <GridItem span={3}>
                <Text component={TextVariants.h3}>Free Memory</Text>
                <Text component={TextVariants.p}>{formatBytes(totalResources.freeMemory)}</Text>
              </GridItem>
            </Grid>
          </CardBody>
        </Card>
      </PageSection>
      <PageSection>
        <Card>
          <CardHeader>Testbed Resources</CardHeader>
          <CardBody>
            <div style={{ overflowX: 'auto', marginTop: '16px' }}>
              <ActionList
                kind="Resources"
                columns={columns}
                rows={resourcesData}
                mapper={mapper}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                onSearchResults={handleSearchResults}
                isCardView={false}
                reloadTrigger={resourceView ? 1 : 0}
                reload={resourceView ? 1 : 0}
              />
            </div>
            {noResults && (
              <Alert variant="info" title="No resources found" isInline>
                No resources match your search criteria. Try adjusting your search.
              </Alert>
            )}
          </CardBody>
        </Card>
      </PageSection>
    </React.Fragment>
  );
};

function formatBytes(bytes, decimals = 0) {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export { Resources };
