import React from 'react';
import { Table /* data-codemods */, Thead, Tr, Th, Tbody, Td } from '@patternfly/react-table';

export type DetailEntry = {
  label: string;
  value: any;
};

interface DetailsTableProps {
  label: string;
  entries: Array<DetailEntry>;
}

export const DetailsTable: React.FunctionComponent<DetailsTableProps> = ({ label, entries }) => {
  return (
    <>
      <Table aria-label={label} variant={'compact'} borders={false}>
        <Thead>
          <Tr>
            {entries.map((e, i) => {
              return <Th key={i}>{e.label}</Th>;
            })}
          </Tr>
        </Thead>
        <Tbody>
          <Tr key={'details'}>
            {entries.map((e, i) => {
              return (
                <Td dataLabel={e.label} key={i}>
                  {e.value}
                </Td>
              );
            })}
          </Tr>
        </Tbody>
      </Table>
    </>
  );
};
