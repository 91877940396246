import * as React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { accessibleRouteChangeHandler } from '@app/utils/utils';
import { UpdateSettings } from '@app/Settings/Update/UpdateSettings';
import { NotFound } from '@app/NotFound/NotFound';
import { useDocumentTitle } from '@app/utils/useDocumentTitle';

import { Dashboard } from '@app/Dashboard/Dashboard';

import { AppLayout } from '@app/AppLayout/AppLayout';
import { FullpageLayout } from '@app/FullpageLayout/FullpageLayout';
import { News } from '@app/News/News';
import { User } from '@app/User/User';
import { Users } from '@app/Users/Users';
import { Projects } from '@app/Projects/Projects';
import { Organizations } from '@app/Organizations/Organizations';
import { Organization } from '@app/Organization/Organization';
import { Project } from '@app/Project/Project';
import { Experiments } from '@app/Experiments/Experiments';
import { Experiment } from '@app/Experiment/Experiment';
import { Revision } from '@app/Revision/Revision';
import { Models } from '@app/Models/Models';
//import { Facilities } from '@app/Facilities/Facilities';
//import { Pools } from '@app/Pools/Pools';
import { Realization } from '@app/Realization/Realization';
import { Realizations } from '@app/Realizations/Realizations';
import { Resources } from '@app/Resources/Resources';
import { Materializations } from '@app/Materializations/Materializations';
import { Materialization } from '@app/Materialization/Materialization';
import { XDC } from '@app/XDC/XDC';
import { XDCs } from '@app/XDCs/XDCs';
import { MergeLoginPage } from '@app/LoginPage/LoginPage';
import { Register } from '@app/Register/Register';
import { RegisterUserInfo } from '@app/UserInfo/RegisterUserInfo';
import { UpdateUserInfo } from '@app/UserInfo/UpdateUserInfo';
import { Recovery } from '@app/Recovery/Recovery';
import { ErrorPage } from '@app/Error/Error';

import { useTranslation } from 'react-i18next';
import { toTitleCase } from '@app/lib/util';
import Tutorials from './Tutorials/Tutorials';

let routeFocusTimer: number;
export interface IAppRoute {
  label?: string; // Excluding the label will exclude the route from the nav sidebar in AppLayout
  /* eslint-disable @typescript-eslint/no-explicit-any */
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  /* eslint-enable @typescript-eslint/no-explicit-any */
  exact?: boolean;
  path: string;
  title: string;
  isAsync?: boolean;
  routes?: undefined;
}

export interface IAppRouteGroup {
  label: string;
  isExapanded?: boolean;
  routes: IAppRoute[];
}

export type AppRouteConfig = IAppRoute | IAppRouteGroup;

// Support for pages rendered in a full page payout.
// Each path needs to be added to fullpageRoutePaths as well
// have an entry in fullpageRoutes. All other routes use
// the AppLayout layout which includes the header, sidebar, etc.
const fullpageRoutePaths: Array<string> = ['/login', '/registration', '/recovery', '/verification', '/userinfo'];
const fullpageRoutes: AppRouteConfig[] = [
  {
    component: MergeLoginPage,
    exact: true,
    path: '/login',
    title: 'Merge Portal | Login',
  },
  {
    component: Register,
    exact: true,
    path: '/registration',
    title: 'Merge Portal | Register',
  },
  {
    component: RegisterUserInfo,
    exact: true,
    path: '/userinfo',
    title: 'Merge Portal | User Account Information',
  },
  {
    component: Recovery,
    exact: true,
    path: '/recovery',
    title: 'Merge Portal | Account Recovery',
  },
];

//
// All the "in app" routes which use the AppLayout layout.
//
const routes = (t: TFunction<'translation', undefined>): AppRouteConfig[] => {
  return [
    {
      component: Dashboard,
      exact: true,
      path: '/',
      label: 'Dashboard',
      title: 'Merge Portal | Dashboard',
    },
    {
      component: News,
      exact: true,
      isAsync: true,
      path: '/news',
      title: 'Merge Portal | News',
    },
    {
      component: Users,
      exact: true,
      isAsync: true,
      label: 'Users',
      path: '/user',
      title: 'Merge Portal | Users',
    },
    {
      component: User,
      exact: true,
      isAsync: true,
      path: '/user/:uid',
      title: 'Merge Portal',
    },
    {
      component: Organizations,
      exact: true,
      isAsync: true,
      label: 'Organizations',
      path: '/organization',
      title: 'Merge Portal | Organizations',
    },
    {
      component: Organization,
      exact: true,
      path: '/organization/:oid',
      title: 'Merge Portal | Organization',
    },
    {
      component: Projects,
      exact: true,
      isAsync: true,
      label: 'Projects',
      path: '/project',
      title: 'Merge Portal | Projects',
    },
    {
      component: Project,
      exact: true,
      path: '/project/:pid',
      title: 'Merge Portal',
    },
    /*
               {
    component: Identity,
    exact: true,
    isAsync: true,
    label: 'Identity',
    path: '/identity',
    title: 'Merge Portal | Identity Management',
    },
    */
    {
      label: 'Experiments',
      isExapanded: true,
      routes: [
        {
          component: Experiments,
          exact: true,
          isAsync: true,
          label: 'Manage',
          path: '/experiment',
          title: 'Merge Portal | Experiment Management',
        },
        {
          component: Realizations,
          exact: true,
          isAsync: true,
          label: toTitleCase(t('realizations')),
          path: '/realizations',
          title: 'Merge Portal | Realization Management',
        },
        {
          component: Materializations,
          exact: true,
          isAsync: true,
          label: toTitleCase(t('materializations')),
          path: '/materializations',
          title: 'Merge Portal | Materialization Management',
        },
      ],
    },
    {
      component: Experiment,
      exact: true,
      isAsync: true,
      path: '/project/:pid/experiment/:eid',
      title: 'Merge Portal',
    },
    {
      component: Revision,
      exact: true,
      isAsync: true,
      path: '/model/:pid/:eid/:rev',
      title: 'Merge Portal',
    },

    /*
       {
component: Pools,
exact: true,
isAsync: true,
label: 'Pools',
path: '/pools',
title: 'Merge Portal | Pool Management',
},
{
component: Facilities,
exact: true,
isAsync: true,
label: 'Facilities',
path: '/facilities',
title: 'Merge Portal | Facility Management',
},
*/
    {
      component: Realization,
      exact: true,
      isAsync: true,
      path: '/realizations/:pid/:eid/:rid',
      title: 'Merge Portal',
    },
    {
      component: Materialization,
      exact: true,
      isAsync: true,
      path: '/materializations/:pid/:eid/:rid',
      title: 'Merge Portal',
    },
    {
      component: XDCs,
      exact: true,
      isAsync: true,
      label: 'XDCs',
      path: '/xdcs',
      title: 'Merge Portal | XDC Management',
    },
    {
      component: XDC,
      exact: true,
      isAsync: true,
      path: '/xdcs/:pid/:xid',
      title: 'Merge Portal',
    },
    {
      component: Models,
      exact: true,
      isAsync: true,
      label: 'Model Editor',
      path: '/models',
      title: 'Merge Portal | Model Editor',
    },
    {
      component: Resources,
      exact: true,
      label: 'Resources',
      isAsync: true,
      path: '/resources',
      title: 'Merge Portal | Testbed Resources',
    },
    {
      component: Tutorials,
      exact: false,
      isAsync: true,
      label: 'Tutorials',
      path: '/tutorials',
      title: 'Merge Portal | Tutorials',
    },
    {
      component: UpdateSettings,
      exact: true,
      path: '/settings',
      title: 'Merge Portal | Update User Identity Profile',
    },
    {
      component: UpdateUserInfo,
      exact: true,
      path: '/updateuserinfo',
      title: 'Merge Portal | Update Merge Portal Profile',
    },
    {
      component: ErrorPage,
      exact: true,
      path: '/error',
      title: 'Merge Portal | Error',
    },
  ];
};

// a custom hook for sending focus to the primary content container
// after a view has loaded so that subsequent press of tab key
// sends focus directly to relevant content
// const useA11yRouteChange = (isAsync: boolean) => {
//   const lastNavigation = useLastLocation();
//   React.useEffect(() => {
//     if (!isAsync && lastNavigation !== null) {
//       routeFocusTimer = accessibleRouteChangeHandler();
//     }
//     return () => {
//       window.clearTimeout(routeFocusTimer);
//     };
//   }, [isAsync, lastNavigation]);
// };

const RouteWithTitleUpdates = ({ component: Component, isAsync = false, title, ...rest }: IAppRoute) => {
  //   useA11yRouteChange(isAsync);
  useDocumentTitle(title);

  function routeWithTitle(routeProps: RouteComponentProps) {
    return <Component {...rest} {...routeProps} />;
  }

  return <Route render={routeWithTitle} {...rest} />;
};

const PageNotFound = ({ title }: { title: string }) => {
  useDocumentTitle(title);
  return <Route component={NotFound} />;
};

const flattenedFullpageRoutes: IAppRoute[] = fullpageRoutes.reduce(
  (flattened, route) => [...flattened, ...(route.routes ? route.routes : [route])],
  [] as IAppRoute[]
);

const flattenedRoutes = (name: string): IAppRoute[] => {
  return routes(name).reduce(
    (flattened, route) => [...flattened, ...(route.routes ? route.routes : [route])],
    [] as IAppRoute[]
  );
};

const AppRoutes = (): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <Switch>
      <Route exact={true} path={fullpageRoutePaths}>
        <FullpageLayout>
          <Switch>
            {flattenedFullpageRoutes.map(({ path, exact, component, title, isAsync }, idx) => (
              <RouteWithTitleUpdates
                path={path}
                exact={exact}
                component={component}
                key={idx}
                title={title}
                isAsync={isAsync}
              />
            ))}
            <PageNotFound title="404 Page Not Found" />
          </Switch>
        </FullpageLayout>
      </Route>

      <Route>
        <AppLayout>
          <Switch>
            {flattenedRoutes(t).map(({ path, exact, component, title, isAsync }, idx) => (
              <RouteWithTitleUpdates
                path={path}
                exact={exact}
                component={component}
                key={idx}
                title={title}
                isAsync={isAsync}
              />
            ))}
            <PageNotFound title="404 Page Not Found" />
          </Switch>
        </AppLayout>
      </Route>
    </Switch>
  );
};

export { AppRoutes, routes };
