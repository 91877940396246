import React from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Badge,
  Text,
  TextContent,
  TextVariants,
  Grid,
  GridItem,
  Button,
  Split,
  SplitItem,
  Flex,
  FlexItem,
  Label,
} from '@patternfly/react-core';
import { Link } from 'react-router-dom';
import { ProjectDiagramIcon, UsersIcon, FolderOpenIcon, TagIcon, BuildingIcon, FlaskIcon } from '@patternfly/react-icons';
import { KebabMenu } from '@app/lib/KebabMenu';

interface ProjectCardProps {
  data: any;
  actions: any[];
}

const ProjectCard: React.FC<ProjectCardProps> = ({ data, actions }) => {
  return (
    <Card isHoverable className="p-4 bg-gray-50 shadow-sm hover:shadow-md transition-shadow duration-300" style={{ width: '350px', margin: '10px' }}>
      <CardHeader>
        <Split hasGutter>
          <SplitItem isFilled>
            <CardTitle className="text-xl font-semibold">
              <Flex alignItems={{ default: 'alignItemsCenter' }}>
                <FlexItem className="text-blue-500 mr-2">
                  <ProjectDiagramIcon />
                </FlexItem>
                <FlexItem>{data.name.title}</FlexItem>
              </Flex>
            </CardTitle>
          </SplitItem>
          <SplitItem>
            <Badge className="bg-blue-500 text-white px-2 py-1 rounded">{data.accessMode}</Badge>
          </SplitItem>
          <SplitItem>
            <KebabMenu actions={actions} row={data} isCardView={true} />
          </SplitItem>
        </Split>
      </CardHeader>
      <CardBody>
        <Grid hasGutter className="mt-4">
          <GridItem span={12}>
            <TextContent>
              <Text className="text-gray-600">{data.description}</Text>
            </TextContent>
          </GridItem>
          <GridItem span={12} className="my-3">
            <Flex>
              <FlexItem>
                <Label className="bg-blue-100 text-blue-800 px-2 py-1 rounded mr-2">{data.category}</Label>
              </FlexItem>
              <FlexItem>
                <Label className="bg-cyan-100 text-cyan-800 px-2 py-1 rounded">{data.subcategory}</Label>
              </FlexItem>
            </Flex>
          </GridItem>
          <GridItem span={6}>
            <TextContent>
              <Text component={TextVariants.small} className="text-gray-600">
                <Flex alignItems={{ default: 'alignItemsCenter' }}>
                  <FlexItem className="text-blue-500 mr-2">
                    <UsersIcon />
                  </FlexItem>
                  <FlexItem>Members: {data.members.title}</FlexItem>
                </Flex>
              </Text>
              <Text component={TextVariants.small} className="text-gray-600 mt-1">
                <Flex alignItems={{ default: 'alignItemsCenter' }}>
                  <FlexItem className="text-blue-500 mr-2">
                    <FlaskIcon />
                  </FlexItem>
                  <FlexItem>Experiments: {data.experiments.title}</FlexItem>
                </Flex>
              </Text>
            </TextContent>
          </GridItem>
          <GridItem span={6}>
            <TextContent>
              <Text component={TextVariants.small} className="text-gray-600">
                <Flex alignItems={{ default: 'alignItemsCenter' }}>
                  <FlexItem className="text-blue-500 mr-2">
                    <TagIcon />
                  </FlexItem>
                  <FlexItem>Category: {data.category}</FlexItem>
                </Flex>
              </Text>
              <Text component={TextVariants.small} className="text-gray-600 mt-1">
                <Flex alignItems={{ default: 'alignItemsCenter' }}>
                  <FlexItem className="text-blue-500 mr-2">
                    <BuildingIcon />
                  </FlexItem>
                  <FlexItem>Organization: {data.organization.title}</FlexItem>
                </Flex>
              </Text>
            </TextContent>
          </GridItem>
        </Grid>
      </CardBody>
      <CardFooter className="mt-4">
        <Button 
          variant="link" 
          component={(props) => <Link {...props} to={`/project/${data.name.props.text}`} />}
          className="text-blue-600 hover:text-blue-800"
        >
          View Details
        </Button>
      </CardFooter>
    </Card>
  );
};

export { ProjectCard };