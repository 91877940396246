import * as React from 'react';
import { Page, SkipToContent } from '@patternfly/react-core';

interface IAppLayout {
  children: React.ReactNode;
}

const FullpageLayout: React.FunctionComponent<IAppLayout> = ({ children }) => {
  const [isMobileView, setIsMobileView] = React.useState(true);

  const onPageResize = (props: { mobileView: boolean; windowSize: number }) => {
    setIsMobileView(props.mobileView);
  };

  const PageSkipToContent = <SkipToContent href="#primary-app-container">Skip to Content</SkipToContent>;

  return (
    <Page mainContainerId="primary-app-container" onPageResize={(_event, props: { mobileView: boolean; windowSize: number }) => onPageResize(props)} skipToContent={PageSkipToContent}>
      {children}
    </Page>
  );
};

export { FullpageLayout };
