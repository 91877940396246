import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { SettingsFlow, UpdateSettingsFlowBody } from '@ory/client';
import { NodeMessages, UserSettingsCard, UserSettingsFlowType } from '@ory/elements';
import { Card, CardTitle, CardBody, Spinner, Modal, Button } from '@patternfly/react-core';
import ory, { sdkError } from '@app/lib/OrySdk';

const UpdateSettings: React.FunctionComponent = () => {
  const [flow, setFlow] = React.useState<SettingsFlow>();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = React.useState(false);
  const [pendingPasswordChange, setPendingPasswordChange] = React.useState<UpdateSettingsFlowBody | null>(null);
  const params = new URLSearchParams(document.location.search);
  const loginChallenge = params.get('login_challenge');
  const history = useHistory();

  // const setDisabled = (name: string, flow: SettingsFlow): SettingsFlow => {
  //   flow.ui.nodes.forEach((n) => {
  //     if (name === n?.attributes.name) {
  //       n.attributes.disabled = true;
  //     }
  //   });
  //   return flow;
  // };

  // Get the flow based on the flowId in the URL (.e.g redirect to this page after flow initialized)
  const getFlow = React.useCallback(
    (flowId: string) =>
      ory
        // the flow data contains the form fields, error messages and csrf token
        .getSettingsFlow({ id: flowId })
        .then(({ data: flow }) => setFlow(flow))
        .catch(sdkErrorHandler),
    []
  );

  // initialize the sdkError for generic handling of errors
  const sdkErrorHandler = sdkError(getFlow, setFlow, '/settings', true);

  const createFlow = () => {
    ory
      // create a new settings flow
      // the flow contains the form fields, error messages and csrf token
      // depending on the Ory Network project settings, the form fields returned may vary
      .createBrowserSettingsFlow()
      .then(({ data: flow }) => {
        // Update URI query params to include flow id
        const params = new URLSearchParams({ ['flow']: flow.id });
        history.push({ pathname: location.pathname, search: params.toString() });
        // Set the flow data
        setFlow(flow);
      })
      .catch(sdkErrorHandler);
  };

  // submit any of the settings form data to Ory
  const onSubmit = (body: UpdateSettingsFlowBody) => {
    if (body.method === 'password') {
      setPendingPasswordChange(body);
      setIsConfirmModalOpen(true);
    } else {
      submitSettingsFlow(body);
    }
  };

  const submitSettingsFlow = (body: UpdateSettingsFlowBody) => {
    // something unexpected went wrong and the flow was not set
    if (!flow) return history.push('/settings', { replace: true });

    ory
      // submit the form data the user provided to Ory
      .updateSettingsFlow({ flow: flow.id, updateSettingsFlowBody: body })
      .then(({ data: flow }) => {
        setFlow(flow);
        if (body.method === 'password') {
          alert('Password changed successfully');
        }
      })
      .catch(sdkErrorHandler);
  };

  const handleConfirmPasswordChange = () => {
    if (pendingPasswordChange) {
      submitSettingsFlow(pendingPasswordChange);
      setIsConfirmModalOpen(false);
      setPendingPasswordChange(null);
    }
  };

  React.useEffect(() => {
    // we might redirect to this page after the flow is initialized, so we check for the flowId in the URL
    const flowId = params.get('flow');
    // the flow already exists
    if (flowId) {
      getFlow(flowId).catch(createFlow); // if for some reason the flow has expired, we need to get a new one
      return;
    }
    createFlow();
  }, []);

  return (
    <React.Fragment>
      <Card id="updatessettings-card">
        <CardTitle>Change Identity Profile</CardTitle>
        <CardBody>
          {flow ? (
            <>
              <NodeMessages uiMessages={flow.ui.messages} />
              {(['password', 'totp', 'webauthn', 'lookup_secret', 'oidc'] as UserSettingsFlowType[]).map(
                (flowType: UserSettingsFlowType, index) => (
                  // here we render the settings flow using Ory Elements
                  <UserSettingsCard
                    key={index}
                    // we always need to pass the component the flow since it contains the form fields, error messages and csrf token
                    flow={flow}
                    method={flowType}
                    // include scripts for webauthn support
                    includeScripts={true}
                    // submit the form data the user provides to Ory
                    onSubmit={({ body }) => onSubmit(body as UpdateSettingsFlowBody)}
                  />
                )
              )}
            </>
          ) : (
            <Spinner />
          )}
        </CardBody>
      </Card>
      <Modal
        title="Confirm Password Change"
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        actions={[
          <Button key="confirm" variant="primary" onClick={handleConfirmPasswordChange}>
            Confirm
          </Button>,
          <Button key="cancel" variant="link" onClick={() => setIsConfirmModalOpen(false)}>
            Cancel
          </Button>,
        ]}
      >
        <p>Are you sure you want to change your password?</p>
      </Modal>
    </React.Fragment>
  );
};

export { UpdateSettings };

