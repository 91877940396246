import * as React from 'react';
import { useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  PageSection,
  Title,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Alert,
  Bullseye,
  Spinner,
  Breadcrumb,
  BreadcrumbItem,
  Popover,
  Button,
  Tooltip,
  Text,
  GridItem,
  Grid,
} from '@patternfly/react-core';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import { useFetch } from 'use-http';
import { ActionList } from '@app/lib/ActionList';
import { sortable, headerCol } from '@patternfly/react-table';
import { Experiment, GetExperimentResponse } from '@mergetb/api/portal/v1/workspace_types';
import { DetailEntry, DetailsTable } from '@app/lib/DetailsTable';
import { TaskStatusTable } from '@app/lib/TaskStatusTable';
import { toTitleCase } from '@app/lib/util';
import { GRPCError } from '@app/lib/error';

import { useTranslation } from 'react-i18next';

// exp json example
// {
//   "experiment":  {
//     "name":  "aaa",
//     "project":  "aaa",
//     "models":  {
//       "a4227a99e12fec40038fe2e5b8a3970bc4850a23":  {
//         "compiled":  true,
//         "msg":  "Succeeded",
//         "compileTime":  "2021-08-16T20:49:06.849481137Z"
//       }
//     },
//     "repository":  "https://git.dorvan.mergetb.net/aaa/aaa",
//     "creator":  "glawler",
//     "ver":  "2"
//   }
// }

const Experiment: React.FunctionComponent = () => {
  const { pid, eid } = useParams();
  const conf = useContext(GeneralSettingsContext);

  const options = { credentials: 'include', cachePolicy: 'no-cache' };
  // we request status for experiments, as it includes in progress compiliations
  const st_url = conf.api + '/project/' + pid + '/experiment/' + eid + '?statusMS=-1';
  const [exp, setExp] = React.useState<Experiment>();
  const [loadError, setLoadError] = React.useState<GRPCError>();
  const { loading, error, get: getExp, response } = useFetch(st_url, options, []);

  const loadExp = React.useCallback(async () => {
    const resp = await getExp();
    if (response.ok) {
      setExp(GetExperimentResponse.fromJSON(resp).experiment);
    } else {
      setLoadError(resp);
    }
  }, [getExp, response]);

  React.useEffect(() => {
    loadExp();
  }, [loadExp]);

  const crumbs = (
    <PageSection>
      <Breadcrumb>
        <BreadcrumbItem to="/project">Projects</BreadcrumbItem>
        <BreadcrumbItem to={'/project/' + pid}>{pid}</BreadcrumbItem>
        <BreadcrumbItem to="/experiment">Experiments</BreadcrumbItem>
        <BreadcrumbItem>{eid}</BreadcrumbItem>
      </Breadcrumb>
    </PageSection>
  );

  const st_getter = (data) => {
    return data.status;
  };

  const details: Array<DetailEntry> = exp
    ? [
        { label: 'Name', value: exp.name },
        { label: 'Project', value: <Link to={'/project/' + exp.project}>{exp.project}</Link> },
        {
          label: 'Repository',
          value: (
            <a href={exp.repository} target="_blank" rel="noopener noreferrer">
              {exp.repository}
            </a>
          ),
        },
        { label: 'Description', value: exp.description },
        { label: 'Creator', value: <Link to={'/user/' + exp.creator}>{exp.creator}</Link> },
        { label: 'Maintainers', value: exp.maintainers.join(', ') },
      ]
    : [];

  const revisions = (
    <React.Fragment>
      {error && loadError && (
        <Alert variant="danger" title="Response Error">
          {loadError.message}
        </Alert>
      )}
      {loading && !error && (
        <Bullseye>
          <Spinner size="sm" />
        </Bullseye>
      )}
      {exp && <Revisions pid={exp.project} eid={exp.name} models={exp.models} />}
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {crumbs}
      <PageSection>
        <Title headingLevel="h1" size="3xl">
          Experiment: {pid}.{eid}
        </Title>
      </PageSection>
      <PageSection>
        <Grid hasGutter>
          <GridItem>
            <Card id="deetsCard">
              <CardHeader>
                <CardTitle id="deetsCardTitle">Details</CardTitle>
              </CardHeader>
              <CardBody>
                {error && (
                  <Alert variant="danger" title="Error">
                    Error loading
                  </Alert>
                )}
                {error && loadError && (
                  <Alert variant="danger" title="Response Error">
                    {loadError.message}
                  </Alert>
                )}
                {loading && (
                  <Bullseye>
                    <Spinner size="sm" />
                  </Bullseye>
                )}
                {exp && <DetailsTable label="expdeets" entries={details} />}
              </CardBody>
            </Card>
          </GridItem>
          <GridItem>{revisions}</GridItem>
          <GridItem>
            <Card id="deetsCard">
              <CardHeader>
                <CardTitle id="statusCardTitle">Status</CardTitle>
              </CardHeader>
              <CardBody>
                <TaskStatusTable
                  kind={pid + '-tst'}
                  url={st_url}
                  getter={st_getter}
                  ongoingfrequency={5000}
                  completedfrequency={60000}
                  scalingfrequency={1.0 / 10.0}
                />
              </CardBody>
            </Card>
          </GridItem>
        </Grid>
      </PageSection>
    </React.Fragment>
  );
};

type ExpRevProps = {
  pid: string;
  eid: string;
  models: any;
};

const Revisions: React.FunctionComponent<ExpRevProps> = ({ pid, eid, models }) => {
  const { t } = useTranslation();
  const cols = [
    { title: 'Revision', cellTransforms: [headerCol()], transforms: [sortable] },
    { title: toTitleCase(t('realizations')) },
    { title: 'Compilation' },
    { title: 'Timestamp', cellTransforms: [headerCol()], transforms: [sortable] },
  ];

  const keys = Object.keys(models).sort((a, b) => {
    const d1 = Date.parse(models[a].compileTime);
    const d2 = Date.parse(models[b].compileTime);
    return d2 - d1;
  });

  const rows = keys.map((revision) => ({
    revision: {
      title: (
        <Tooltip content={revision}>
          <Link to={`/model/${pid}/${eid}/${revision}`}>{revision.substring(0, 8)}</Link>
        </Tooltip>
      ),
      props: { text: revision },
    },
    realizations: (
      <React.Fragment>
        {models[revision].realizations.map((rlz, ri) => (
          <React.Fragment key={ri}>
            <Link to={`/realizations/${pid}/${eid}/${rlz}`}>{rlz}</Link>
            <br />
          </React.Fragment>
        ))}
      </React.Fragment>
    ),
    compilation: models[revision].compiled ? (
      models[revision].msg === 'Succeeded' ? (
        'Succeeded'
      ) : (
        'Failed'
      )
    ) : models[revision].msg.includes('compiling') ? (
      <Button variant="warning">{models[revision].msg}</Button>
    ) : (
      <Popover
        aria-label="Compilation Error"
        headerContent={<div>Compilation Error Message</div>}
        bodyContent={<div>{models[revision].msg}</div>}
      >
        <Button variant="danger">Compile Error</Button>
      </Popover>
    ),
    timestamp: new Date(models[revision].compileTime).toLocaleString(),
  }));

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <CardTitle id="expModelsTitle">Models</CardTitle>
        </CardHeader>
        <CardBody>
          <ActionList kind="revisions" columns={cols} rows={rows} />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export { Experiment };
