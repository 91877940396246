import * as React from 'react';

import { LoginPage, ListVariant } from '@patternfly/react-core';
import { UserInfo } from '@app/UserInfo/UserInfo';

import launchLogo from '@app/bgimages/launch-logo.png';

const RegisterUserInfo: React.FunctionComponent = () => {
  return (
    <LoginPage
      loginTitle={'Configure Merge User Account'}
      // footerListItems={footerListItems}
      footerListVariants={ListVariant.inline}
      brandImgSrc={launchLogo}
      brandImgAlt="Merge Launch"
      backgroundImgSrc="/images/bgimage.png"
      textContent={
        "Once you have registered, you will need to contact a Merge Portal operator or your organization's Portal adminstrator to initialize and activate your account. Until your account is approved you will be able to login, but have access to no Merge resources."
      }
      backgroundImgSrc="/images/bgimage.png"
    >
      <UserInfo existingUser={false} />
    </LoginPage>
  );
};

export { RegisterUserInfo };
