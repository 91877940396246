import * as React from 'react';
import { GetRealizationsResponse } from '@mergetb/api/portal/v1/realize_types';
import { GetMaterializationsResponse } from '@mergetb/api/portal/v1/materialize_types';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import { Icon } from '@patternfly/react-core';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/esm/icons/exclamation-circle-icon';
import CheckCircleIcon from '@patternfly/react-icons/dist/esm/icons/check-circle-icon';
import {
  Card,
  CardTitle,
  CardBody,
  List,
  ListItem,
  Button,
  Split,
  SplitItem,
  Tooltip,
  AlertProps,
  Text,
} from '@patternfly/react-core';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { toTitleCase } from '@app/lib/util';

type InactiveExperimentsProps = {
  mtzs: GetMaterializationsResponse | undefined;
  rlzs: GetRealizationsResponse | undefined;
  addAlert: (title: string, variant: AlertProps['variant']) => void;
  reload: () => void;
};

type rlzStatus = {
  name: string;
  status: boolean;
};

const InactiveExperiments: React.FC<InactiveExperimentsProps> = ({ mtzs, rlzs, addAlert, reload }) => {
  const conf = React.useContext(GeneralSettingsContext);
  const { t } = useTranslation();

  const inactive = React.useMemo(() => {
    if (rlzs && mtzs) {
      // find rlz that do not exist in mzts. Those are the realized, but not materializes experiments.
      const rzArray = rlzs.results.map((r) => {
        return {
          name: r.realization?.id + '.' + r.realization?.eid + '.' + r.realization?.pid,
          status: r.diagnostics?.value.length === 0,
        };
      });
      const mzArray = mtzs.materializations.map((m) => m?.rid + '.' + m?.eid + '.' + m?.pid);

      // find the difference in the sets.
      return rzArray.filter((x) => !mzArray.includes(x.name));
    }
    return undefined;
  }, [mtzs, rlzs]);

  const onRelinquish = (rlz: string) => {
    const x = rlz.split('.');
    fetch(conf.api + '/realize/realizations/' + x[2] + '/' + x[1] + '/' + x[0], {
      method: 'DELETE',
      credentials: 'include',
    })
      .then((response) => {
        if (response.ok) {
          reload();
          addAlert(toTitleCase(t('realization')) + ' ' + rlz + ' ' + t('relinquished'), 'success');
        }
      })
      .catch((err) => {
        addAlert('Error ' + t('relinquishing') + ' ' + rlz + ': ' + err, 'danger');
      });
  };

  const onMtz = (rlz: string) => {
    const x = rlz.split('.');
    fetch(conf.api + '/materialize/materialize/' + x[2] + '/' + x[1] + '/' + x[0], {
      method: 'PUT',
      credentials: 'include',
    })
      .then((response) => {
        if (response.ok) {
          reload();
          addAlert(toTitleCase(t('realization')) + ' ' + rlz + ' ' + t('materialization') + ' started', 'success');
        }
      })
      .catch((err) => {
        addAlert(toTitleCase(t('materialize')) + ' Error: ' + err, 'danger');
      });
  };

  const relTTContent = (
    <>
      When you {t('relinquish')} an experiment, all resources reserved including nodes and network components are
      released and can be used by other experimenters. If the experiment is {t('materialized')}, it will be{' '}
      {t('dematerialized')} first - then {t('relinquished')}.
    </>
  );

  const matTTContent = (
    <div>
      When an experiment is {t('materialized')}, all resources allocated are powered on (or created) and configured.
      After the
      {t('materialization')} is complete, the experiment is ready to be used.
    </div>
  );
  return (
    <Card isFullHeight>
      <CardTitle>Allocated, But Inactive Experiments ({toTitleCase(t('realizations'))})</CardTitle>
      <CardBody>
        <List isPlain>
          {inactive && inactive.length === 0 && <Text>You have no allocated and inactive experiments.</Text>}
          {inactive &&
            inactive.map((r, i) => {
              const x = r.name.split('.');
              return (
                <ListItem key={i}>
                  <Split hasGutter isWrappable>
                    <SplitItem>
                      {r.status === false ? (
                        <Tooltip
                          content={
                            <div>
                              This is a failed resource allocation. The resources are not allocated to this experiment.
                              This is usually caused by a lack of availble resources of the number and type requested.
                            </div>
                          }
                        >
                          <Icon status="danger">
                            <ExclamationCircleIcon />
                          </Icon>
                        </Tooltip>
                      ) : (
                        <Tooltip content={<div>This experiment has resources reserved for exclusive use.</div>}>
                          <Icon status="success">
                            <CheckCircleIcon />
                          </Icon>
                        </Tooltip>
                      )}
                    </SplitItem>
                    <SplitItem isFilled>
                      <Link to={'/realizations/' + x[2] + '/' + x[1] + '/' + x[0]}>
                        {r.name.length > 25 ? r.name.slice(0, 25) + '...' : r.name}
                      </Link>
                    </SplitItem>
                    <SplitItem>
                      <Tooltip content={relTTContent}>
                        <Button onClick={() => onRelinquish(r.name)} size="sm" variant="secondary">
                          {toTitleCase(t('relinquish'))}
                        </Button>
                      </Tooltip>
                    </SplitItem>
                    <SplitItem>
                      <Tooltip content={matTTContent}>
                        <Button isDisabled={!r.status} onClick={() => onMtz(r.name)} size="sm" variant="secondary">
                          {toTitleCase(t('materialize'))}
                        </Button>
                      </Tooltip>
                    </SplitItem>
                  </Split>
                </ListItem>
              );
            })}
        </List>
      </CardBody>
    </Card>
  );
};

export { InactiveExperiments };
