import * as React from 'react';
import {
  Alert,
  AlertGroup,
  AlertVariant,
  PageSection,
  Title,
  Form,
  FormGroup,
  FormAlert,
  FormHelperText,
  FormSelect,
  FormSelectOption,
  ActionGroup,
  Button,
  TextInput,
  Split,
  SplitItem,
  Modal,
  ModalVariant,
  Breadcrumb,
  BreadcrumbItem,
  HelperText,
  HelperTextItem,
  Flex,
  FlexItem,
  ButtonVariant,
  Text,
  TextContent,
  Tooltip,
  Popover,
} from '@patternfly/react-core';
import { ActionList } from '@app/lib/ActionList';
import { Link } from 'react-router-dom';
import { sortable, headerCol } from '@patternfly/react-table';
import { FetchSelect } from '@app/lib/FetchSelect';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import { XDCCard } from './XDCCard';
import { BallotOutlined, GridViewOutlined } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { InfoCircleIcon } from '@patternfly/react-icons';
import HelpIcon from '@patternfly/react-icons/dist/esm/icons/help-icon';
import styles from '@patternfly/react-styles/css/components/Form/form';

// Custom styled tooltip
const StyledTooltip = styled(Tooltip)({
  '& .pf-v5-c-tooltip__content': {
    backgroundColor: 'white',
    color: 'black',
    boxShadow: 'none',
    border: 'none',
    padding: '10px',
  },
  '& .pf-v5-c-tooltip__arrow': {
    display: 'none',
  },
});

const StyledBallotOutlined = styled(BallotOutlined)(({ theme }) => ({
  fontSize: '24px',
  color: 'inherit',
  marginRight: '8px',
  verticalAlign: 'middle',
}));

const StyledGridViewOutlined = styled(GridViewOutlined)(({ theme }) => ({
  fontSize: '24px',
  color: 'inherit',
  marginRight: '8px',
  verticalAlign: 'middle',
}));
import { AttachXDCRequest, xDCTypeFromJSON } from '@mergetb/api/portal/v1/xdc_types';
import { eventEmitter } from '@app/Tutorials/EventEmitter';

type XDCProps = {
  fxid: string | undefined;
  fpid: string | undefined;
  filter: boolean;
};

const XDCs: React.FunctionComponent<XDCProps> = ({ fxid, fpid, filter }) => {
  const conf = React.useContext(GeneralSettingsContext);

  const [reload, setReload] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [url, setUrl] = React.useState(conf.api + '/xdc/list/');
  const [reloadTrigger, setReloadTrigger] = React.useState(0);

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [xdc, setXdc] = React.useState({ value: '', valid: 'error' });
  const [invalidXDC, setInvalidXDC] = React.useState('');
  const [pid, setPid] = React.useState({ value: '', valid: 'error' });
  const [xdctype, setXDCType] = React.useState('personal');
  const [alerts, setAlerts] = React.useState([]);

  // attach dialog
  const [attachIsOpen, setAttachIsOpen] = React.useState(false);
  const [attachTo, setAttachTo] = React.useState('');
  const [attachXDC, setAttachXDC] = React.useState('');

  const [isCardView, setIsCardView] = React.useState(false);
  const [noResults, setNoResults] = React.useState(false);

  const handleViewChange = (newView: 'table' | 'card') => {
    setIsCardView(newView === 'card');
  };

  const handleSearchResults = (hasResults: boolean) => {
    setNoResults(!hasResults);
  };

  const onXDCTypeChange = (value: string) => {
    setXDCType(value);
  };

  const columns = [
    { title: 'Name', cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'Project', cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'Type', cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'Attached', cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'URL', cellTransforms: [], transforms: [sortable] },
    { title: 'SSH Name', cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'Creator', cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'Memory Limit', cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'CPU Limit', cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'Image', cellTransforms: [headerCol()], transforms: [sortable] },
  ];

  const fcolumns = [
    { title: 'Name' },
    { title: 'Project' },
    { title: 'Attached' },
    { title: 'URL' },
    { title: 'SSH Name' },
    { title: 'Creator' },
    { title: 'Memory Limit' },
    { title: 'CPU Limit' },
    { title: 'Image' },
  ];

  const actions = [
    {
      title: 'Attach',
      onClick: (event, rowId, rowData) => {
        setAttachXDC(rowData.name.props.text + '.' + rowData.project.props.text);
        setXDCType(rowData.type);
        setAttachIsOpen(true);
      },
    },
    {
      title: 'Detach',
      onClick: (event, rowId, rowData) => {
        const xdc = rowData.name.props.text;
        const mtz = rowData.attached.props.text;
        const pid = rowData.project.props.text;
        const [rid, eid, _] = mtz.split('.');

        fetch(conf.api + '/xdc/attach/' + xdc + '/' + pid, {
          method: 'DELETE',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            experiment: eid,
            realization: rid,
          }),
        })
          .then((resp) => {
            if (resp.ok) {
              setReloadTrigger((prev) => prev + 1);
              addAlert('XDC ' + xdc + ' detached from ' + mtz, 'success');
              eventEmitter.emit('xdcDetached', { xdcName: xdc, materializationName: mtz });
            }
          })
          .catch((error) => {
            addAlert(error.message, 'danger');
          });
      },
    },
    {
      title: 'Delete',
      onClick: (event, rowId, rowData) => {
        const xdc = rowData.name.props.text;
        const pid = rowData.project.props.text;
        fetch(conf.api + '/xdc/instance/' + xdc + '/' + pid, {
          method: 'DELETE',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then((resp) => {
            if (resp.ok) {
              return resp.json();
            }
            throw new Error('Failed to delete XDC');
          })
          .then(() => {
            setReloadTrigger((prev) => prev + 1);
            addAlert('XDC deleted: ' + xdc, 'success');
          })
          .catch((error) => {
            console.error('Error deleting XDC:', error);
            addAlert(error.message, 'danger');
          });
      },
    },
  ];

  let xdcrow = (x) => {
    const [xid, pid] = x.name.split('.');
    const [mrid, meid, mpid] = x.materialization.split('.');

    return [
      // name
      {
        title: (
          <Link to={`/xdcs/${pid}/${xid}`} text={xid}>
            {xid}
          </Link>
        ),
      },

      // project
      {
        title: (
          <Link to={`/project/${pid}`} text={pid}>
            {pid}
          </Link>
        ),
      },

      // type
      x.type,

      // attached
      {
        title: (
          <Link to={'/materializations/' + mpid + '/' + meid + '/' + mrid} text={x.materialization}>
            {x.materialization}
          </Link>
        ),
      },

      // juypter URL
      {
        title: (
          <a href={x.url} target="_blank">
            Jupyter
          </a>
        ),
      },

      // SSH Name
      x.fqdn.split('.')[0],

      // creator
      x.creator,

      // mem limit
      x.memlimit,

      // cpu limit
      x.cpulimit,

      // image
      x.image,
    ];
  };

  let mapper = React.useCallback ((json) => {
    return json.XDCs.map((x) => ({
      name: {
        title: <Link to={`/xdcs/${x.name.split('.')[1]}/${x.name.split('.')[0]}`}>{x.name.split('.')[0]}</Link>,
        props: { text: x.name.split('.')[0] },
      },
      project: {
        title: <Link to={`/project/${x.name.split('.')[1]}`}>{x.name.split('.')[1]}</Link>,
        props: { text: x.name.split('.')[1] },
      },
      type: x.type,
      attached: {
        type: x.type,
        title: (
          <Link
            to={`/materializations/${x.materialization.split('.')[2]}/${x.materialization.split('.')[1]}/${
              x.materialization.split('.')[0]
            }`}
          >
            {x.materialization}
          </Link>
        ),
        props: { text: x.materialization },
      },
      url: {
        title: (
          <a href={x.url} target="_blank" rel="noopener noreferrer">
            Jupyter
          </a>
        ),
        props: { text: x.url },
      },
      ssh_name: x.fqdn.split('.')[0],
      creator: x.creator,
      memory_limit: x.memlimit,
      cpu_limit: x.cpulimit,
      image: x.image,
    }));
  }, []);

  let fmapper = (json) => {
    return [xdcrow(json.xdc)];
  };

  const addAlert = (t, v) => {
    setAlerts((prev) => [...prev, { title: t, variant: v }]);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const submitForm = () => {
    fetch(conf.api + '/xdc/instance/' + xdc.value + '/' + pid.value, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        xdc: xdc.value,
        project: pid.value,
        type: xDCTypeFromJSON(xdctype),
      }),
    })
      .then((response) => {
        if (response.ok) {
          setReloadTrigger((prev) => prev + 1);
          toggleModal();
          addAlert('XDC created: ' + xdc.value, 'success');
          // Emit the xdcCreated event
          eventEmitter.emit('xdcCreated', { xdcName: xdc.value });
          return;
        } else {
          return response.text().then((text) => {
            throw new Error(text);
          });
        }
      })
      .catch((error) => {
        console.log(error);
        addAlert(error.message, 'danger');
      });
  };

  const submitAttach = () => {
    // "attachXDC" is the xdc name to attach to mtz "attachTo"
    // xdc.project ==> rlz.eid.pidS
    const xdcTkns = attachXDC.split('.');
    const mtzTkns = attachTo.split('.');

    const req = {
      xdc: attachXDC,
      project: xdcTkns[1],
      realization: mtzTkns[0],
      experiment: mtzTkns[1],
      realizationProject: mtzTkns[2],
    };

    console.log('attach request', JSON.stringify(req));

    fetch(conf.api + '/xdc/attach/' + xdcTkns[0] + '/' + xdcTkns[1] + '/' + mtzTkns[1] + '/' + mtzTkns[0], {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(req),
    })
      .then((resp) => {
        if (resp.ok) {
          setAttachIsOpen(false);
          setAttachXDC('');
          setAttachTo('');
          setReloadTrigger((prev) => prev + 1);
          addAlert('XDC ' + attachXDC + ' attached to ' + attachTo, 'success');
          // Emit the xdcAttached event
          eventEmitter.emit('xdcAttached', { xdcName: attachXDC });
          return;
        } else {
          return resp.text().then((text) => {
            throw new Error(text);
          });
        }
      })
      .catch((error) => {
        console.log(error);
        addAlert(error.message, 'danger');
      });
  };

  const onPidSelect = (val) => {
    setPid({ value: val, valid: val === '' ? 'error' : 'success' });
  };

  function mapProj(json: any): Array<string> {
    return json.projects.map((p) => p.name);
  }

  function mapMtzs(json: any): Array<string> {
    const [_, pid] = attachXDC.split('.');
    return json.materializations
      .map((m) => {
        if (xdctype == 'personal' || m.pid == pid) {
          // personal can attach to any; shared only to the same project
          return m.rid + '.' + m.eid + '.' + m.pid;
        }
        return 'EMPTY';
      })
      .filter((e) => {
        return e !== 'EMPTY';
      }); // there is likely a better way to do this.
  }

  const onXDCChange = (event) => {
    const val = event.target.value;
    const re = /^[a-z]([a-z0-9]*[a-z0-9])?$/;
    if (re.test(val) === false) {
      setXdc({ value: val, valid: 'error' });
      setInvalidXDC('The XDC name must start with a lowercase and only contain lowercase alphanumeric characters');
    } else {
      setInvalidXDC('');
      setXdc({ value: val, valid: val === '' ? 'error' : 'success' });
    }
  };

  const crumbs = (
    <PageSection>
      <Breadcrumb>
        <BreadcrumbItem>XDCs</BreadcrumbItem>
      </Breadcrumb>
    </PageSection>
  );

  const header = (
    <PageSection>
      <Split>
        <SplitItem>
          <Flex alignItems={{ default: 'alignItemsCenter' }} style={{ gap: '8px' }}>
            <FlexItem>
              <Title headingLevel="h1" size="2xl">
                Experiment Development Containers (XDCs)
              </Title>
            </FlexItem>
            <FlexItem>
              <StyledTooltip
                content={
                  <TextContent>
                    <Text>
                      An Experiment Development Container (XDC) is a dedicated environment for developing and managing
                      experiments on the Merge testbed. XDCs provide a consistent, isolated workspace with necessary
                      tools and access to testbed resources, enabling efficient experiment creation, modification, and
                      execution.
                    </Text>
                  </TextContent>
                }
                position="right"
              >
                <Button variant="plain" aria-label="More info for XDCs" style={{ padding: 0 }}>
                  <InfoCircleIcon />
                </Button>
              </StyledTooltip>
            </FlexItem>
          </Flex>
        </SplitItem>
        <SplitItem isFilled />
        <SplitItem>
          <Button variant="control" aria-label="New XDC" onClick={toggleModal}>
            New XDC
          </Button>
        </SplitItem>
      </Split>
    </PageSection>
  );

  const notready = xdc.valid !== 'success' || pid.valid !== 'success';

  const newModal = (
    <Modal
      isOpen={isModalOpen}
      onClose={toggleModal}
      variant={ModalVariant.medium}
      aria-label="New Experiment Development Container"
    >
      <React.Fragment>
        <Title headingLevel="h1" size="2xl">
          New Experiment Development Container
        </Title>
        <Form isHorizontal>
          {notready && (
            <FormAlert>
              <Alert variant="danger" title="All fields must be filled" aria-live="polite" isInline />
            </FormAlert>
          )}
          <FormGroup fieldId="project" label="Project">
            <FetchSelect label="Project" url={conf.api + '/project'} onSelect={onPidSelect} mapItems={mapProj} />
          </FormGroup>
          <FormGroup fieldId="xdcname" label="Name">
            <TextInput type="text" id="name" value={xdc.value} onChange={onXDCChange} />
            <HelperText>
              {xdc.valid === 'success' ? (
                <HelperTextItem>Valid</HelperTextItem>
              ) : (
                <HelperTextItem variant="error">{invalidXDC}</HelperTextItem>
              )}
            </HelperText>
          </FormGroup>
          <FormGroup
            fieldId="xdctype"
            label="Type"
            labelIcon={
              <Popover
                bodyContent={
                  <div>
                    Information on the differences among XDC types can be found{' '}
                    <a
                      href="https://mergetb.gitlab.io/testbeds/sphere/sphere-docs/docs/experimentation/xdc/#differences-between-_shared_-and-_personal_-xdcs"
                      target="_blank"
                      rel="noreferrer"
                    >
                      here
                    </a>
                    .
                  </div>
                }
              >
                <button
                  type="button"
                  aria-label="More info for name field"
                  onClick={(e) => e.preventDefault()}
                  aria-describedby="simple-form-name-02"
                  className={styles.formGroupLabelHelp}
                >
                  <HelpIcon />
                </button>
              </Popover>
            }
          >
            <FormSelect value={xdctype} aria-label="Type" onChange={(_, value) => onXDCTypeChange(value as string)}>
              <FormSelectOption key={0} value="personal" label="personal" />
              <FormSelectOption key={1} value="shared" label="shared" />
            </FormSelect>
          </FormGroup>
          <ActionGroup>
            <Button variant="primary" onClick={submitForm} isDisabled={notready} isAriaDisabled={notready}>
              Submit
            </Button>
          </ActionGroup>
        </Form>
      </React.Fragment>
    </Modal>
  );

  const attachModel = (
    <Modal
      isOpen={attachIsOpen}
      onClose={() => setAttachIsOpen(!attachIsOpen)}
      variant={ModalVariant.medium}
      aria-label="Attach to Materialization"
    >
      <React.Fragment>
        <Title headingLevel="h1" size="2xl">
          Attach to Materialization
        </Title>
        <Form isHorizontal>
          <FormGroup fieldId="xdcname" label="XDC" isRequired={true}>
            <TextInput isRequired type="text" id="name" value={attachXDC} isDisabled={true} />
          </FormGroup>
          <FormGroup fieldId="mtz" label="Materialization" isRequired={true}>
            <FetchSelect
              label="Materialization"
              url={conf.api + '/materialize/materializations'}
              onSelect={setAttachTo}
              mapItems={mapMtzs}
            />
          </FormGroup>
          <ActionGroup>
            <Button variant="primary" onClick={submitAttach}>
              Attach
            </Button>
          </ActionGroup>
        </Form>
      </React.Fragment>
    </Modal>
  );

  const notifications = (
    <AlertGroup isToast>
      {alerts.map((a, i) => (
        <Alert variant={AlertVariant[a.var]} title={a.title} timeout={9000} key={i} />
      ))}
    </AlertGroup>
  );

  if (filter) {
    return (
      <React.Fragment>
        {alerts.length !== 0 && notifications}
        {attachModel}
        <ActionList
          kind="XDCs"
          columns={columns}
          url={url}
          actions={actions}
          mapper={mapper}
          reload={reload}
          reloadTrigger={reloadTrigger}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          onSearchResults={handleSearchResults}
        />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {alerts.length !== 0 && notifications}
      {crumbs}
      {newModal}
      {attachModel}
      {header}
      <PageSection>
        <Flex alignItems={{ default: 'alignItemsCenter' }} spaceItems={{ default: 'spaceItemsMd' }}>
          <FlexItem>
            <Button
              variant={isCardView ? ButtonVariant.control : ButtonVariant.primary}
              onClick={() => handleViewChange('table')}
              aria-label="Table view"
              style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <StyledBallotOutlined />
              <span style={{ lineHeight: 1 }}>Table</span>
            </Button>
          </FlexItem>
          <FlexItem>
            <Button
              variant={isCardView ? ButtonVariant.primary : ButtonVariant.control}
              onClick={() => handleViewChange('card')}
              aria-label="Card view"
              style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <StyledGridViewOutlined />
              <span style={{ lineHeight: 1 }}>Card</span>
            </Button>
          </FlexItem>
        </Flex>
        <div style={{ overflowX: 'auto', marginTop: '16px' }}>
          <ActionList
            kind="XDCs"
            columns={columns}
            url={url}
            actions={actions}
            mapper={mapper}
            reload={reload}
            reloadTrigger={reloadTrigger}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            onSearchResults={handleSearchResults}
            isCardView={isCardView}
            CardComponent={XDCCard}
          />
        </div>
        {noResults && (
          <Alert variant="info" title="No XDCs found" isInline>
            No XDCs match your search criteria. Try adjusting your search.
          </Alert>
        )}
      </PageSection>
    </React.Fragment>
  );
};

export { XDCs };
