import * as React from 'react';
import {
  PageSection,
  Title,
  Breadcrumb,
  BreadcrumbItem,
  Alert,
  AlertGroup,
  AlertVariant,
  Switch,
  ToggleGroup,
  ToggleGroupItem,
  Flex,
  FlexItem,
  Button,
  ButtonVariant,
  TextContent,
  TextVariants,
  Tooltip,
  Text,
} from '@patternfly/react-core';
import { Link } from 'react-router-dom';
import { ActionList } from '@app/lib/ActionList';
import { sortable, headerCol, cellWidth } from '@patternfly/react-table';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import {
  GetUsersResponse,
  userStateToJSON,
  accessModeToJSON,
  ActivateUserRequest,
} from '@mergetb/api/portal/v1/workspace_types';
import { AuthContext } from '@app/lib/AuthProvider';
import { UserCard } from './UserCard';
import { TableIcon, ThLargeIcon } from '@patternfly/react-icons';
import { BallotOutlined, GridViewOutlined } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { InfoCircleIcon } from '@patternfly/react-icons';

// Custom styled tooltip
const StyledTooltip = styled(Tooltip)({
  '& .pf-v5-c-tooltip__content': {
    backgroundColor: 'white',
    color: 'black',
    boxShadow: 'none',
    border: 'none',
    padding: '10px',
  },
  '& .pf-v5-c-tooltip__arrow': {
    display: 'none',
  },
});

const StyledBallotOutlined = styled(BallotOutlined)(({ theme }) => ({
  fontSize: '24px',
  color: 'inherit',
  marginRight: '8px',
  verticalAlign: 'middle',
}));

const StyledGridViewOutlined = styled(GridViewOutlined)(({ theme }) => ({
  fontSize: '24px',
  color: 'inherit',
  marginRight: '8px',
  verticalAlign: 'middle',
}));

const Users: React.FunctionComponent = () => {
  const conf = React.useContext(GeneralSettingsContext);
  const { isAdmin } = React.useContext(AuthContext);
  const [reload, setReload] = React.useState(0);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [reloadTrigger, setReloadTrigger] = React.useState(0);
  const [noResults, setNoResults] = React.useState(false);
  const [isCardView, setIsCardView] = React.useState(false);

  const triggerReload = () => {
    setReloadTrigger((prev) => prev + 1);
  };

  const toggleView = () => {
    setIsCardView(!isCardView);
  };

  type alertProps = {
    title: string;
    variant: AlertVariant;
  };
  const [alerts, setAlerts] = React.useState<Array<alertProps>>([]);

  const columns = [
    { title: 'Username', cellTransforms: [headerCol()], transforms: [sortable, cellWidth(10)] },
    { title: 'Full Name', cellTransforms: [headerCol()], transforms: [sortable, cellWidth(15)] },
    { title: 'Institution', transforms: [sortable, cellWidth(15)] },
    { title: 'Category', transforms: [sortable, cellWidth(10)] },
    { title: 'Country', transforms: [sortable, cellWidth(10)] },
    { title: 'US State', transforms: [sortable, cellWidth(10)] },
    { title: 'Account State', transforms: [sortable, cellWidth(10)] },
    { title: 'Access Mode', transforms: [sortable, cellWidth(10)] },
    { title: 'Projects', cellTransforms: [headerCol()], transforms: [cellWidth(15)] },
    { title: 'Organizations', cellTransforms: [headerCol()], transforms: [cellWidth(15)] },
    { title: 'Experiments', cellTransforms: [headerCol()], transforms: [cellWidth(15)] },
    { title: 'UID', cellTransforms: [headerCol()], transforms: [cellWidth(10)] },
    { title: 'GID', cellTransforms: [headerCol()], transforms: [cellWidth(10)] },
  ];

  const actions = React.useMemo(() => {
    if (!isAdmin) {
      return [];
    }

    const addAlert = (t: string, v: AlertVariant) => {
      setAlerts((prev) => {
        return [...prev, { title: t, variant: v }];
      });
      setReload((reload) => reload + 1);
    };

    const userAction = (method: string, url: string, message: string) => {
      fetch(conf.api + url, {
        method: method,
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((resp) => {
          if (!resp.ok) {
            return resp
              .json()
              .catch(() => {
                // could not parse json
                addAlert(resp.statusText, AlertVariant.danger);
              })
              .then((json) => {
                addAlert(json.message, AlertVariant.danger);
              });
          }
          // success
          addAlert(message, AlertVariant.success);
          setReload((reload) => reload + 1);
          return;
        })
        .catch((error) => {
          addAlert(error.message, AlertVariant.danger);
        });
    };

    return [
      {
        title: 'Activate',
        onClick: (_event, _rowId, rowData) => {
          const username = rowData.username.props.text;
          userAction('POST', '/user/activate/' + username, 'User ' + username + ' set to active.');
        },
      },
      {
        title: 'Freeze',
        onClick: (_event, _rowId, rowData) => {
          const username = rowData.username.props.text;
          userAction('POST', '/user/freeze/' + username, 'User ' + username + ' frozen.');
        },
      },
      {
        title: 'Delete',
        onClick: (_event, _rowId, rowData) => {
          const username = rowData.username.props.text;
          userAction('DELETE', '/user/' + username, 'User ' + username + ' deleted.');
        },
      },
    ];
  }, [isAdmin, conf.api]);

  const notifications = (
    <AlertGroup isToast>
      {alerts.map((p, i) => (
        <Alert variant={p.variant} title={p.title} timeout={3000} key={i} />
      ))}
    </AlertGroup>
  );

  const mapper = React.useCallback((json) => {
    const u = GetUsersResponse.fromJSON(json);
    return u.users.map((x) => ({
      username: {
        title: (
          <Link to={`/user/${x.username}`}>
            {x.username}
            {x.admin ? '*' : ''}
          </Link>
        ),
        props: { text: x.username },
      },
      full_name: x.name,
      institution: x.institution,
      category: x.category,
      country: x.country,
      us_state: x.usstate,
      account_state: userStateToJSON(x.state),
      access_mode: accessModeToJSON(x.accessMode),
      projects: {
        title: (
          <>
            {Object.keys(x.projects || {}).map((p, i) => (
              <React.Fragment key={i}>
                <Link to={`/project/${p}`}>{p}</Link>
                {i < Object.keys(x.projects || {}).length - 1 ? ', ' : ''}
              </React.Fragment>
            ))}
          </>
        ),
        props: { text: Object.keys(x.projects || {}).join(', ') || 'None' },
      },
      organizations: {
        title: (
          <>
            {Object.keys(x.organizations || {}).map((o, i) => (
              <React.Fragment key={i}>
                <Link to={`/organization/${o}`}>{o}</Link>
                {i < Object.keys(x.organizations || {}).length - 1 ? ', ' : ''}
              </React.Fragment>
            ))}
          </>
        ),
        props: { text: Object.keys(x.organizations || {}).join(', ') || 'None' },
      },
      experiments: {
        title: (
          <>
            {x.experiments.map((e, i) => {
              const [eid, pid] = e.split('.');
              return (
                <React.Fragment key={i}>
                  <Link to={`/project/${pid}/experiment/${eid}`}>{e}</Link>
                  {i < x.experiments.length - 1 ? ', ' : ''}
                </React.Fragment>
              );
            })}
          </>
        ),
        props: { text: x.experiments.join(', ') || 'None' },
      },
      uid: x.uid.toString(),
      gid: x.gid.toString(),
    }));
  }, []);

  const crumbs = (
    <PageSection>
      <Breadcrumb>
        <BreadcrumbItem>Users</BreadcrumbItem>
      </Breadcrumb>
    </PageSection>
  );

  const header = (
    <PageSection>
      <Flex alignItems={{ default: 'alignItemsCenter' }} style={{ gap: '4px' }}>
        <FlexItem>
          <Title headingLevel="h1" size="2xl">
            Users
          </Title>
        </FlexItem>
        <FlexItem>
          <StyledTooltip
            content={
              <TextContent>
                <Text>
                  A user represents a human user of the Portal. Each user has a separate login username/ID, and may have
                  membership in a set of projects and/or organizations.
                </Text>
              </TextContent>
            }
            position="right"
          >
            <Button variant="plain" aria-label="More info for Users" style={{ padding: 0 }}>
              <InfoCircleIcon />
            </Button>
          </StyledTooltip>
        </FlexItem>
      </Flex>
    </PageSection>
  );

  const handleSearchResults = (hasResults: boolean) => {
    setNoResults(!hasResults);
  };

  const handleViewChange = (newView: 'table' | 'card') => {
    setIsCardView(newView === 'card');
  };

  return (
    <React.Fragment>
      {alerts.length !== 0 && notifications}
      {crumbs}
      {header}
      <PageSection>
        <Flex alignItems={{ default: 'alignItemsCenter' }} spaceItems={{ default: 'spaceItemsMd' }}>
          <FlexItem>
            <Button
              variant={isCardView ? ButtonVariant.control : ButtonVariant.primary}
              onClick={() => handleViewChange('table')}
              aria-label="Table view"
              style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <StyledBallotOutlined />
              <span style={{ lineHeight: 1 }}>Table</span>
            </Button>
          </FlexItem>
          <FlexItem>
            <Button
              variant={isCardView ? ButtonVariant.primary : ButtonVariant.control}
              onClick={() => handleViewChange('card')}
              aria-label="Card view"
              style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <StyledGridViewOutlined />
              <span style={{ lineHeight: 1 }}>Card</span>
            </Button>
          </FlexItem>
        </Flex>
        <div style={{ overflowX: 'auto', marginTop: '16px' }}>
          <ActionList
            reload={reload}
            reloadTrigger={reloadTrigger}
            kind="Users"
            columns={columns}
            url={conf.api + '/users'}
            actions={actions}
            mapper={mapper}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            onSearchResults={handleSearchResults}
            isCardView={isCardView}
            CardComponent={(props) => <UserCard {...props} actions={actions} />}
          />
        </div>
        {noResults && (
          <Alert variant="info" title="No users found" isInline>
            No users match your search criteria. Try adjusting your search.
          </Alert>
        )}
      </PageSection>
    </React.Fragment>
  );
};

export { Users };

