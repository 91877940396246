import React from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Badge,
  Text,
  TextContent,
  TextVariants,
  Grid,
  GridItem,
  Button,
  Split,
  SplitItem,
  Flex,
  FlexItem,
} from '@patternfly/react-core';
import { Link } from 'react-router-dom';
import {
  ServerIcon,
  CubeIcon,
  NetworkIcon,
  GlobeIcon,
  CalendarAltIcon,
  ClockIcon,
  CatalogIcon,
} from '@patternfly/react-icons';
import { KebabMenu } from '@app/lib/KebabMenu';
import { useTranslation } from 'react-i18next';

interface MaterializationCardProps {
  data: any;
  actions: any[];
}

const MaterializationCard: React.FC<MaterializationCardProps> = ({ data, actions }) => {
  const { t } = useTranslation();
  const mtzTitle = data[t('materialization').toLowerCase()].title;

  return (
    <Card isClickable style={{ width: '500px', margin: '10px' }}>
      <CardHeader>
        <Split hasGutter>
          <SplitItem isFilled>
            <Flex alignItems={{ default: 'alignItemsCenter' }}>
              <FlexItem>
                <CatalogIcon />
              </FlexItem>
              <FlexItem>
                <CardTitle>{mtzTitle}</CardTitle>
              </FlexItem>
            </Flex>
          </SplitItem>
          <SplitItem>
            <Badge isRead style={{ backgroundColor: data.statusColor, color: 'white' }}>
              {data.statusText}
            </Badge>
          </SplitItem>
          <SplitItem>
            <KebabMenu
              actions={actions.map((action) => ({
                ...action,
                onClick: () =>
                  action.onClick(null, null, [
                    { props: { text: data.rid } },
                    { props: { text: data.eid } },
                    { props: { text: data.pid } },
                  ]),
              }))}
              row={data}
              isCardView={true}
            />
          </SplitItem>
        </Split>
      </CardHeader>
      <CardBody>
        <Grid hasGutter>
          <GridItem span={6}>
            <TextContent>
              <Text component={TextVariants.small}>
                <Flex alignItems={{ default: 'alignItemsCenter' }}>
                  <FlexItem>
                    <ServerIcon />
                  </FlexItem>
                  <FlexItem>Metal Nodes: {data.numMetal}</FlexItem>
                </Flex>
              </Text>
            </TextContent>
          </GridItem>
          <GridItem span={6}>
            <TextContent>
              <Text component={TextVariants.small}>
                <Flex alignItems={{ default: 'alignItemsCenter' }}>
                  <FlexItem>
                    <CubeIcon />
                  </FlexItem>
                  <FlexItem>Virtual Nodes: {data.numVirtual}</FlexItem>
                </Flex>
              </Text>
            </TextContent>
          </GridItem>
          <GridItem span={6}>
            <TextContent>
              <Text component={TextVariants.small}>
                <Flex alignItems={{ default: 'alignItemsCenter' }}>
                  <FlexItem>
                    <NetworkIcon />
                  </FlexItem>
                  <FlexItem>Links: {data.numLinks}</FlexItem>
                </Flex>
              </Text>
            </TextContent>
          </GridItem>
          <GridItem span={6}>
            <TextContent>
              <Text component={TextVariants.small}>
                <Flex alignItems={{ default: 'alignItemsCenter' }}>
                  <FlexItem>
                    <GlobeIcon />
                  </FlexItem>
                  <FlexItem>Ingresses:</FlexItem>
                </Flex>
              </Text>
            </TextContent>
          </GridItem>
          <GridItem span={12}>
            {data.ingressesRaw &&
              data.ingressesRaw.map((ing, index) => (
                <Text key={index} component={TextVariants.small}>
                  {ing.hostname}:{ing.hostport}
                </Text>
              ))}
          </GridItem>
          <GridItem span={6}>
            <TextContent>
              <Text component={TextVariants.small}>
                <Flex alignItems={{ default: 'alignItemsCenter' }}>
                  <FlexItem>
                    <CalendarAltIcon />
                  </FlexItem>
                  <FlexItem>Created: {data.created}</FlexItem>
                </Flex>
              </Text>
            </TextContent>
          </GridItem>
          <GridItem span={6}>
            <TextContent>
              <Text component={TextVariants.small}>
                <Flex alignItems={{ default: 'alignItemsCenter' }}>
                  <FlexItem>
                    <ClockIcon />
                  </FlexItem>
                  <FlexItem>Last Updated: {data.lastUpdated}</FlexItem>
                </Flex>
              </Text>
            </TextContent>
          </GridItem>
        </Grid>
      </CardBody>
      <CardFooter>
        <Button
          variant="link"
          component={(props) => <Link {...props} to={`/materializations/${data.pid}/${data.eid}/${data.rid}`} />}
        >
          View Details
        </Button>
      </CardFooter>
    </Card>
  );
};

export { MaterializationCard };

