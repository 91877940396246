import React from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Badge,
  Text,
  TextContent,
  TextVariants,
  Grid,
  GridItem,
  Button,
  Split,
  SplitItem,
  Flex,
  FlexItem,
  Label,
} from '@patternfly/react-core';
import { Link } from 'react-router-dom';
import { UserIcon, BuildingIcon, GlobeIcon, MapMarkerIcon, ProjectDiagramIcon, UsersIcon, FlaskIcon } from '@patternfly/react-icons';

const UserCard = ({ data, actions }) => {
  return (
    <Card isHoverable className="p-4 bg-gray-50" style={{ width: '350px', margin: '10px' }}>
      <CardHeader>
        <Split hasGutter>
          <SplitItem isFilled>
            <CardTitle className="text-xl font-semibold">{data.username.title}</CardTitle>
          </SplitItem>
          <SplitItem>
            <Badge className="bg-green-500 text-white px-2 py-1 rounded">{data.account_state}</Badge>
          </SplitItem>
        </Split>
      </CardHeader>
      <CardBody>
      <Grid hasGutter className="mt-4">
        <GridItem span={12}>
          <TextContent>
            <Text component={TextVariants.h3} className="text-lg font-medium">
              <Flex alignItems={{ default: 'alignItemsCenter' }}>
                <FlexItem className="text-blue-500 mr-2">
                  <UserIcon />
                </FlexItem>
                <FlexItem>{data.full_name}</FlexItem>
              </Flex>
            </Text>
            <Text className="text-gray-600">
              <Flex alignItems={{ default: 'alignItemsCenter' }}>
                <FlexItem className="text-blue-500 mr-2">
                  <BuildingIcon />
                </FlexItem>
                <FlexItem>{data.institution}</FlexItem>
              </Flex>
            </Text>
          </TextContent>
        </GridItem>
        <GridItem span={12} className="my-3">
          <Flex>
            <FlexItem>
              <Label className="bg-blue-100 text-blue-800 px-2 py-1 rounded">{data.access_mode}</Label>
            </FlexItem>
            <FlexItem className="ml-2">
              <Label className="bg-cyan-100 text-cyan-800 px-2 py-1 rounded">{data.category}</Label>
            </FlexItem>
          </Flex>
        </GridItem>
        <GridItem span={6}>
          <TextContent>
            <Text component={TextVariants.small} className="text-gray-600">
              <Flex alignItems={{ default: 'alignItemsCenter' }}>
                <FlexItem className="text-blue-500 mr-2">
                  <GlobeIcon />
                </FlexItem>
                <FlexItem>{data.country}</FlexItem>
              </Flex>
            </Text>
            {data.us_state && (
              <Text component={TextVariants.small} className="text-gray-600 mt-1">
                <Flex alignItems={{ default: 'alignItemsCenter' }}>
                  <FlexItem className="text-blue-500 mr-2">
                    <MapMarkerIcon />
                  </FlexItem>
                  <FlexItem>{data.us_state}</FlexItem>
                </Flex>
              </Text>
            )}
          </TextContent>
        </GridItem>
        <GridItem span={6}>
          <TextContent>
            <Text component={TextVariants.small} className="text-gray-600">
              <Flex alignItems={{ default: 'alignItemsCenter' }}>
                <FlexItem className="text-blue-500 mr-2">
                  <ProjectDiagramIcon />
                </FlexItem>
                <FlexItem>Projects: {data.projects.title}</FlexItem>
              </Flex>
            </Text>
            <Text component={TextVariants.small} className="text-gray-600 mt-1">
              <Flex alignItems={{ default: 'alignItemsCenter' }}>
                <FlexItem className="text-blue-500 mr-2">
                  <UsersIcon />
                </FlexItem>
                <FlexItem>Orgs: {data.organizations.title}</FlexItem>
              </Flex>
            </Text>
            <Text component={TextVariants.small} className="text-gray-600 mt-1">
              <Flex alignItems={{ default: 'alignItemsCenter' }}>
                <FlexItem className="text-blue-500 mr-2">
                  <FlaskIcon />
                </FlexItem>
                <FlexItem>Experiments: {data.experiments.title}</FlexItem>
              </Flex>
            </Text>
          </TextContent>
        </GridItem>
      </Grid>
      </CardBody>
      <CardFooter className="mt-4">
        <Flex justifyContent={{ default: 'justifyContentSpaceBetween' }}>
          <FlexItem>
            <Button 
              variant="link" 
              component={(props) => <Link {...props} to={`/user/${data.username.props.text}`} />}
              className="text-blue-600 hover:text-blue-800"
            >
              View Details
            </Button>
          </FlexItem>
          <FlexItem>
            <Flex>
              {actions && actions.map(action => (
                <FlexItem key={action.title}>
                  <Button 
                    variant="secondary" 
                    onClick={() => action.onClick(null, null, { username: data.username })}
                    className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-semibold py-2 px-4 rounded ml-2"
                  >
                    {action.title}
                  </Button>
                </FlexItem>
              ))}
            </Flex>
          </FlexItem>
        </Flex>
      </CardFooter>
    </Card>
  );
};

export { UserCard };