// This component renders the main tutorials page.
//  It displays a gallery of tutorial cards, each representing a tutorial from TutorialData.tsx.
// Key features:
// 	• Tracks the progress of each tutorial (Not Started, In Progress, Completed)
// 	• Allows users to start or review tutorials
// 	• Uses the useSidebar hook to manage the sidebar state




import React, { useState, useEffect } from 'react';
import {
  PageSection,
  Title,
  Gallery,
  GalleryItem,
  Card,
  CardBody,
  CardTitle,
  CardFooter,
  Button,
  Label,
  Flex,
  FlexItem,
  Text,
  TextVariants,
  TextContent,
  Stack,
  StackItem
} from '@patternfly/react-core';
import { BookOpenIcon, ClockIcon } from '@patternfly/react-icons';
import { useSidebar } from './SidebarContext';
import TutorialContent from './TutorialContent';
import { Tutorial } from './Types';
import { tutorials } from './TutorialData';

const Tutorials: React.FC = () => {
  const { setSidebarOpen, setSidebarContent } = useSidebar();
  const [tutorialStatus, setTutorialStatus] = useState<{ [key: string]: 'Not Started' | 'In Progress' | 'Completed' }>({});

  useEffect(() => {
    const loadTutorialStatus = () => {
      const status: { [key: string]: 'Not Started' | 'In Progress' | 'Completed' } = {};
      tutorials.forEach(tutorial => {
        const savedProgress = localStorage.getItem(`tutorial_progress_${tutorial.id}`);
        if (savedProgress) {
          const { completedSteps } = JSON.parse(savedProgress);
          if (completedSteps.length === 0) {
            status[tutorial.id] = 'Not Started';
          } else if (completedSteps.length === tutorial.steps.length) {
            status[tutorial.id] = 'Completed';
          } else {
            status[tutorial.id] = 'In Progress';
          }
        } else {
          status[tutorial.id] = 'Not Started';
        }
      });
      setTutorialStatus(status);
    };

    loadTutorialStatus();
  }, []);

  const handleCloseTutorial = () => {
    setSidebarOpen(false);
    setSidebarContent(null);
  };

  const handleStartTutorial = (tutorial: Tutorial) => {
    setSidebarContent(
      <TutorialContent
        tutorial={tutorial}
        onClose={handleCloseTutorial}
        onProgressChange={() => {
          const newStatus = { ...tutorialStatus };
          newStatus[tutorial.id] = 'In Progress';
          setTutorialStatus(newStatus);
        }}
        onComplete={() => {
          const newStatus = { ...tutorialStatus };
          newStatus[tutorial.id] = 'Completed';
          setTutorialStatus(newStatus);
        }}
      />
    );
    setSidebarOpen(true);
  };

  return (
    <PageSection>
      <Title headingLevel="h1" size="2xl" className="pf-u-mb-lg">
        Tutorials
      </Title>
      <Gallery hasGutter>
        {tutorials.map((tutorial) => (
          <GalleryItem key={tutorial.id}>
            <Card isHoverable style={{ width: '500px' }}>
              <CardTitle>
                <Flex alignItems={{ default: 'alignItemsCenter' }}>
                  <FlexItem>
                    <BookOpenIcon style={{ width: '2.5em', height: '2.5em' }} color="var(--pf-global--primary-color--100)" />
                  </FlexItem>
                  <FlexItem>
                    <Stack>
                      <StackItem>
                        <Text component={TextVariants.h2} className="pf-u-font-weight-bold pf-u-font-size-3xl">
                          {tutorial.title.split('(')[0].trim()}
                        </Text>
                      </StackItem>
                      <StackItem>
                        <Text component={TextVariants.h3} className="pf-u-font-weight-bold pf-u-font-size-xl">
                          ({tutorial.title.split('(')[1]?.replace(')', '')})
                        </Text>
                      </StackItem>
                    </Stack>
                  </FlexItem>
                </Flex>
              </CardTitle>
              <CardBody>
                <TextContent>
                  <Text component={TextVariants.p} className="pf-u-font-size-sm">
                    {tutorial.description}
                  </Text>
                </TextContent>
              </CardBody>
              <CardFooter>
                <Flex justifyContent={{ default: 'justifyContentSpaceBetween' }} alignItems={{ default: 'alignItemsCenter' }}>
                  <FlexItem>
                    <Label color="blue" icon={<ClockIcon />}>
                      {tutorial.estimatedTime}
                    </Label>
                  </FlexItem>
                  <FlexItem>
                    {tutorialStatus[tutorial.id] !== 'Not Started' && (
                      <Label color={tutorialStatus[tutorial.id] === 'Completed' ? 'green' : 'blue'}>
                        {tutorialStatus[tutorial.id]}
                      </Label>
                    )}
                  </FlexItem>
                  <FlexItem>
                    <Button
                      variant="primary"
                      onClick={() => handleStartTutorial(tutorial)}
                    >
                      {tutorialStatus[tutorial.id] === 'Completed' ? 'Review Tutorial' : 'Start Tutorial'}
                    </Button>
                  </FlexItem>
                </Flex>
              </CardFooter>
            </Card>
          </GalleryItem>
        ))}
      </Gallery>
    </PageSection>
  );
};

export default Tutorials;
