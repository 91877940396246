import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useFetch } from 'use-http';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import { ModelEditor } from '@app/ModelEditor/ModelEditor';
import { TopologyView } from '@app/Revision/TopologyView';
import {
  Alert,
  Spinner,
  Bullseye,
  PageSection,
  Card,
  CardBody,
  Split,
  SplitItem,
  Tabs,
  Tab,
  TabTitleText,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Title,
  Modal,
  ModalVariant,
  Form,
  FormGroup,
  FormAlert,
  TextInput,
  ActionGroup,
  AlertGroup,
  AlertVariant,
} from '@patternfly/react-core';

import { GetRevisionResponse } from '@mergetb/api/portal/v1/workspace_types';
import { useTranslation } from 'react-i18next';
import { toTitleCase } from '@app/lib/util';
import { eventEmitter } from '@app/Tutorials/EventEmitter';
import { GRPCError } from '@app/lib/error';

const Revision: React.FunctionComponent = () => {
  const { pid, eid, rev } = useParams();
  const conf = React.useContext(GeneralSettingsContext);
  const [activeTab, setActiveTab] = React.useState(0);

  const [showRealRev, setShowRealRev] = React.useState(false);
  const [realName, setRealName] = React.useState('');
  const [alerts, setAlerts] = React.useState([]);
  const { t } = useTranslation();
  const [revision, setRevision] = React.useState<GetRevisionResponse>();
  const [loadError, setLoadError] = React.useState<GRPCError>();

  const options = { credentials: 'include' };
  const { loading, error, get, response } = useFetch(
    conf.api + '/project/' + pid + '/experiment/' + eid + '/revision/' + rev + '?encoding=DOT',
    options,
    []
  );

  const load = React.useCallback(async () => {
    const resp = await get();
    if (response.ok) {
      setRevision(GetRevisionResponse.fromJSON(resp));
    } else {
      setLoadError(resp);
    }
  }, [get, response]);

  React.useEffect(() => {
    load();
  }, [load]);
  const handleRealizationNameChange = (value: string) => {
    setRealName(value);
  };

  const tabClick = (event, index) => {
    setActiveTab(index);
  };

  const crumbs = (
    <PageSection>
      <Breadcrumb>
        <BreadcrumbItem to="/project">Projects</BreadcrumbItem>
        <BreadcrumbItem to={'/project/' + pid}>{pid}</BreadcrumbItem>
        <BreadcrumbItem to="/experiment">Experiments</BreadcrumbItem>
        <BreadcrumbItem to={'/project/' + pid + '/experiment/' + eid}>{eid}</BreadcrumbItem>
        <BreadcrumbItem>Revisions</BreadcrumbItem>
        <BreadcrumbItem>{rev}</BreadcrumbItem>
      </Breadcrumb>
    </PageSection>
  );

  const header = (
    <PageSection>
      <Split>
        <SplitItem>
          <Title headingLevel="h1" size="lg">
            Revision: {pid}/{eid}/{rev}
          </Title>
        </SplitItem>
        <SplitItem isFilled />
        <SplitItem>
          <Button
            variant="control"
            aria-label={toTitleCase(t('realize')) + ' Revision'}
            onClick={() => setShowRealRev(!showRealRev)}
          >
            {toTitleCase(t('realize'))} Revision
          </Button>
        </SplitItem>
      </Split>
    </PageSection>
  );

  const addAlert = (t, v) => {
    setAlerts((prev) => [...prev, { title: t, variant: v }]);
  };

  const submitRealize = () => {
    fetch(conf.api + '/realize/realizations/' + pid + '/' + eid + '/' + realName, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        project: pid,
        experiment: eid,
        realization: realName,
        revision: rev,
      }),
    })
      .then((resp) => {
        if (resp.ok) {
          addAlert('New ' + toTitleCase(t('realization')) + ' ' + realName + ' Created', 'success');
          setRealName('');
          setShowRealRev(!showRealRev);
          eventEmitter.emit('realizationCreated', { realizationName: realName });
          return;
        } else {
          return resp.text().then((text) => {
            throw new Error(text);
          });
        }
      })
      .catch((error) => {
        addAlert(error.message, 'danger');
        setShowRealRev(!showRealRev);
      });
  };

  const notready = realName === '';

  const title = (
    <>
      A {toTitleCase(t('realization'))} is a request to exclusively reserve resources for an experiment. This is an
      asynchronous process. Check the {t('realizations')} listing or the dashboard to see the status of your{' '}
      {t('realization')}. If the {t('realization')} fails, it is likely that your model is requesting resources that are
      not currently available. In that case, {t('relinquish')} the {t('realization')} and try again at a later time.
    </>
  );

  const realizeModal = (
    <Modal
      isOpen={showRealRev}
      onClose={() => setShowRealRev(!showRealRev)}
      variant={ModalVariant.medium}
      aria-label={toTitleCase(t('realize')) + ' Revision'}
    >
      <React.Fragment>
        <Title headingLevel="h1" size="2xl">
          {toTitleCase(t('realize'))} Revision
        </Title>
        <Alert isInline title={title} />
        <Form>
          {notready && (
            <FormAlert>
              <Alert variant="danger" title="All fields must be filled" aria-live="polite" isInline />
            </FormAlert>
          )}
          <FormGroup label="Project">
            <TextInput isRequired type="text" id="project" value={pid} readOnlyVariant="default" />
          </FormGroup>
          <FormGroup label="Experiment">
            <TextInput type="text" id="experment" value={eid} readOnlyVariant="default" />
          </FormGroup>
          <FormGroup label={toTitleCase(t('realization')) + ' Name'}>
            <TextInput
              type="text"
              id="realization"
              value={realName}
              onChange={(_event, value) => handleRealizationNameChange(value)}
            />
          </FormGroup>
          <ActionGroup>
            <Button variant="control" onClick={submitRealize} isDisabled={notready} isAriaDisabled={notready}>
              Submit
            </Button>
          </ActionGroup>
        </Form>
      </React.Fragment>
    </Modal>
  );

  const notifications = (
    <AlertGroup isToast>
      {alerts.map((a, i) => (
        <Alert variant={AlertVariant[a.var]} title={a.title} timeout={3000} key={i} />
      ))}
    </AlertGroup>
  );

  return (
    <React.Fragment>
      {alerts.length !== 0 && notifications}
      {realizeModal}
      {crumbs}
      {header}
      <PageSection>
        <Card>
          <CardBody>
            {error && !loadError && (
              <Alert variant="danger" title="Error">
                Error loading
              </Alert>
            )}
            {error && loadError && (
              <Alert variant="danger" title="Response Error">
                <pre>{loadError.message}</pre>
              </Alert>
            )}
            {loading && (
              <Bullseye>
                <Spinner size="sm" />
              </Bullseye>
            )}
            {revision && (
              <div>
                <Tabs activeKey={activeTab} onSelect={tabClick}>
                  <Tab eventKey={0} title={<TabTitleText>Topology</TabTitleText>}>
                    <TopologyView model={revision.model?.model} />
                  </Tab>
                  <Tab eventKey={1} title={<TabTitleText>Model</TabTitleText>}>
                    <ModelEditor model={revision.ModelFile} readonly={true} />
                  </Tab>
                  <Tab eventKey={2} title={<TabTitleText>XIR</TabTitleText>}>
                    <pre>{JSON.stringify(revision.model, null, 2)}</pre>
                  </Tab>
                  <Tab eventKey={3} title={<TabTitleText>Graphviz</TabTitleText>}>
                    <pre>{revision.encoding}</pre>
                  </Tab>
                </Tabs>
              </div>
            )}
          </CardBody>
        </Card>
      </PageSection>
    </React.Fragment>
  );
};

export { Revision };
