// This file defines an EventEmitter class that implements a simple event system.
// It allows components to subscribe to and emit events, enabling communication between different parts of the application.
// Key features:
// 	• on: Subscribe to an event
// 	• emit: Trigger an event with data
// 	• off: Unsubscribe from an event
// The file exports an eventEmitter instance for global use.



class EventEmitter {
    private listeners: { [event: string]: Function[] } = {};
  
    on(event: string, callback: Function) {
      if (!this.listeners[event]) {
        this.listeners[event] = [];
      }
      this.listeners[event].push(callback);
    }
  
    emit(event: string, data: any) {
      if (this.listeners[event]) {
        this.listeners[event].forEach(callback => callback(data));
      }
    }
  
    off(event: string, callback: Function) {
      if (this.listeners[event]) {
        this.listeners[event] = this.listeners[event].filter(cb => cb !== callback);
      }
    }
  }
  
  export const eventEmitter = new EventEmitter();