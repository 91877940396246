import * as React from 'react';
import { Materialization } from '@mergetb/api/portal/v1/materialize_types';
import * as ModelTypes from '@app/Revision/model_types';
import { mtz2topomodel } from '@app/Revision/graph';
import { ForceGraph2D } from 'react-force-graph';


type MtzTopologyProps = {
  mtz: Materialization;
};

const MtzTopology: React.FC<MtzTopologyProps> = ({ mtz }) => {
  const fgRef = React.useRef();
  const nodeSize = 1.3;
  const width = 100;
  const height = 100;

  const drawNode = ({ x, y, type }, ctx) => {
    switch (type) {
      case ModelTypes.NodeType.Node:
        ctx.fillStyle = 'green';
        ctx.beginPath();
        ctx.arc(x, y, nodeSize, 0, 2 * Math.PI, false);
        ctx.fill();
        break;
    }
  };

  // based heavily on https://github.com/usc-isi-i2/kgtk-browser/blob/main/app/src/components/ClassGraphViz.js
  const renderNodeCanvasObject = React.useCallback(
    (node, ctx, globalScale) => {
      const label = node.label;
      const fontSize = (nodeSize * 7) / globalScale;
      ctx.font = `${fontSize}px Sans-Serif`;
      const textWidth = ctx.measureText(label).width;
      const bckgDimensions = [textWidth, fontSize].map((n) => n + fontSize * 0.2); // some padding

      ctx.fillStyle = 'rgba(255, 255, 255, 0.75)';
      ctx.fillRect(node.x - bckgDimensions[0] / 2, node.y - (nodeSize + 5) - bckgDimensions[1] / 2, ...bckgDimensions);

      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';

      ctx.fillStyle = 'black';
      ctx.fillText(label, node.x - (nodeSize + 2), node.y - (nodeSize + 2));

      drawNode(node, ctx);

      node.__bckgDimensions = bckgDimensions; // to re-use in nodePointerAreaPaint
    },
    [nodeSize]
  );

  return (
    <div
      style={{
        height: { height },
        width: { width },
      }}
    >
      <ForceGraph2D
        ref={fgRef}
        graphData={mtz2topomodel(mtz)}
        width={width}
        height={height}
        nodeCanvasObject={renderNodeCanvasObject}
        linkLabel={'id'}
      />
    </div>
  );
};

export { MtzTopology };
