import * as React from 'react';
import {
  ActionGroup,
  AlertGroup,
  AlertVariant,
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  PageSection,
  Split,
  SplitItem,
  Title,
  Breadcrumb,
  BreadcrumbItem,
  Modal,
  ModalVariant,
  Form,
  FormGroup,
  FormHelperText,
  FormAlert,
  TextInput,
  TextArea,
  FormSelect,
  Radio,
  FormSelectOption,
  HelperTextItem,
  HelperText,
  FlexItem,
  ButtonVariant,
  Flex,
  TextContent,
  Tooltip,
  Text,
} from '@patternfly/react-core';
import { Link } from 'react-router-dom';
import { sortable, headerCol, cellWidth } from '@patternfly/react-table';
import { ActionList } from '@app/lib/ActionList';
import { AuthContext } from '@app/lib/AuthProvider';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import {
  CreateOrganizationRequest,
  UpdateOrganizationRequest,
  GetOrganizationsResponse,
  Member,
  Member_Role,
  Member_State,
  member_RoleToJSON,
  member_StateToJSON,
  userStateToJSON,
  accessModeToJSON,
  AccessMode,
  UserState,
  RequestOrganizationMembershipRequest,
  MembershipType,
  DeleteOrganizationRequest,
  GetEntityTypeConfigurationsResponse,
  Organization,
} from '@mergetb/api/portal/v1/workspace_types';
import { useFetch } from 'use-http';
import { OrganizationCard } from './OrganizationCard';
import { BallotOutlined, GridViewOutlined } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { InfoCircleIcon } from '@patternfly/react-icons';
import { en } from '@ory/elements/dist/locales';

const StyledTooltip = styled(Tooltip)({
  '& .pf-v5-c-tooltip__content': {
    backgroundColor: 'white',
    color: 'black',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    border: '1px solid #d2d2d2',
    padding: '10px',
    maxWidth: '500px',
    width: '400px',
    fontSize: '14px',
    lineHeight: '1.4',
    maxHeight: '300px',
  },
  '& .pf-v5-c-tooltip__arrow': {
    display: 'none',
  },
});

const StyledBallotOutlined = styled(BallotOutlined)(({ theme }) => ({
  fontSize: '24px',
  color: 'inherit',
  marginRight: '8px',
  verticalAlign: 'middle',
}));

const StyledGridViewOutlined = styled(GridViewOutlined)(({ theme }) => ({
  fontSize: '24px',
  color: 'inherit',
  marginRight: '8px',
  verticalAlign: 'middle',
}));

const Organizations: React.FunctionComponent = () => {
  const { api } = React.useContext(GeneralSettingsContext);
  const { user } = React.useContext(AuthContext);
  const username = user?.username;
  const [searchTerm, setSearchTerm] = React.useState('');
  const [reloadTrigger, setReloadTrigger] = React.useState(0);

  const userview_last = (localStorage.getItem('userview') ?? true) == true;
  const [viewLabel, setViewLabel] = React.useState('View ' + (userview_last ? 'All' : 'Own'));
  const [userView, setUserView] = React.useState(userview_last);
  const [alerts, setAlerts] = React.useState<(typeof Alert)[]>([]);
  const [reload, setReload] = React.useState(1);

  const [orgs, setOrgs] = React.useState<Organization[]>([]);

  const [showNew, setShowNew] = React.useState(false);
  const [newOrgName, setNewOrgName] = React.useState('');
  const [newOrgNameValid, setNewOrgNameValid] = React.useState(false);
  const [newOrgDesc, setNewOrgDesc] = React.useState('');
  const [invalidName, setInvalidName] = React.useState('');

  const [orgCat, setOrgCat] = React.useState('');
  const [orgSubCat, setOrgSubCat] = React.useState('');
  const [orgSubCats, setOrgSubCats] = React.useState<Array<string>>([]);
  const [otherSubCat, setOtherSubCat] = React.useState('');

  const [isCardView, setIsCardView] = React.useState(false);
  const [noResults, setNoResults] = React.useState(false);

  const [reqOrg, setReqOrg] = React.useState<Organization>();
  const [reqMemberOpen, setReqMemberOpen] = React.useState<boolean>(false);

  const handleViewChange = (newView: 'table' | 'card') => {
    setIsCardView(newView === 'card');
  };

  const handleSearchResults = (hasResults: boolean) => {
    setNoResults(!hasResults);
  };

  const options = {
    credentials: 'include',
    cachePolicy: 'no-cache',
  };

  const { data: entTypeData } = useFetch(api + '/configurations/entitytype', options, []);

  const columns = [
    { title: 'Name', cellTransforms: [headerCol()], transforms: [sortable, cellWidth(15)] },
    { title: 'Description', cellTransforms: [headerCol()], transforms: [sortable, cellWidth(20)] },
    { title: 'State', cellTransforms: [headerCol()], transforms: [sortable, cellWidth(10)] },
    { title: 'Access Mode', cellTransforms: [headerCol()], transforms: [sortable, cellWidth(20)] },
    { title: 'Members', cellTransforms: [headerCol()], transforms: [sortable, cellWidth(15)] },
    { title: 'Projects', cellTransforms: [headerCol()], transforms: [sortable, cellWidth(15)] },
    { title: 'Category', cellTransforms: [headerCol()], transforms: [sortable, cellWidth(15)] },
    { title: 'Subcategory', cellTransforms: [headerCol()], transforms: [sortable, cellWidth(15)] },
  ];

  const entityTypes = React.useMemo(() => {
    if (entTypeData) {
      if (Object.prototype.hasOwnProperty.call(entTypeData, 'Types')) {
        const types = GetEntityTypeConfigurationsResponse.fromJSON(entTypeData).Types;
        if (types.length > 0) {
          setOrgCat(types[0].etype);
          return types;
        }
      }
    }
    return undefined;
  }, [entTypeData]);

  const setMode = (oid, mode) => {
    fetch(api + '/organization/' + oid, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        accessMode: {
          value: mode,
        },
      }),
    })
      .then((resp) => {
        if (resp.ok) {
          addAlert('Organization ' + oid + ' access mode set to ' + accessModeToJSON(mode), 'success');
          setReload(reload + 1);
        }
        if (resp.status !== 200) {
          addAlert('Error: ' + resp.statusText, 'danger');
        }
      })
      .catch((error) => {
        console.log(error);
        addAlert(error.message, 'danger');
      });
  };

  const deleteOrg = (org: string) => {
    fetch(api + '/organization/' + org, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: username,
        name: org,
      }),
    })
      .then((resp) => {
        if (resp.ok) {
          addAlert('Organization ' + org + ' Deleted', 'success');
          setReload(reload + 1);
        }
        if (resp.status !== 200) {
          addAlert('Error: ' + resp.statusText, 'danger');
        }
      })
      .catch((error) => {
        console.log(error);
        addAlert(error.message, 'danger');
      });
  };

  const actions = [
    {
      title: 'Request User Membership',
      onClick: (e, rowId, rowData) => {
        const oid = rowData.name.props.text;
        const o: Organization | undefined = orgs.find((o) => o.name === oid);
        setReqOrg(o);
        setReqMemberOpen(true);
      },
    },
    {
      isSeparator: true,
    },
    {
      title: 'Set Access Public',
      onClick: (_event, _rowId, rowData) => {
        const oid = rowData.name.props.text;
        setMode(oid, AccessMode.Public);
      },
    },
    {
      title: 'Set Access Protected',
      onClick: (_event, _rowId, rowData) => {
        const oid = rowData.name.props.text;
        setMode(oid, AccessMode.Protected);
      },
    },
    {
      title: 'Set Access Private',
      onClick: (_event, _rowId, rowData) => {
        const oid = rowData.name.props.text;
        setMode(oid, AccessMode.Private);
      },
    },
    {
      isSeparator: true,
    },
    {
      title: 'Delete',
      onClick: (_event, _rowId, rowData) => {
        const orgName = rowData.name.props.text;
        if (window.confirm(`Are you sure you want to delete the organization "${orgName}"?`)) {
          deleteOrg(orgName);
        }
      },
    },
  ];

  const mapper = React.useCallback((json) => {
    const orgs: GetOrganizationsResponse = GetOrganizationsResponse.fromJSON(json);
    setOrgs(orgs.organizations);
    return orgs.organizations.map((x) => ({
      name: {
        title: <Link to={`/organization/${x.name}`}>{x.name}</Link>,
        props: { text: x.name, component: 'th' },
      },
      description: x.description,
      state: <div className="pf-u-danger-color-200">{userStateToJSON(x.state)}</div>,
      access_mode: accessModeToJSON(x.accessMode),
      members: {
        title: (
          <>
            {Object.keys(x.members).map((m, i) => (
              <React.Fragment key={i}>
                <Link to={`/user/${m}`}>{m}</Link>
                <br />
              </React.Fragment>
            ))}
          </>
        ),
        props: { text: Object.keys(x.members).join(', ') },
      },
      projects: {
        title: (
          <>
            {Object.keys(x.projects).map((p, i) => (
              <React.Fragment key={i}>
                <Link to={`/project/${p}`}>{p}</Link>
                <br />
              </React.Fragment>
            ))}
          </>
        ),
        props: { text: Object.keys(x.projects).join(', ') },
      },
      category: x.category,
      subcategory: x.subcategory,
    }));
  }, []);

  const onOrgNameChange = (event: React.ChangeEvent<HTMLInputElement> | string) => {
    const val = typeof event === 'string' ? event : event.target.value;
    const re = /^[a-z]([a-z0-9]*[a-z0-9])?$/;
    if (re.test(val) === false) {
      setNewOrgName(val);
      setNewOrgNameValid(false);
      setInvalidName(
        'The organization name must start with a lowercase letter and only contain lowercase alphanumeric characters and no spaces'
      );
    } else {
      setInvalidName('');
      setNewOrgName(val);
      setNewOrgNameValid(val !== '');
    }
  };

  const toggleNewOrg = () => {
    setShowNew(!showNew);
  };

  const toggleView = () => {
    setViewLabel('View ' + (userView === false ? 'All' : 'Own'));
    localStorage.setItem('userview', userView ? '0' : '1');
    setUserView(!userView);
    setReload(reload + 1);
  };

  const addAlert = (t, v) => {
    setAlerts((prev) => [...prev, { title: t, variant: v }]);
  };

  const crumbs = (
    <PageSection>
      <Breadcrumb>
        <BreadcrumbItem>Organizations</BreadcrumbItem>
      </Breadcrumb>
    </PageSection>
  );

  const header = (
    <PageSection>
      <Split>
        <SplitItem>
          <Flex alignItems={{ default: 'alignItemsCenter' }} style={{ gap: '4px' }}>
            <FlexItem>
              <Title headingLevel="h1" size="2xl">
                Organizations
              </Title>
            </FlexItem>
            <FlexItem>
              <StyledTooltip
                content={
                  <TextContent>
                    <Text>
                      Organizations are used to manage a (typically large) group of users. When users join an
                      organization, they delegate the authority to manage their account to the creators and maintainers
                      of the organization. New users on the Portal can request membership in an organization when
                      creating the account; when that happens, the organization's maintainers can authorize the account
                      without requiring approval of a Merge Portal administrator. Projects can also join organizations,
                      which gives the organization's maintainers access to the experiments created in that project.
                    </Text>
                  </TextContent>
                }
                position="right"
                enableFlip={false}
                distance={15}
              >
                <Button variant="plain" aria-label="More info for Organizations" style={{ padding: 0 }}>
                  <InfoCircleIcon />
                </Button>
              </StyledTooltip>
            </FlexItem>
          </Flex>
        </SplitItem>
        <SplitItem isFilled />
        <SplitItem>
          <Flex>
            <FlexItem>
              <Button variant="control" aria-label={viewLabel} onClick={toggleView}>
                {viewLabel}
              </Button>
            </FlexItem>
            <FlexItem>
              <Button variant="control" aria-label="New Organization" onClick={toggleNewOrg}>
                New Organization
              </Button>
            </FlexItem>
          </Flex>
        </SplitItem>
      </Split>
    </PageSection>
  );

  const submitForm = () => {
    if (notready || user === undefined) {
      return;
    }

    const req: CreateOrganizationRequest = {
      user: username,
      organization: {
        name: newOrgName,
        description: newOrgDesc,
        category: orgCat,
        subcategory: orgSubCat === 'Other' ? otherSubCat : orgSubCat,
        members: {},
        projects: {},
        state: UserState.Pending,
        accessMode: AccessMode.Public,
      },
    };

    fetch(api + '/organization/' + newOrgName, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(req),
    })
      .then(async (resp) => {
        const responseText = await resp.text();

        if (resp.ok) {
          addAlert('New Organization ' + newOrgName + ' Created', 'success');
          setReload(reload + 1);
          setNewOrgDesc('');
          setNewOrgName('');
          setNewOrgNameValid(false);
          toggleNewOrg();
        } else {
          throw new Error(responseText);
        }
      })
      .catch((error) => {
        addAlert(error.message, 'danger');
      });
  };

  const handleOrgCatChange = (value) => {
    setOrgCat(value);
    setOrgSubCat('');
    if (entityTypes) {
      const found = entityTypes.find((o) => o.etype === value);
      if (found && found.subtypes) {
        setOrgSubCats(found.subtypes);
        if (found.subtypes.length > 0) {
          setOrgSubCat(found.subtypes[0]);
        }
      } else {
        setOrgSubCats([]);
      }
    }
  };

  const handleApiResponse = async (response, successMessage) => {
    const responseText = await response.text();

    if (response.ok) {
      addAlert(successMessage, 'success');
      setReload(reload + 1);
    } else {
      let errorMessage = `Error: ${response.statusText}`;
      try {
        const errorData = JSON.parse(responseText);
        errorMessage = `Error: ${errorData.message || response.statusText}`;
        console.error('Detailed error:', errorData);
      } catch (e) {
        console.error('Error parsing response:', e);
      }
      addAlert(errorMessage, 'danger');
      throw new Error(errorMessage);
    }
  };

  const notready = React.useMemo(() => {
    return (
      newOrgName === '' ||
      !newOrgNameValid ||
      newOrgDesc === '' ||
      orgCat === '' ||
      (orgSubCats.length > 0 && orgSubCat === '') ||
      (orgSubCat === 'Other' && otherSubCat === '')
    );
  }, [newOrgName, newOrgNameValid, newOrgDesc, orgCat, orgSubCats, orgSubCat, otherSubCat]);

  const newModal = (
    <Modal isOpen={showNew} onClose={toggleNewOrg} variant={ModalVariant.medium} aria-label="New Organization">
      <React.Fragment>
        <Title headingLevel="h1" size="2xl">
          New Organization
        </Title>
        <Form>
          {notready && (
            <FormAlert>
              <Alert variant="danger" title="All fields must be filled" aria-live="polite" isInline />
            </FormAlert>
          )}
          <FormGroup label="Name" fieldId="name">
            <TextInput
              type="text"
              id="name"
              value={newOrgName}
              onChange={(event, value) => onOrgNameChange(value)}
              validated={newOrgNameValid ? 'success' : 'error'}
            />
            {invalidName && (
              <HelperText>
                <HelperTextItem variant="error">{invalidName}</HelperTextItem>
              </HelperText>
            )}
          </FormGroup>
          <FormGroup label="Description">
            <TextArea
              type="text"
              id="description"
              value={newOrgDesc}
              autoResize={true}
              onChange={(event) => setNewOrgDesc(event.target.value)}
            />
          </FormGroup>
          <FormGroup label="Category">
            <FormSelect value={orgCat} onChange={(event, value) => handleOrgCatChange(value)} aria-label="Category">
              <FormSelectOption key="placeholder" value="" label="Select a category" isDisabled />
              {entityTypes && entityTypes.map((e, i) => <FormSelectOption key={i} value={e.etype} label={e.etype} />)}
            </FormSelect>
          </FormGroup>
          {orgSubCats.length !== 0 && (
            <FormGroup label="Subcategory">
              <FormSelect
                value={orgSubCat}
                onChange={(event, value) => {
                  setOrgSubCat(value);
                }}
                aria-label="Subcategory"
              >
                <FormSelectOption key="placeholder" value="" label="Select a subcategory" isDisabled />
                {orgSubCats.map((e, i) => (
                  <FormSelectOption key={i} value={e} label={e} />
                ))}
              </FormSelect>
            </FormGroup>
          )}
          {orgSubCat === 'Other' && (
            <React.Fragment>
              <FormGroup label="Other Subcategory">
                <TextInput
                  isRequired
                  type="text"
                  id="othersubcat"
                  value={otherSubCat}
                  onChange={(e) => setOtherSubCat(e)}
                />
              </FormGroup>
            </React.Fragment>
          )}
          <ActionGroup>
            <Button variant="control" onClick={submitForm} isDisabled={notready} isAriaDisabled={notready}>
              Submit
            </Button>
          </ActionGroup>
        </Form>
      </React.Fragment>
    </Modal>
  );

  const notifications = (
    <AlertGroup isToast>
      {alerts.map((a, i) => (
        <Alert variant={AlertVariant[a.variant]} title={a.title} timeout={3000} key={i} />
      ))}
    </AlertGroup>
  );

  let url = api + '/organization';
  if (userView === false) {
    url += '?filter=ByAll';
  }

  return (
    <React.Fragment>
      {alerts.length !== 0 && notifications}
      <MembershipRequest
        isOpen={reqMemberOpen}
        onClose={() => setReqMemberOpen(false)}
        org={reqOrg}
        user={username}
        addAlert={addAlert}
        api={api}
      />
      {newModal}
      {crumbs}
      {header}
      <PageSection>
        <Flex alignItems={{ default: 'alignItemsCenter' }} spaceItems={{ default: 'spaceItemsMd' }}>
          <FlexItem>
            <Button
              variant={isCardView ? ButtonVariant.control : ButtonVariant.primary}
              onClick={() => handleViewChange('table')}
              aria-label="Table view"
              style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <StyledBallotOutlined />
              <span style={{ lineHeight: 1 }}>Table</span>
            </Button>
          </FlexItem>
          <FlexItem>
            <Button
              variant={isCardView ? ButtonVariant.primary : ButtonVariant.control}
              onClick={() => handleViewChange('card')}
              aria-label="Card view"
              style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <StyledGridViewOutlined />
              <span style={{ lineHeight: 1 }}>Card</span>
            </Button>
          </FlexItem>
        </Flex>
        <div style={{ overflowX: 'auto', marginTop: '16px' }}>
          <ActionList
            kind="Organizations"
            columns={columns}
            url={url}
            actions={actions}
            mapper={mapper}
            reload={reload}
            reloadTrigger={reloadTrigger}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            onSearchResults={handleSearchResults}
            isCardView={isCardView}
            CardComponent={OrganizationCard}
          />
        </div>
        {noResults && (
          <Alert variant="info" title="No organizations found" isInline>
            No organizations match your search criteria. Try adjusting your search.
          </Alert>
        )}
      </PageSection>
    </React.Fragment>
  );
};

interface MembershipRequestProps {
  user: string;
  org: Organization | undefined;
  isOpen: boolean;
  onClose: () => void;
  addAlert: (m: string, v: AlertVariant) => void;
  api: string;
}

const MembershipRequest: React.FC<MembershipRequestProps> = ({ user, org, isOpen, onClose, addAlert, api }) => {
  const [role, setRole] = React.useState<Member_Role>(Member_Role.Member);
  const memberRole = member_RoleToJSON(Member_Role.Member);
  const maintRole = member_RoleToJSON(Member_Role.Maintainer);
  const creatRole = member_RoleToJSON(Member_Role.Creator);

  if (org === undefined) return null;

  console.log('org', org);

  if (Object.keys(org.members).includes(user)) {
    const r = member_RoleToJSON(org.members[user].role);
    const s = member_StateToJSON(org.members[user].state);

    return (
      <Modal variant={ModalVariant.small} titleIconVariant={'danger'} title="Error" isOpen={isOpen} onClose={onClose}>
        You are already a {r} ({s}) member of this organization.
      </Modal>
    );
  }

  const onSubmit = () => {
    const req: RequestOrganizationMembershipRequest = {
      organization: org.name,
      id: user,
      kind: MembershipType.UserMember,
      member: {
        role: role,
        state: Member_State.Active,
      },
    };
    fetch(api + '/organization/' + org.name + '/member/' + user, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(req),
    })
      .then((resp) => {
        if (resp.ok) {
          addAlert('Membership in ' + org.name + ' requested for ' + user, AlertVariant.success);
        }
        if (resp.status !== 200) {
          addAlert('Error: ' + resp.statusText, AlertVariant.danger);
        }
        return;
      })
      .catch((error) => {
        console.log(error);
        addAlert(error.message, AlertVariant.danger);
      });

    onClose();
  };

  return (
    <Modal
      title={`Organization Membership Request`}
      description="Until confirmed, the membership state will be pending"
      isOpen={isOpen}
      onClose={onClose}
      variant={ModalVariant.medium}
      aria-label="Organization Membership Request"
      actions={[
        <Button key="submit" variant={ButtonVariant.primary} form="reqmemform" onClick={onSubmit}>
          Submit
        </Button>,
        <Button key="cancel" variant={ButtonVariant.link} onClick={onClose}>
          Cancel
        </Button>,
      ]}
    >
      <Form id="reqmemform">
        <FormGroup label="Organization" isRequired fieldId="reqmemform-org">
          <TextInput isRequired id="reqmemform-org" name="reqmemform-org" value={org.name} isDisabled={true} />
        </FormGroup>
        <FormGroup label="Username" isRequired fieldId="reqmemform-user">
          <TextInput isRequired id="reqmemform-user" name="reqmemform-user" value={user} isDisabled={true} />
        </FormGroup>
        <FormGroup>
          <FormGroup name="org-role-radioi-group" isInline fieldId="org-role-radio-group" label="Organization Role">
            <Radio
              name="org-role-radio"
              label={memberRole}
              id="org-role-radio-01"
              onChange={() => setRole(Member_Role.Member)}
            />
            <Radio
              name="org-role-radio"
              label={maintRole}
              id="org-role-radio-02"
              onChange={() => {
                setRole(Member_Role.Maintainer);
              }}
            />
            <Radio
              name="org-role-radio"
              label={creatRole}
              id="org-role-radio-03"
              onChange={() => setRole(Member_Role.Creator)}
            />
          </FormGroup>
        </FormGroup>
      </Form>
    </Modal>
  );
};

export { Organizations };
