// This file provides a React context for managing the state of the adjustable sidebar. It includes:
// 	• SidebarProvider: A component that wraps the application and provides the sidebar context
// 	• useSidebar: A custom hook for accessing the sidebar state and functions
// The context manages:
// 	• Whether the sidebar is open
// 	• The content of the sidebar
// 	• The width of the sidebar




import React, { createContext, useContext, useState } from 'react';

interface SidebarContextType {
  isSidebarOpen: boolean;
  setSidebarOpen: (isOpen: boolean) => void;
  sidebarContent: React.ReactNode | null;
  setSidebarContent: (content: React.ReactNode) => void;
  sidebarWidth: number;
  setSidebarWidth: (width: number) => void;
}
const SidebarContext = createContext<SidebarContextType | undefined>(undefined);
export const SidebarProvider: React.FC = ({ children }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [sidebarContent, setSidebarContent] = useState<React.ReactNode | null>(null);
  const [sidebarWidth, setSidebarWidth] = useState(500);
  return (
    <SidebarContext.Provider
      value={{ isSidebarOpen, setSidebarOpen, sidebarContent, setSidebarContent, sidebarWidth, setSidebarWidth }}
    >
      {' '}
      {children}{' '}
    </SidebarContext.Provider>
  );
};
export const useSidebar = () => {
  const context = useContext(SidebarContext);
  if (context === undefined) {
    throw new Error('useSidebar must be used within a SidebarProvider');
  }
  return context;
};
export { SidebarContext };
