import * as React from 'react';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import { useFetch } from 'use-http';
import { Materialization } from '@mergetb/api/portal/v1/materialize_types';
import { ListXDCsResponse, XDCInfo } from '@mergetb/api/portal/v1/xdc_types';
import { Table /* data-codemods */, Thead, Tr, Th, Tbody, Td } from '@patternfly/react-table';

type MtxAttachedXDCsProps = {
  mtz: Materialization | undefined;
};

export const MtzAttachedXDCs: React.FC<MtxAttachedXDCsProps> = ({ mtz }) => {
  const { api } = React.useContext(GeneralSettingsContext);
  const options = { credentials: 'include', cachePolicy: 'no-cache' };
  const { data } = useFetch(api + '/xdc/list/' + mtz!.pid, options, []);

  const mzid = mtz!.rid + '.' + mtz!.eid + '.' + mtz!.pid;

  const xdcs = React.useMemo(() => {
    if (data) {
      const attached: XDCInfo[] = [];
      if (Object.prototype.hasOwnProperty.call(data, 'XDCs')) {
        const xdcs = ListXDCsResponse.fromJSON(data);
        for (const xdc of xdcs.XDCs) {
          if (xdc.materialization == mzid) {
            attached.push(xdc);
          }
        }
      }
      return attached;
    }
    return undefined;
  }, [data]);

  const cols = { xdcs: 'Attached XDCs' };

  return (
    <>
      {xdcs && xdcs?.length != 0 && (
        <Table aria-label={cols.xdcs} variant="compact" borders={false}>
          <Thead>
            <Tr>
              <Th>{cols.xdcs}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {xdcs.map((x, i) => {
              return (
                <Tr key={i}>
                  <Td dataLabel={cols.xdcs}>
                    <a href={x.url} target="_blank" rel="noreferrer">
                      {x.name}
                    </a>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      )}
    </>
  );
};
