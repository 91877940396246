import React from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Badge,
  Text,
  TextContent,
  TextVariants,
  Grid,
  GridItem,
  Button,
  Split,
  SplitItem,
  Flex,
  FlexItem,
  Label,
} from '@patternfly/react-core';
import { Link } from 'react-router-dom';
import {
  ProjectDiagramIcon,
  UserIcon,
  CalendarAltIcon,
  ClockIcon,
  CubeIcon,
  LinkIcon,
  CodeIcon,
  FlaskIcon,
} from '@patternfly/react-icons';
import { KebabMenu } from '@app/lib/KebabMenu';
import { useTranslation } from 'react-i18next';

interface RealizationCardProps {
  data: any;
  actions: any[];
}

const RealizationCard: React.FC<RealizationCardProps> = ({ data, actions }) => {
  console.log('rlz card data', data);
  const { t } = useTranslation();
  const getSuccessBadge = () => {
    if (React.isValidElement(data.succeeded)) {
      return data.succeeded;
    } else if (typeof data.succeeded === 'string') {
      return <Badge className="bg-green-500 text-white px-2 py-1 rounded">{data.succeeded}</Badge>;
    } else {
      return (
        <Badge className={`px-2 py-1 rounded ${data.succeeded ? 'bg-green-500 text-white' : 'bg-red-500 text-white'}`}>
          {data.succeeded ? 'Succeeded' : 'Failed'}
        </Badge>
      );
    }
  };

  const rlzTitle = data[t('realization').toLowerCase()].title;

  return (
    <Card
      isClickable
      className="p-4 bg-gray-50 shadow-sm hover:shadow-md transition-shadow duration-300"
      style={{ width: '350px', margin: '10px' }}
    >
      <CardHeader>
        <Split hasGutter>
          <SplitItem isFilled>{getSuccessBadge()}</SplitItem>
          <SplitItem>
            <KebabMenu actions={actions} row={data} isCardView={true} />
          </SplitItem>
        </Split>
      </CardHeader>
      <CardBody>
        <Grid hasGutter className="mt-4">
          <GridItem span={12}>
            <TextContent>
              <Text component={TextVariants.h3} className="text-lg font-medium">
                <Flex alignItems={{ default: 'alignItemsCenter' }}>
                  <FlexItem className="text-blue-500 mr-2">
                    <FlaskIcon />
                  </FlexItem>
                  <FlexItem>{rlzTitle}</FlexItem>
                </Flex>
              </Text>
            </TextContent>
          </GridItem>
          <GridItem span={12} className="my-3">
            <Flex>
              <FlexItem className="mr-2">
                <Label className="bg-blue-100 text-blue-800 px-2 py-1 rounded">
                  Experiment: {data.experiment.title}
                </Label>
              </FlexItem>
              <FlexItem>
                <Label className="bg-cyan-100 text-cyan-800 px-2 py-1 rounded">Project: {data.project.title}</Label>
              </FlexItem>
            </Flex>
          </GridItem>
          <GridItem span={6}>
            <TextContent>
              <Text component={TextVariants.small} className="text-gray-600">
                <Flex alignItems={{ default: 'alignItemsCenter' }}>
                  <FlexItem className="text-blue-500 mr-2">
                    <UserIcon />
                  </FlexItem>
                  <FlexItem>Creator: {data.creator.title}</FlexItem>
                </Flex>
              </Text>
              <Text component={TextVariants.small} className="text-gray-600 mt-1">
                <Flex alignItems={{ default: 'alignItemsCenter' }}>
                  <FlexItem className="text-blue-500 mr-2">
                    <CalendarAltIcon />
                  </FlexItem>
                  <FlexItem>Created: {data.created}</FlexItem>
                </Flex>
              </Text>
            </TextContent>
          </GridItem>
          <GridItem span={6}>
            <TextContent>
              <Text component={TextVariants.small} className="text-gray-600">
                <Flex alignItems={{ default: 'alignItemsCenter' }}>
                  <FlexItem className="text-blue-500 mr-2">
                    <ClockIcon />
                  </FlexItem>
                  <FlexItem>Expires: {data.expires}</FlexItem>
                </Flex>
              </Text>
              <Text component={TextVariants.small} className="text-gray-600 mt-1">
                <Flex alignItems={{ default: 'alignItemsCenter' }}>
                  <FlexItem className="text-blue-500 mr-2">
                    <CubeIcon />
                  </FlexItem>
                  <FlexItem>Nodes: {data.nodes}</FlexItem>
                </Flex>
              </Text>
              <Text component={TextVariants.small} className="text-gray-600 mt-1">
                <Flex alignItems={{ default: 'alignItemsCenter' }}>
                  <FlexItem className="text-blue-500 mr-2">
                    <LinkIcon />
                  </FlexItem>
                  <FlexItem>Links: {data.links}</FlexItem>
                </Flex>
              </Text>
            </TextContent>
          </GridItem>
          <GridItem span={12}>
            <TextContent>
              <Text component={TextVariants.small} className="text-gray-600 mt-1">
                <Flex alignItems={{ default: 'alignItemsCenter' }}>
                  <FlexItem className="text-blue-500 mr-2">
                    <CodeIcon />
                  </FlexItem>
                  <FlexItem>Revision: {data.revision.title}</FlexItem>
                </Flex>
              </Text>
            </TextContent>
          </GridItem>
        </Grid>
      </CardBody>
      <CardFooter className="mt-4">
        <Button
          variant="link"
          component={(props) => <Link {...props} to={`/realizations/${data.pid}/${data.eid}/${data.id}`} />}
          className="text-blue-600 hover:text-blue-800"
        >
          View Details
        </Button>
      </CardFooter>
    </Card>
  );
};

export { RealizationCard };
