import * as React from 'react';
import { AuthContext } from '@app/lib/AuthProvider';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import {
  ActionGroup,
  Alert,
  AlertActionLink,
  Avatar,
  Button,
  Icon,
  Modal,
  ModalVariant,
  ButtonVariant,
  Title,
  Form,
  FormGroup,
  AlertVariant,
  AlertActionCloseButton,
} from '@patternfly/react-core';
import { DropdownSeparator, Dropdown, DropdownToggle, DropdownItem } from '@patternfly/react-core/deprecated';
import CaretDownIcon from '@patternfly/react-icons/dist/esm/icons/caret-down-icon';
import UserIcon from '@patternfly/react-icons/dist/esm/icons/user-icon';
import { Link, useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';
import ory from '@app/lib/OrySdk';
import { GetUsersResponse, GetUserResponse } from '@mergetb/api/portal/v1/workspace_types';
// import { useFetch } from 'use-http';
import { FetchSelect } from '@app/lib/FetchSelect';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/esm/icons/exclamation-circle-icon';
import Cookies from 'js-cookie';

type AccountMenuProps = {};

const AccountMenu: React.FunctionComponent<AccountMenuProps> = () => {
  const { api } = React.useContext(GeneralSettingsContext);
  const { isAuthenticated, identity, setAdmin, isAdmin, user, setUser } = React.useContext(AuthContext);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const [userInactive, setUserInactive] = React.useState<boolean>(false);

  const options = { credentials: 'include', cachePolicy: 'no-cache' };
  const logout = CreateLogoutHandler([isAuthenticated]);

  const sudoUserCookie = 'x-mergetb-param-sudo-username';
  const [sudoModalOpen, setSudoModalOpen] = React.useState<boolean>(false);
  const [sudoUser, setSudoUser] = React.useState<string>(Cookies.get(sudoUserCookie));
  const [chosenSudoUser, setChosenSudoUser] = React.useState<string>('');
  const [getUsersResponse, setGetUsersResponse] = React.useState<GetUsersResponse>();

  React.useEffect(() => {
    const sudoUserCookieAttrs = {
      path: '/',
      domain: env.REACT_APP_MERGE_LAUNCH_DOMAIN,
      secure: true,
      sameSite: 'lax',
      // httpOnly: true,
    };

    if (sudoUser) {
      console.log('setting cookie', sudoUserCookieAttrs);
      console.log('cookie user', sudoUser);
      Cookies.set(sudoUserCookie, sudoUser, sudoUserCookieAttrs);
    } else {
      Cookies.remove(sudoUserCookie, sudoUserCookieAttrs);
    }
    console.log('sudouser:', sudoUser);
  }, [sudoUser]);

  const mapUsers = (json: GetUsersResponse) => {
    // Store the list of users away so we can set the auth user to the sudo user later.
    setGetUsersResponse(json);
    const req = GetUsersResponse.fromJSON(json);
    return req.users.filter((u) => u.username != identity?.traits.username).map((u) => u.username);
  };

  const submitSudoModal = () => {
    setSudoUser(chosenSudoUser);
    setChosenSudoUser('');
    setSudoModalOpen(false);
    const u = getUsersResponse?.users.find((u) => u.username == chosenSudoUser);
    setUser(u);
  };

  const unsetSudoUser = () => {
    setSudoUser('');
    console.log('logged in user', identity?.traits?.username);
    const u = getUsersResponse?.users.find((u) => identity?.traits?.username == chosenSudoUser);
    setUser(u);
  };

  const sudoModal = (
    <Modal
      isOpen={sudoModalOpen}
      onClose={() => setSudoModalOpen(false)}
      variant={ModalVariant.medium}
      aria-label={'Act as User'}
    >
      <React.Fragment>
        <Title headingLevel="h1" size="2xl">
          Act as User
        </Title>
        <Form>
          <FormGroup label={name} fieldId={'act-as-user'} isRequired={true}>
            <FetchSelect
              label={'Act as User...'}
              url={api + '/users'}
              onSelect={(v) => setChosenSudoUser(v)}
              mapItems={mapUsers}
            />
          </FormGroup>
          <ActionGroup>
            <Button variant="control" onClick={submitSudoModal}>
              Submit
            </Button>
          </ActionGroup>
        </Form>
      </React.Fragment>
    </Modal>
  );

  const sudoAlert = React.useMemo(() => {
    if (sudoUser) {
      return (
        <Alert
          isInline
          variant={AlertVariant.danger}
          title={`You are acting as another user`}
          actionClose={<AlertActionCloseButton onClose={() => setSudoUser('')} />}
          actionLinks={<AlertActionLink onClick={unsetSudoUser}>Stop Acting As {sudoUser}</AlertActionLink>}
        >
          WARNING: You are acting as user {sudoUser}.
        </Alert>
      );
    }
    return undefined;
  }, [sudoUser]);

  const dropdownItems = [
    <DropdownItem key="settings" component={<Link to="/settings">Update Identity Profile</Link>} />,
    <DropdownItem key="userupdate" component={<Link to={'/updateuserinfo'}>Update Portal User Profile</Link>} />,
    <DropdownItem
      key="logout"
      component={
        <Button onClick={logout} variant="link">
          Logout
        </Button>
      }
    />,
  ];

  if (isAdmin) {
    dropdownItems.push(<DropdownSeparator key="separator" />);
    dropdownItems.push(
      <DropdownItem
        key="sudo"
        component={
          <Button variant={ButtonVariant.warning} onClick={() => setSudoModalOpen(true)}>
            Act As User
          </Button>
        }
      />
    );
  }

  const accountMenuStyles = {
    wrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    dropdown: {
      display: 'flex',
      alignItems: 'center',
    },
    avatarWrapper: {
      marginRight: '8px', // Keep this as it was
    },
    username: {
      marginRight: '0px', // Remove margin to the right of username
    },
    caretIcon: {
      marginLeft: '2px', // Add a small margin to the left of the caret icon
    },
  };

  const icon = React.useMemo(() => {
    if (identity?.traits.picture) {
      return <Avatar src={identity?.traits.picture} alt="avatar" size="md" />;
    }

    return <UserIcon />;
  }, [identity]);

  const reddot = React.useMemo(() => {
    if (isAdmin) {
      return (
        <Icon status="danger">
          <ExclamationCircleIcon />
        </Icon>
      );
    }
  }, [isAdmin]);

  // read User data and set admin in auth context.
  React.useEffect(() => {
    if (identity === undefined || identity.traits.username === '' || user !== undefined) {
      return;
    }

    // const controller = new AbortController();

    fetch(api + '/user/' + identity.traits.username, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      // signal: controller.signal,
    })
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((data) => {
            const resp = GetUserResponse.fromJSON(data);
            if (resp.user !== undefined) {
              setAdmin(resp.user.admin);
              setUser(resp.user);
            }
          });
        } else if (resp.status === 400) {
          resp.json().then((data) => {
            if (Object.prototype.hasOwnProperty.call(data, 'code') && data.code === 9) {
              if (Object.prototype.hasOwnProperty.call(data, 'message') && data.message === 'User Inactive') {
                setUserInactive(true);
              }
            }
          });
        }
      })
      .catch((err) => {
        console.log('fetch user data error', err);
      });

    // return () => controller.abort();
  }, [identity, setAdmin, isAuthenticated, identity?.traits.username, api, setUser, user]);

  const userInactiveModal = (
    <Modal
      isOpen={userInactive}
      variant={ModalVariant.medium}
      title="User Inactive"
      aria-label={'User Inactive'}
      onClose={logout}
      actions={[
        <Button key="confirm" variant="primary" onClick={logout}>
          Logout
        </Button>,
      ]}
    >
      Your account is currently not active. Please ask a testbed administrator or an organization administrator (of
      which you are a member) to approve your account.
    </Modal>
  );

  return (
    <React.Fragment>
      {sudoAlert}
      {sudoModal}
      {userInactive && userInactiveModal}
      {isAuthenticated ? (
        <div style={accountMenuStyles.wrapper}>
          <Dropdown
            onSelect={() => setIsOpen(!isOpen)}
            isOpen={isOpen}
            isPlain
            isFullHeight
            position={'right'}
            toggle={
              <DropdownToggle
                id="user-settings-dropdown"
                onToggle={(_event, next) => setIsOpen(next)}
                toggleIndicator={CaretDownIcon}
                style={accountMenuStyles.dropdown}
              >
                <span style={accountMenuStyles.avatarWrapper}>{icon}</span>
                <span style={accountMenuStyles.username}>{identity?.traits.username}</span>
              </DropdownToggle>
            }
            dropdownItems={dropdownItems}
          />
          {reddot}
        </div>
      ) : (
        <Link to="/login">Login</Link>
      )}
    </React.Fragment>
  );
};

// Return a function that logs the user out.
const CreateLogoutHandler: React.FC = (deps?: React.DependencyList) => {
  const [logoutToken, setLogoutToken] = React.useState<string>('');
  const history = useHistory();
  const { setSession } = React.useContext(AuthContext);

  React.useEffect(() => {
    let mounted = true;
    ory
      .createBrowserLogoutFlow()
      .then(({ data }) => {
        if (mounted) {
          setLogoutToken(data.logout_token);
        }
      })
      .catch((err: AxiosError) => {
        switch (err.response?.status) {
          case 401:
            // do nothing, the user is not logged in
            return;
        }

        // Something else happened!
        return Promise.reject(err);
      });

    return () => {
      mounted = false;
    };
  }, deps);

  return () => {
    if (logoutToken) {
      ory.updateLogoutFlow({ token: logoutToken }).then(() => {
        setSession(undefined);
        history.push('/login');
        history.go(0);
      });
    }
  };
};

export { AccountMenu };
