import * as React from 'react';

import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import { FetchSelect } from '@app/lib/FetchSelect';
import { TopologyView } from '@app/Revision/TopologyView';

import { CodeEditor, CodeEditorControl, Language } from '@patternfly/react-code-editor';
import {
  Alert,
  AlertActionCloseButton,
  ActionGroup,
  PageSection,
  Card,
  CardTitle,
  CardBody,
  CardHeader,
  Button,
  Split,
  SplitItem,
  Title,
  Modal,
  ModalVariant,
  TextInput,
  Form,
  FormGroup,
} from '@patternfly/react-core';
import { DropdownItem, Dropdown, DropdownToggle, KebabToggle } from '@patternfly/react-core/deprecated';
import { Link } from 'react-router-dom';

import CaretDownIcon from '@patternfly/react-icons/dist/esm/icons/caret-down-icon';
import PlayIcon from '@patternfly/react-icons/dist/esm/icons/play-icon';
import RepositoryIcon from '@patternfly/react-icons/dist/esm/icons/repository-icon';
import DeleteIcon from '@patternfly/react-icons/dist/esm/icons/trash-icon';
import { eventEmitter } from '@app/Tutorials/EventEmitter';

const Models: React.FunctionComponent = () => {
  // check local storage for preferences
  const load = localStorage.getItem('editor_preferences');
  let prefs = {};
  if (load != null) {
    prefs = JSON.parse(load);
  }

  const [code, setCode] = React.useState(''); // mx code
  const [model, setModel] = React.useState({ id: '', nodes: [], links: [] });
  const [ddOpen, setDDOpen] = React.useState(false);
  const [dark, setDark] = React.useState(prefs.dark ?? true);
  const [lineNumbers, setLineNumbers] = React.useState(prefs.lineNumbers ?? true);
  const [minimap, setMinimap] = React.useState(prefs.minimap ?? true);
  const [pushIsOpen, setPushIsOpen] = React.useState(false);
  const [pushXp, setPushXp] = React.useState('');
  const [pageAlert, setPageAlert] = React.useState({ variant: '', title: '', message: '' });
  const [ckIsOpen, setCKIsOpen] = React.useState(false);

  const conf = React.useContext(GeneralSettingsContext);

  const savePrefs = () => {
    var prefs = {
      dark: dark,
      lineNumbers: lineNumbers,
      minimap: minimap,
    };

    localStorage.setItem('editor_preferences', JSON.stringify(prefs));
  };

  React.useEffect(() => {
    savePrefs();
  }, [dark, lineNumbers, minimap]);

  const compile = () => {
    setPageAlert({ variant: 'info', title: 'compiling...', message: '' });
    setModel({ id: '', nodes: [], links: [] });

    // https://mergetb.gitlab.io/api/#operation/Model_Compile
    fetch(conf.api + '/model/compile', {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        model: code,
      }),
    })
      .then((resp) => resp.json())
      .then((json) => {
        if (json.success === true) {
          setPageAlert({
            variant: 'success',
            title: 'Compilation Successful',
            message: '',
          });
          const mdl = JSON.parse(json.network);
          setModel(mdl);
        } else if (json.errors) {
          setPageAlert({
            variant: 'danger',
            title: 'Compilation Failed: ',
            message: json.errors.join('\n'),
          });
        } else if (json.message) {
          let m = json.message;
          if (json.details[0].fieldViolations) {
            for (let i = 0; i < json.details[0].fieldViolations.length; i++) {
              let element = json.details[0].fieldViolations[i];
              if (element.field == 'Detail') {
                m = element.description;
              }
            }
          }

          setPageAlert({
            variant: 'danger',
            title: json.message,
            message: m,
          });
        }
      })
      .catch((error) => {
        setPageAlert({ variant: 'danger', title: 'Error', message: error });
      });
  };

  const pushToExp = () => {
    setPushIsOpen(true);
  };

  const pushClose = () => {
    setPushXp('');
    setPushIsOpen(false);
  };

  const doPush = () => {
    // see https://mergetb.gitlab.io/api/#operation/Model_Push
    const tkns = pushXp.split('.');
    fetch(conf.api + '/repository/' + tkns[1] + '/' + tkns[0] + '/push', {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        model: code, // push the python mergemx code.
        branch: '',
        tag: '',
      }),
    })
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        }
        // response not ok. parse json for error.
        resp.json().then((json) => {
          setPageAlert({
            variant: 'danger',
            title: 'Request Error',
            message: json.message,
          });
        });
      })
      .then((json) => {
        // Response OK, display success message.
        setPageAlert({
          variant: 'success',
          title: 'Model Pushed',
          message: (
            <React.Fragment>
              Revision created in <Link to={'/project/' + tkns[1] + '/experiment/' + tkns[0]}>{pushXp}</Link>:{' '}
              <Link to={'/model/' + tkns[1] + '/' + tkns[0] + '/' + json.revision}>{json.revision}</Link>
            </React.Fragment>
          ),
        });

        // Add this line here
        eventEmitter.emit('modelPushed', {
          experimentName: pushXp,
          revisionHash: json.revision,
        });
      })
      .catch((error) => {
        // some non API error.
        setPageAlert({
          variant: 'Danger',
          title: 'Danger',
          message: error,
        });
      });

    pushClose();
  };

  function mapExp(json: any): Array<string> {
    console.log('map exp data', json);
    return json.experiments.map((e) => e.name + '.' + e.project);
  }

  const pushModal = (
    <Modal isOpen={pushIsOpen} onClose={pushClose} variant={ModalVariant.medium} aria-label="Push Model to Experiment">
      <React.Fragment>
        <Title headingLevel="h1" size="2xl">
          Push Model to Experiment
        </Title>
        <Form isHorizontal>
          <FormGroup fieldId="experiment" label="Experiment" isRequired={true}>
            <FetchSelect
              placeholderText="Choose an Experiment"
              label="Experiment"
              url={conf.api + '/experiment'}
              onSelect={(val) => setPushXp(val)}
              mapItems={mapExp}
            />
          </FormGroup>
          <FormGroup label="Branch">
            <TextInput type="text" id="branch" value="master" readOnlyVariant="default" />
          </FormGroup>
          <FormGroup label="Tag">
            <TextInput type="text" id="tag" value="" readOnlyVariant="default" />
          </FormGroup>
          <ActionGroup>
            <Button variant="control" isDisabled={pushXp === ''} isAriaDisabled={pushXp === ''} onClick={doPush}>
              Push
            </Button>
          </ActionGroup>
        </Form>
      </React.Fragment>
    </Modal>
  );

  const dropdownItems = [
    <DropdownItem
      key="darkmode"
      component={
        <Button onClick={() => setDark(!dark)} variant="link">
          {dark ? 'Light' : 'Dark'} Mode
        </Button>
      }
    />,
    <DropdownItem
      key="numbers"
      component={
        <Button onClick={() => setLineNumbers(!lineNumbers)} variant="link">
          {lineNumbers ? 'Hide' : 'Show'} Line Numbers
        </Button>
      }
    />,
    <DropdownItem
      key="minimap"
      component={
        <Button onClick={() => setMinimap(!minimap)} variant="link">
          {minimap ? 'Hide' : 'Show'} Minimap
        </Button>
      }
    />,
  ];
  const dropdown = (
    <React.Fragment>
      <Dropdown
        onSelect={() => setDDOpen(!ddOpen)}
        isOpen={ddOpen}
        isPlain
        position="right"
        toggle={
          <DropdownToggle
            id="user-settings-dropdown"
            onToggle={(_event, next) => setDDOpen(next)}
            toggleIndicator={CaretDownIcon}
          >
            Editor
          </DropdownToggle>
        }
        dropdownItems={dropdownItems}
      />
    </React.Fragment>
  );

  const customControl = [
    <CodeEditorControl
      key="compilecontrol"
      icon={<PlayIcon />}
      aria-label="Compile Code"
      tooltipProps={{ content: 'Compile Code' }}
      onClick={() => compile()}
      isVisible={code !== ''}
    />,
    <CodeEditorControl
      key="pushcontrol"
      icon={<RepositoryIcon />}
      aria-label="Push"
      tooltipProps={{ content: 'Push to Experiment Repository' }}
      onClick={() => pushToExp()}
      isVisible={code !== ''}
    />,
    <CodeEditorControl
      key="clear"
      icon={<DeleteIcon />}
      aria-label="Clear"
      tooltipProps={{ content: 'Clear Editor' }}
      onClick={() => onEditChange('')}
      isVisible={code !== ''}
    />,
  ];

  const header = (
    <PageSection>
      <Split>
        <SplitItem>
          <Title headingLevel="h1" size="lg">
            Models
          </Title>
        </SplitItem>
        <SplitItem isFilled />
        <SplitItem>{dropdown}</SplitItem>
      </Split>
    </PageSection>
  );

  React.useEffect(() => {
    const code = localStorage.getItem('modelcode');
    if (code) {
      setCode(code);
    }
  });

  const onEditorDidMount = (editor: any, monaco: any) => {
    editor.layout();
    editor.focus();
    monaco.editor.getModels()[0].updateOptions({ tabSize: 4 });
  };

  const onEditChange = (value: string) => {
    setCode(value);
    localStorage.setItem('modelcode', value);
  };

  return (
    <React.Fragment>
      {header}
      {pushModal}
      <PageSection>
        {pageAlert.title !== '' && (
          <Alert
            variant={pageAlert.variant}
            isInline
            title={pageAlert.title}
            actionClose={
              <AlertActionCloseButton onClose={() => setPageAlert({ title: '', variant: '', message: '' })} />
            }
          >
            <pre>{pageAlert.message}</pre>
          </Alert>
        )}
      </PageSection>
      <PageSection>
        <CodeEditor
          isUploadEnabled
          isDownloadEnabled
          isCopyEnabled
          isDarkTheme={dark}
          isLineNumbersVisible={lineNumbers}
          isMinimapVisible={minimap}
          isLanguageLabelVisible
          code={code}
          onChange={onEditChange}
          language={Language.python}
          onEditorDidMount={onEditorDidMount}
          customControls={customControl}
          height="800px"
        />
      </PageSection>
      <PageSection>
        {model && model.hasOwnProperty('id') && model.id !== '' && (
          <Card id="compiledmodel">
            <CardHeader
              actions={{
                actions: (
                  <Dropdown
                    isOpen={ckIsOpen}
                    toggle={<KebabToggle onToggle={setCKIsOpen} />}
                    isPlain
                    position="right"
                    dropdownItems={[
                      <DropdownItem
                        key="cclose"
                        onClick={() => {
                          setPageAlert({ variant: '', title: '', message: '' });
                          setModel({ id: '', nodes: [], links: [] });
                        }}
                      >
                        Close
                      </DropdownItem>,
                    ]}
                  />
                ),
                hasNoOffset: true,
                className: undefined,
              }}
            >
              <CardTitle>Compiled Topology</CardTitle>
            </CardHeader>
            <CardBody>
              <TopologyView model={model} />
            </CardBody>
          </Card>
        )}
      </PageSection>
    </React.Fragment>
  );
};

export { Models };
