import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Alert,
  AlertGroup,
  Button,
  Form,
  FormGroup,
  FormSelect,
  FormSelectOption,
  TextInput,
  Card,
  CardBody,
  ActionGroup,
  GridItem,
} from '@patternfly/react-core';
import { useFetch } from 'use-http';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import { RegisterRequest } from '@mergetb/api/portal/v1/identity_types';
import { GetUserConfigurationsResponse } from '@mergetb/api/portal/v1/workspace_types';
import { AuthContext } from '@app/lib/AuthProvider';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/esm/icons/exclamation-circle-icon';

import { Grid }  from '@patternfly/react-core';


interface UserInfoProps {
  existingUser?: boolean;
}

const UserInfo: React.FunctionComponent<UserInfoProps> = ({ existingUser }) => {
  const [otherinst, setOtherInst] = React.useState<string>('');
  const [institution, setInstition] = React.useState<string>('');
  const [country, setCountry] = React.useState<string>();
  const [usstate, setUsstate] = React.useState<string>();
  const [accountCategory, setAccountCategory] = React.useState<string>('');
  const [fullname, setFullname] = React.useState<string>('');
  const [fullnameError, setFullnameError] = React.useState<string>('');
  const [regError, setRegError] = React.useState<string>('');
  const { identity, session, user } = React.useContext(AuthContext);

  React.useEffect(() => {
    if (user !== undefined) {
      setInstition(user.institution || '');
      setCountry(user.country || '');
      setUsstate(user.usstate || '');
      setAccountCategory(user.category || '');
      setFullname(user.name || '');
    } else if (userConf) {
      setInstition(userConf.institutions[0] || '');
      setAccountCategory(userConf.categories[0] || '');
      setCountry('United States');
      setUsstate(userConf.usstates[0]?.name || '');
    }
  }, [user]);

  const history = useHistory();
  const conf = React.useContext(GeneralSettingsContext);

  const options = {
    credentials: 'include',
    cachePolicy: 'no-cache',
  };

  const { data: userConfigData } = useFetch(conf.api + '/configurations/user', options, []);

  // read and parse userConfigData once is shows up.
  const userConf = React.useMemo(() => {
    if (userConfigData && !existingUser && user === undefined) {
      if (Object.prototype.hasOwnProperty.call(userConfigData, 'institutions')) {
        const c = GetUserConfigurationsResponse.fromJSON(userConfigData);
        setInstition(c.institutions[0] || '');
        setAccountCategory(c.categories[0] || '');
        setCountry('United States');
        setUsstate(c.usstates[0]?.name || '');
        return c;
      }
    }
    return undefined;
  }, [userConfigData, existingUser, user]);

  // If we have a session and the user is not using password authentication, let them
  // know that they will need to set a password via the change password page
  // before they can login with a pasword.
  const passwordWarning = React.useMemo(() => {
    // do not warn about password for existing users.
    if (existingUser) {
      return undefined;
    }

    let password = false;
    session?.authentication_methods?.forEach((m) => {
      if (m.method == 'password') {
        // TODO figure out how to reference the enum 'passsword' value directly in TS.
        password = true;
      }
    });

    // no password auth method, ask the user for a command line password.
    if (password) {
      return undefined;
    }

    return (
      <Alert title="Password Warning" aria-label="Password Warning" variant="warning">
        <p>
          Note that as you registered without a password, you will need to set a new password via the change password
          mechanism before you can login with a password on this interface or any command line tools.
        </p>
      </Alert>
    );
  }, [session, existingUser]);

  const notImplementedWarning = React.useMemo(() => {
    if (existingUser) {
      return (
        <Alert title="Not Implemented" aria-label="Not Implemented" variant="danger">
          Updating Portal user profile is not yet implemented. Profile below is your current information. Speak to the
          portal administrator if you need to update it.
        </Alert>
      );
    }
    return undefined;
  }, [existingUser]);

  const onSubmit = () => {
    if (!existingUser) {
      const req: RegisterRequest = {
        username: identity?.traits?.username,
        email: identity?.traits?.email,
        password: 'changemeplease',
        name: fullname,
        institution: institution === 'Other' ? otherinst : institution,
        category: accountCategory,
        usstate: usstate ? usstate : '',
        country: country ? country : '',
        admin: false,
      };

      fetch(conf.api + '/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(req),
      })
        .then((resp) => {
          if (!resp.ok) {
            return resp.json().then((j) => {
              console.log('error message', j.message);
              throw new Error(j.message);
            });
          }

          if (resp.ok) {
            // we successfully submitted the user info, so lets redirect to the dashboard
            history.push('/', { replace: true });
          }
        })
        .catch((err) => {
          err.json().then((j) => {
            setRegError(j.message);
            // TODO: Need to unregister user here if merge api error.
          });
        });
    } else {
      // TODO Add API call to update user info and call it here.
    }
  };

  const handleFullnameChange = (value) => {
    setFullname(value);
    setFullnameError('');
  };

  const handleSetCountry = (value) => {
    setCountry(value);
    if (value !== 'United States') {
      setUsstate('');
    }
  };

  return (
    <Card>
      <CardBody>
        <div className="scrollable-form-container">
          <Form>
            {notImplementedWarning}
            {passwordWarning}
            <Grid hasGutter>
              <GridItem span={12}>
                <FormGroup label="Username" isRequired>
                  <TextInput
                    value={identity?.traits?.username}
                    label="Username"
                    isReadOnly
                    isRequired
                    aria-label="Username"
                  />
                </FormGroup>
              </GridItem>
              <GridItem span={12}>
                <FormGroup label="Email" isRequired>
                  <TextInput value={identity?.traits?.email} isReadOnly aria-label="Email" isRequired />
                </FormGroup>
              </GridItem>
              <GridItem span={12}>
                <FormGroup
                  helperTextInvalid={fullnameError}
                  helperTextInvalidIcon={fullnameError !== '' && <ExclamationCircleIcon />}
                  label="Full Name"
                  fieldId="fullname"
                  isRequired
                  validated={fullnameError !== '' ? 'error' : 'success'}
                >
                  <TextInput
                    isDisabled={user !== undefined}
                    isRequired
                    onChange={(_, value) => handleFullnameChange(value)}
                    type="text"
                    value={fullname}
                    aria-label={'Full Name'}
                    label={'Full Name'}
                  />
                </FormGroup>
              </GridItem>
              <GridItem span={12}>
                <FormGroup label="Institution" fieldId="institution" isRequired>
                  <FormSelect
                    value={institution}
                    onChange={(_, value) => setInstition(value)}
                    isDisabled={user !== undefined}
                    aria-label="Institution"
                  >
                    {(user ? [{ institution: user.institution }] : userConf?.institutions || []).map((e, i) => (
                      <FormSelectOption key={i} value={e.institution || e} label={e.institution || e} />
                    ))}
                  </FormSelect>
                </FormGroup>
              </GridItem>
              {institution === 'Other' && (
                <GridItem span={12}>
                  <FormGroup label="Other Institution" fieldId="otherinst" isRequired>
                    <TextInput
                      isRequired
                      value={otherinst}
                      onChange={(_, value) => setOtherInst(value)}
                      aria-label="Other Institution"
                    />
                  </FormGroup>
                </GridItem>
              )}
              <GridItem span={12}>
                <FormGroup label="Account Category" fieldId="accountcategory" isRequired>
                  <FormSelect
                    value={accountCategory}
                    onChange={(_, value) => setAccountCategory(value)}
                    aria-label="Account Category"
                    isDisabled={user !== undefined}
                  >
                    {user !== undefined ? (
                      <FormSelectOption key={1} value={user.category} label={user.category} />
                    ) : (
                      userConf?.categories.map((e, i) => <FormSelectOption key={i} value={e} label={e} />)
                    )}
                  </FormSelect>
                </FormGroup>
              </GridItem>
              <GridItem span={12}>
                <FormGroup label="Country" fieldId="country" isRequired>
                  <FormSelect
                    value={country}
                    onChange={(_, value) => handleSetCountry(value)}
                    aria-label="Country"
                    isDisabled={user !== undefined}
                  >
                    {user !== undefined ? (
                      <FormSelectOption key={1} value={user.country} label={user.country} />
                    ) : (
                      userConf?.countries.map((e, i) => <FormSelectOption key={i} value={e.name} label={e.name} />)
                    )}
                  </FormSelect>
                </FormGroup>
              </GridItem>
              {country === 'United States' && (
                <GridItem span={12}>
                  <FormGroup label="US State" fieldId="usstate" isRequired>
                    <FormSelect
                      value={usstate}
                      onChange={(_, value) => setUsstate(value)}
                      aria-label="US State"
                      isDisabled={user !== undefined}
                    >
                      {(user ? [{ name: user.usstate }] : userConf?.usstates || []).map((e, i) => (
                        <FormSelectOption key={i} value={e.name} label={e.name} />
                      ))}
                    </FormSelect>
                  </FormGroup>
                </GridItem>
              )}
              <GridItem span={12}>
                <ActionGroup>
                  <Button key="confirm" onClick={onSubmit} isDisabled={existingUser}>
                    {existingUser ? 'Update' : 'Register'}
                  </Button>
                </ActionGroup>
              </GridItem>
            </Grid>
          </Form>
        </div>
      </CardBody>
    </Card>
  );
};



export { UserInfo };
