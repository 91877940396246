import React from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Text,
  TextContent,
  TextVariants,
  Button,
  Split,
  SplitItem,
  Flex,
  FlexItem,
} from '@patternfly/react-core';
import { Link } from 'react-router-dom';
import { CubeIcon, ServerIcon, UserIcon, MemoryIcon, CpuIcon, ImageIcon, KeyIcon, GlobeIcon } from '@patternfly/react-icons';
import ProjectIcon from '@patternfly/react-icons/dist/esm/icons/project-diagram-icon';
import { KebabMenu } from '@app/lib/KebabMenu';

interface XDCCardProps {
  data: any;
  actions: any[];
}

const XDCCard: React.FC<XDCCardProps> = ({ data, actions }) => {
  return (
    <Card isHoverable className="p-4 bg-gray-50 shadow-sm hover:shadow-md transition-shadow duration-300" style={{ width: '350px', margin: '10px' }}>
      <CardHeader>
        <Split hasGutter>
          <SplitItem isFilled>
            <CardTitle className="text-xl font-semibold">
              <Link to={`/xdcs/${data.project.props.text}/${data.name.props.text}`}>
                {data.name.props.text}
              </Link>
            </CardTitle>
          </SplitItem>
          <SplitItem>
            <KebabMenu 
              actions={actions.map(action => ({
                ...action,
                onClick: () => action.onClick(null, null, data)
              }))} 
              row={data} 
              isCardView={true} 
            />
          </SplitItem>
        </Split>
      </CardHeader>
      <CardBody>
        <TextContent>
          <Text component={TextVariants.h6} className="text-lg font-medium mb-3">
            <Flex alignItems={{ default: 'alignItemsCenter' }}>
              <FlexItem className="text-blue-500 mr-2">
                <ProjectIcon />
              </FlexItem>
              <FlexItem>
                Project: <Link to={`/project/${data.project.props.text}`}>{data.project.props.text}</Link>
              </FlexItem>
            </Flex>
          </Text>
          <Text component={TextVariants.small} className="text-gray-600 mt-2">
            <Flex alignItems={{ default: 'alignItemsCenter' }}>
              <FlexItem className="text-blue-500 mr-2">
                <ServerIcon />
              </FlexItem>
              <FlexItem>
                Attached: <Link to={`/materializations/${data.attached.props.text.split('.')[2]}/${data.attached.props.text.split('.')[1]}/${data.attached.props.text.split('.')[0]}`}>{data.attached.props.text}</Link>
              </FlexItem>
            </Flex>
          </Text>
          <Text component={TextVariants.small} className="text-gray-600 mt-1">
            <Flex alignItems={{ default: 'alignItemsCenter' }}>
              <FlexItem className="text-blue-500 mr-2">
                <KeyIcon />
              </FlexItem>
              <FlexItem>SSH Name: {data.ssh_name}</FlexItem>
            </Flex>
          </Text>
          <Text component={TextVariants.small} className="text-gray-600 mt-1">
            <Flex alignItems={{ default: 'alignItemsCenter' }}>
              <FlexItem className="text-blue-500 mr-2">
                <UserIcon />
              </FlexItem>
              <FlexItem>Creator: {data.creator}</FlexItem>
            </Flex>
          </Text>
          <Text component={TextVariants.small} className="text-gray-600 mt-1">
            <Flex alignItems={{ default: 'alignItemsCenter' }}>
              <FlexItem className="text-blue-500 mr-2">
                <MemoryIcon />
              </FlexItem>
              <FlexItem>Memory Limit: {data.memory_limit}</FlexItem>
            </Flex>
          </Text>
          <Text component={TextVariants.small} className="text-gray-600 mt-1">
            <Flex alignItems={{ default: 'alignItemsCenter' }}>
              <FlexItem className="text-blue-500 mr-2">
                <CpuIcon />
              </FlexItem>
              <FlexItem>CPU Limit: {data.cpu_limit}</FlexItem>
            </Flex>
          </Text>
          <Text component={TextVariants.small} className="text-gray-600 mt-1">
            <Flex alignItems={{ default: 'alignItemsCenter' }}>
              <FlexItem className="text-blue-500 mr-2">
                <ImageIcon />
              </FlexItem>
              <FlexItem>Image: {data.image}</FlexItem>
            </Flex>
          </Text>
          <Text component={TextVariants.small} className="text-gray-600 mt-1">
            <Flex alignItems={{ default: 'alignItemsCenter' }}>
              <FlexItem className="text-blue-500 mr-2">
                <GlobeIcon />
              </FlexItem>
              <FlexItem>
                URL: <a href={data.url.props.text} target="_blank" rel="noopener noreferrer">Jupyter</a>
              </FlexItem>
            </Flex>
          </Text>
        </TextContent>
      </CardBody>
      <CardFooter className="mt-4">
        <Button 
          variant="link" 
          component={(props) => <Link {...props} to={`/xdcs/${data.project.props.text}/${data.name.props.text}`} />}
          className="text-blue-600 hover:text-blue-800"
        >
          View Details
        </Button>
      </CardFooter>
    </Card>
  );
};

export { XDCCard };