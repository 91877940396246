import * as React from 'react';
import { useContext } from 'react';
import {
  Alert,
  AlertGroup,
  AlertVariant,
  PageSection,
  Card,
  CardTitle,
  CardHeader,
  CardBody,
  Bullseye,
  Spinner,
  Button,
  Grid,
  GridItem,
  Split,
  SplitItem,
  Title,
  Modal,
  ModalVariant,
  Form,
  FormGroup,
  ActionGroup,
} from '@patternfly/react-core';
import {
  GetOrganizationResponse,
  accessModeToJSON,
  userStateToJSON,
  MembershipType,
  Member,
  member_StateToJSON,
  member_RoleToJSON,
  ConfirmOrganizationMembershipRequest,
  DeleteOrganizationMemberRequest,
  DeleteOrganizationProjectRequest,
  Member_State,
  Member_Role,
  UpdateOrganizationMemberRequest,
  UpdateOrganizationRequest,
  MembershipUpdate,
  member_StateFromJSON,
  UpdateOrganizationProjectRequest,
  GetProjectsResponse,
  GetUsersResponse,
  Organization as PortalOrg,
} from '@mergetb/api/portal/v1/workspace_types';
import { useParams, Link } from 'react-router-dom';
import { AuthContext } from '@app/lib/AuthProvider';
import { sortable, headerCol } from '@patternfly/react-table';
import { ActionList } from '@app/lib/ActionList';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import { FetchSelect } from '@app/lib/FetchSelect';
import { useFetch } from 'use-http';
import { DetailEntry, DetailsTable } from '@app/lib/DetailsTable';
import { TaskStatusTable } from '@app/lib/TaskStatusTable';
import { GRPCError } from '@app/lib/error';

const Organization: React.FunctionComponent = () => {
  const { oid } = useParams();
  const conf = useContext(GeneralSettingsContext);
  const [reload, setReload] = React.useState(0);
  const { user } = React.useContext(AuthContext);
  const username = user?.username;
  const [org, setOrg] = React.useState<PortalOrg>();

  const options = { credentials: 'include', cachePolicy: 'no-cache' };
  const url = conf.api + '/organization/' + oid;
  const { loading, error, get, response } = useFetch(url, options, [reload]);
  const [loadError, setLoadError] = React.useState<GRPCError>();
  const [org, setOrg] = React.useState<PortalOrg>();

  const st_url = url + '?statusMS=-1';
  const st_getter = (data) => {
    return data.status;
  };

  const load = React.useCallback(async () => {
    const resp = await get();
    if (response.ok) {
      setOrg(GetOrganizationResponse.fromJSON(resp).organization);
    } else {
      setLoadError(resp);
    }
  }, [get, response]);

  React.useEffect(() => {
    load();
  }, [load, reload]);

  const header = (
    <PageSection>
      <Split>
        <SplitItem>
          <Title headingLevel="h1" size="lg">
            Organizations
          </Title>
        </SplitItem>
        <SplitItem isFilled />
        <SplitItem></SplitItem>
      </Split>
    </PageSection>
  );

  // if the user is an active member of the org, we allow actions on the org.
  // NB: this assumes policy on the portal, which is not great (the policy *could* state non-members
  //  are allowed to do actions on the organization). It does remove actions for randos though which
  //  is cleaner and leads to fewer questions.
  let isActive = false;
  if (org !== undefined && org.members !== undefined) {
    if (Object.keys(org?.members).findIndex((u) => u == username) !== -1) {
      if (org.members[username].state === Member_State.Active) {
        isActive = true;
      }
    }
  }

  const incReload = () => {
    setReload(reload + 1);
  };

  const details: Array<DetailEntry> = org
    ? [
        { label: 'Name', value: org.name },
        { label: 'State', value: userStateToJSON(org.state) },
        { label: 'Access Mode', value: accessModeToJSON(org.accessMode) },
        { label: 'Description', value: org.description },
        { label: 'Category', value: org.category },
        { label: 'Subcategory', value: org.subcategory },
      ]
    : [];

  return (
    <React.Fragment>
      {header}
      <PageSection>
        <Grid hasGutter>
          {error && !loadError && (
            <Alert variant="danger" title="Error">
              Error loading
            </Alert>
          )}
          {error && loadError && (
            <Alert variant="danger" title="Response Error">
              {loadError.message}
            </Alert>
          )}
          <Card id={oid + '-card-id'}>
            <CardHeader>
              <CardTitle id={oid + 'card-header-id'}>Details</CardTitle>
            </CardHeader>
            <CardBody>
              {loading && (
                <Bullseye>
                  <Spinner size="sm" />
                </Bullseye>
              )}
              {org && <DetailsTable label="orgdetails" entries={details} />}
            </CardBody>
          </Card>
          {org?.projects && (
            <Members
              showActions={isActive}
              org={org.name}
              kind={MembershipType.ProjectMember}
              members={org.projects}
              reload={incReload}
            />
          )}
          {org?.members && (
            <Members
              showActions={isActive}
              org={org.name}
              kind={MembershipType.UserMember}
              members={org.members}
              reload={incReload}
            />
          )}
          <GridItem>
            <Card id="statusCard">
              <CardHeader>
                <CardTitle id="statusCardTitle">Status</CardTitle>
              </CardHeader>
              <CardBody>
                <TaskStatusTable
                  kind={oid + '-tst'}
                  url={st_url}
                  getter={st_getter}
                  ongoingfrequency={2000}
                  completedfrequency={60000}
                  scalingfrequency={1.0 / 10.0}
                  reload={reload}
                />
              </CardBody>
            </Card>
          </GridItem>
        </Grid>
      </PageSection>
    </React.Fragment>
  );
};

interface MembersProps {
  showActions: boolean;
  org: string;
  kind: MembershipType;
  members: { [key: string]: Member } | undefined;
  reload: number | (() => void);
}

const Members: React.FunctionComponent<MembersProps> = ({ showActions, org, kind, members, reload }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [addName, setAddName] = React.useState('');
  const conf = useContext(GeneralSettingsContext);
  const name = kind == MembershipType.ProjectMember ? 'Project' : 'User';

  type memberAlert = {
    title: string;
    variant: AlertVariant;
  };
  const [alerts, setAlerts] = React.useState<Array<memberAlert>>([]);

  const columns = [
    { title: name, key: 'name', cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'Role', key: 'role', transforms: [sortable] },
    { title: 'State', key: 'state', transforms: [sortable] },
  ];

  const processedMembers = React.useMemo(() => {
    if (!members) return [];
    return Object.entries(members).map(([id, member]) => ({
      name: {
        title: <Link to={(kind === MembershipType.ProjectMember ? '/project/' : '/user/') + id}>{id}</Link>,
        props: { text: id },
      },
      role: member_RoleToJSON(member.role),
      state: member_StateToJSON(member.state),
    }));
  }, [members, kind]);

  const addAlert = (t: string, v: AlertVariant) => {
    setAlerts((prev) => {
      return [...prev, { title: t, variant: v }];
    });
    reload();
  };

  const notifications = (
    <AlertGroup isToast>
      {alerts.map((p, i) => (
        <Alert variant={p.variant} title={p.title} timeout={3000} key={i} />
      ))}
    </AlertGroup>
  );

  const confirmMember = (id: string) => {
    const req: ConfirmOrganizationMembershipRequest = {
      organization: org,
      id: id,
      kind: kind,
    };

    fetch(conf.api + '/organization/' + org + '/member/' + id + '/confirm', {
      method: 'PUT',
      credentials: 'include',
      body: JSON.stringify(req),
    })
      .then((resp) => {
        if (!resp.ok) {
          return resp
            .json()
            .catch(() => {
              // could not parse json
              addAlert(resp.statusText, AlertVariant.danger);
            })
            .then((json) => {
              addAlert(json.message, AlertVariant.danger);
            });
        }
        // success
        addAlert('Membership for ' + id + ' confirmed.', AlertVariant.success);
        return;
      })
      .catch((error) => {
        addAlert(error.message, AlertVariant.danger);
      });
  };

  const denyMember = (id: string) => {
    let path = '';
    let body = '';

    if (kind == MembershipType.ProjectMember) {
      const req: DeleteOrganizationProjectRequest = {
        organization: org,
        project: id,
      };
      path = '/organization/' + org + '/project/' + id;
      body = JSON.stringify(req);
    } else {
      const req: DeleteOrganizationMemberRequest = {
        organization: org,
        username: id,
      };
      path = '/organization/' + org + '/member/' + id;
      body = JSON.stringify(req);
    }

    fetch(conf.api + path, {
      method: 'DELETE',
      credentials: 'include',
      body: body,
    })
      .then((resp) => {
        if (!resp.ok) {
          return resp
            .json()
            .catch(() => {
              // could not parse json
              addAlert(resp.statusText, AlertVariant.danger);
            })
            .then((json) => {
              addAlert(json.message, AlertVariant.danger);
            });
        }
        // success
        addAlert('Membership for ' + id + ' denied.', AlertVariant.success);
        return;
      })
      .catch((error) => {
        addAlert(error.message, AlertVariant.danger);
      });
  };

  const updateMember = (id: string, role: Member_Role, state: Member_State) => {
    let path = '';
    let body = '';

    if (kind == MembershipType.ProjectMember) {
      const req: UpdateOrganizationMemberRequest = {
        organization: org,
        username: id,
        member: {
          role: role,
          state: state,
        },
      };
      path = '/organization/' + org + '/member/' + id;
      body = JSON.stringify(req);
    } else {
      const req: UpdateOrganizationProjectRequest = {
        organization: org,
        project: id,
        member: {
          role: role,
          state: state,
        },
      };
      path = '/organization/' + org + '/project/' + id;
      body = JSON.stringify(req);
    }

    fetch(conf.api + path, {
      method: 'POST',
      credentials: 'include',
      body: body,
    })
      .then((resp) => {
        if (!resp.ok) {
          return resp
            .json()
            .catch(() => {
              // could not parse json
              addAlert(resp.statusText, AlertVariant.danger);
            })
            .then((json) => {
              addAlert(json.message, AlertVariant.danger);
            });
        }
        // success
        addAlert('Member ' + id + ' updated.', AlertVariant.success);
        return;
      })
      .catch((error) => {
        addAlert(error.message, AlertVariant.danger);
      });
  };

  const removeMember = (id: string) => {
    let path = '';

    if (kind == MembershipType.UserMember) {
      path = '/organization/' + org + '/member/' + id;
    } else {
      path = '/organization/' + org + '/project/' + id;
    }

    fetch(conf.api + path, {
      method: 'DELETE',
      credentials: 'include',
    })
      .then((resp) => {
        if (!resp.ok) {
          return resp
            .json()
            .catch(() => {
              // could not parse json
              addAlert(resp.statusText, AlertVariant.danger);
            })
            .then((json) => {
              addAlert(json.message, AlertVariant.danger);
            });
        }
        // success
        addAlert('Removed ' + id + ' from organization.', AlertVariant.success);
        return;
      })
      .catch((error) => {
        addAlert(error.message, AlertVariant.danger);
      });
  };

  const rows = members
    ? Object.keys(members).map((id) => {
        return [
          {
            title: <Link to={(kind === MembershipType.ProjectMember ? '/project/' : '/user/') + id}>{id}</Link>,
            props: {
              component: 'th',
              text: id,
            },
          },
          member_RoleToJSON(members[id].role),
          member_StateToJSON(members[id].state),
        ];
      })
    : [];

  let actions: any = [];

  if (showActions) {
    actions = [
      {
        title: 'Approve Membership',
        onClick: (event, rowId, rowData) => {
          console.log('rowData', rowData);
          const id = rowData.name.props.text;
          confirmMember(id);
        },
      },
      {
        title: 'Deny Membership',
        onClick: (event, rowId, rowData) => {
          const id = rowData.name.props.text;
          denyMember(id);
        },
      },
      {
        isSeparator: true,
      },
      {
        title: 'Remove ' + name,
        onClick: (e, rowId, rowData) => removeMember(rowData[0].props.text),
      },
    ];

    if (kind == MembershipType.UserMember) {
      actions.push(
        {
          isSeparator: true,
        },
        {
          title: 'Set Role ' + member_RoleToJSON(Member_Role.Creator),
          onClick: (e, rowId, rowData) => {
            updateMember(rowData.name.props.text, Member_Role.Creator, member_StateFromJSON(rowData[2]));
          },
        },
        {
          title: 'Set Role ' + member_RoleToJSON(Member_Role.Maintainer),
          onClick: (e, rowId, rowData) => {
            updateMember(rowData.name.props.text, Member_Role.Maintainer, member_StateFromJSON(rowData[2]));
          },
        },
        {
          title: 'Set Role ' + member_RoleToJSON(Member_Role.Member),
          onClick: (e, rowId, rowData) => {
            updateMember(rowData.name.props.text, Member_Role.Member, member_StateFromJSON(rowData[2]));
          },
        }
      );
    }
  }

  const mapProjs = (json: any) => {
    const req = GetProjectsResponse.fromJSON(json);
    // Do not list members that already exist.
    return req.projects.filter((p) => !Object.hasOwnProperty.call(members, p.name)).map((p) => p.name);
  };

  const mapUsers = (json: any) => {
    const req = GetUsersResponse.fromJSON(json);
    return req.users.filter((u) => !Object.hasOwnProperty.call(members, u.username)).map((u) => u.username);
  };

  const submitForm = () => {
    const req = UpdateOrganizationRequest.fromJSON({});
    req.name = org;
    const update: MembershipUpdate = {
      remove: [],
      set: {},
    };
    update.set[addName] = {
      role: Member_Role.Member,
      state: Member_State.Active,
    };

    if (kind == MembershipType.ProjectMember) {
      req.projects = update;
    } else {
      req.members = update;
    }

    fetch(conf.api + '/organization/' + org, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(req),
    })
      .then((resp) => {
        if (resp.ok) {
          addAlert(name + ' added to organization', AlertVariant.success);
          reload();
        }
        if (resp.status !== 200) {
          addAlert('Error: ' + resp.statusText, AlertVariant.danger);
        }
        return;
      })
      .catch((error) => {
        console.log(error);
        addAlert(error.message, AlertVariant.danger);
      });

    setIsModalOpen(false);
  };

  const newModal = (
    <Modal
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      variant={ModalVariant.medium}
      aria-label={'New ' + name}
    >
      <React.Fragment>
        <Title headingLevel="h1" size="2xl">
          New {name}
        </Title>
        <Form>
          <FormGroup label={name} fieldId={name} isRequired={true}>
            <FetchSelect
              placeholderText={kind === MembershipType.ProjectMember ? 'Choose a Project' : 'Choose a User'}
              label={'Add ' + name + ' to Organization'}
              url={conf.api + (kind === MembershipType.ProjectMember ? '/project' : '/users')}
              onSelect={(v) => setAddName(v)}
              mapItems={kind == MembershipType.ProjectMember ? mapProjs : mapUsers}
            />
          </FormGroup>
          <ActionGroup>
            <Button variant="control" onClick={submitForm}>
              Submit
            </Button>
          </ActionGroup>
        </Form>
      </React.Fragment>
    </Modal>
  );

  const add = 'Add ' + name;
  return (
    <Card id="members-card">
      {alerts.length !== 0 && notifications}
      {newModal}
      <CardHeader
        actions={{
          actions: (
            <Button variant="control" aria-label={add} onClick={() => setIsModalOpen(true)}>
              {add}
            </Button>
          ),
          hasNoOffset: false,
          className: undefined,
        }}
        id="card-header-id"
      >
        <CardTitle id="card-title-id">{kind == MembershipType.ProjectMember ? 'Projects' : 'Users'}</CardTitle>
      </CardHeader>
      <CardBody>
        <ActionList
          reload={reload}
          kind={kind === MembershipType.ProjectMember ? 'Projects' : 'Users'}
          columns={columns}
          rows={processedMembers}
          actions={actions}
        />
      </CardBody>
    </Card>
  );
};

export { Organization };
