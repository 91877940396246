import React from 'react';
import {
	treeRow,
	IRow,
	OnTreeRowCollapse
} from '@patternfly/react-table';
import {
	Table,
	TableHeader,
	TableBody
} from '@patternfly/react-table/deprecated';
import RedoIcon from '@patternfly/react-icons/dist/esm/icons/redo-icon';
import CompressArrowsAltIcon from '@patternfly/react-icons/dist/esm/icons/compress-arrows-alt-icon';
import ExpandArrowsAltIcon from '@patternfly/react-icons/dist/esm/icons/expand-arrows-alt-icon';
import {
  GetIconFromStatus,
  LocalizeDate,
  ColorStringFromStatus,
  ColorStringFromLogLevel,
} from '@app/lib/TaskStatusUtils';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import { Truncate } from '@patternfly/react-core';

interface TaskStatusTreeNode {
  id: string;
  name: string;
  desc: React.ReactNode;
  status: React.ReactNode;
  creation: string;
  when: string;
  icon: React.ReactNode;
  num_all_children: number;
  children?: TaskStatusTreeNode[];
}

export const TaskTreeParser = (tt: any, pp: string): TaskStatusTreeNode[] => {
  const fullname = pp ? `${pp}|${tt.Task.Id}` : tt.Task.Id;

  const children: TaskStatusTreeNode[] = tt.Subtasks.flatMap((child: any) => TaskTreeParser(child, fullname));

  const status = tt.HighestStatus;

  return [{
    id: fullname,
    name: tt.Task.Name,
    desc: ColorStringFromLogLevel(tt.Task.Message.Message, tt.Task.Message.Level, fullname, 'level'),
    status: ColorStringFromStatus(status, status, fullname, 'status'),
    num_all_children: tt.NumChildTasks,
    creation: LocalizeDate(tt.Task.FirstUpdated),
    when: LocalizeDate(tt.LastUpdated),
    icon: GetIconFromStatus(tt.HighestStatus),
    children: children,
  }];
};

interface TaskStatusTableProps {
  kind: string;
  url?: string;
  reload: number;
  ongoingfrequency: number; // milliseconds
  completedfrequency: number; // milliseconds
  scalingfrequency: number;
  variant?: string;
  borders?: string;
  data?: any;
  actions?: any;
  getter?: any;
}

export const TaskStatusTable: React.FunctionComponent<TaskStatusTableProps> = (props) => {
  const dataRef = React.useRef<TaskStatusTreeNode[]>([]);
  const [data, setData] = React.useState<TaskStatusTreeNode[]>([]);
  const [frequency, setFrequency] = React.useState<number>(props.ongoingfrequency);
  const [expandedNodeNames, setExpandedNodeNames] = React.useState<string[]>([]);
  const [sortBy, setSortBy] = React.useState({});
  const [idSearch, setIdSearch] = React.useState('');
  const [alert, setAlert] = React.useState('');
  const [atitle, setATitle] = React.useState('');
  const conf = React.useContext(GeneralSettingsContext);
  const { reload, getter } = props;

  const columnNames = {
    empty: 'Icon',
    name: 'Name',
    desc: 'Description',
    type: 'Type',
    status: 'Status',
    version: 'Version',
    creation: 'Creation',
    when: 'Last Updated',
    id: 'ID',
  };

  React.useEffect(() => {
    updateData();
  }, [reload, props.data]);

  React.useEffect(() => {
    if (props.url && frequency > 0) {
      const interval = setInterval(() => {
        updateData();
      }, frequency);

      return () => clearInterval(interval);
    }
  }, [frequency]);

  const updateData = () => {
    var firstTime = false;
    if (dataRef.current.length == 0) {
      firstTime = true;
      setFrequency(props.ongoingfrequency);
    } else if (dataRef.current[0].status === 'Success' || (typeof dataRef.current[0].status === 'object' && dataRef.current[0].status.props && dataRef.current[0].status.props.children === 'Success')) {
      var freq = props.completedfrequency * dataRef.current[0].num_all_children * props.scalingfrequency;
      if (freq < props.completedfrequency) {
        freq = props.completedfrequency;
      }
      setFrequency(freq);
    } else {
      var freq = props.ongoingfrequency * dataRef.current[0].num_all_children * props.scalingfrequency;
      if (freq < props.ongoingfrequency) {
        freq = props.ongoingfrequency;
      }
      setFrequency(freq);
    }

    if (props.url) {
      fetch(props.url, { credentials: 'include' })
        .then((response) => {
          if (response.status == 401) {
            let err = new Error();
            err.name = 'autherror';
            throw err;
          }
          if (!response.ok) {
            console.log('response not ok: ', response);
            throw response;
          }
          return response.json();
        })
        .then((json) => {
          dataRef.current = TaskTreeParser(props.getter(json), '');
          setData([...dataRef.current]);
          if (firstTime) {
            setDefaultExpandedNodeNames();
          }
        })
        .catch((error) => {
          if (error.name === 'autherror') {
            const href = conf.auth + '/.login/web/auth/login?return_to=' + window.location.href;
            window.location.href = href;
          } else if (typeof error.json === 'function') {
            error.json().then((json) => {
              setATitle('Merge API Error');
              json.url = props.url;
              setAlert(json);
            });
          } else {
            setATitle('Network/Fetch Error');
            setAlert(error);
          }
        });
    } else if (props.data) {
      dataRef.current = TaskTreeParser(props.data, '');
      setData([...dataRef.current]);
      if (firstTime) {
        setDefaultExpandedNodeNames();
      }
    }
  };

  const setDefaultExpandedNodeNames = () => {
    var temp: string[] = [dataRef.current[0].id];

    const find_expanded = (node: TaskStatusTreeNode) => {
      if (node.status !== 'Success' && (typeof node.status !== 'object' || node.status.props.children !== 'Success')) {
        temp.push(node.id);
      }

      if (node.children) {
        node.children.forEach((child) => find_expanded(child));
      }
    };

    dataRef.current.forEach((node) => find_expanded(node));
    setExpandedNodeNames(temp);
  };

  const setAllExpandedNodeNames = () => {
    var temp: string[] = [dataRef.current[0].id];

    const find_expanded = (node: TaskStatusTreeNode) => {
      temp.push(node.id);

      if (node.children) {
        node.children.forEach((child) => find_expanded(child));
      }
    };

    dataRef.current.forEach((node) => find_expanded(node));
    setExpandedNodeNames(temp);
  };

  const getDescendants = (node: TaskStatusTreeNode): TaskStatusTreeNode[] =>
    [node].concat(...(node.children ? node.children.map(getDescendants) : []));

  const flattenedNodes: TaskStatusTreeNode[] = [];

  const buildRows = (
    [node, ...remainingNodes]: TaskStatusTreeNode[],
    level = 1,
    posinset = 1,
    rowIndex = 0,
    isHidden = false
  ): IRow[] => {
    if (!node) {
      return [];
    }
    const isExpanded = expandedNodeNames.includes(node.id);
    flattenedNodes.push(node);

    const childRows =
      node.children && node.children.length
        ? buildRows(node.children, level + 1, 1, rowIndex + 1, !isExpanded || isHidden)
        : [];

    return [
      {
        cells: [
          node.name,
          node.desc,
          node.status,
          node.creation,
          node.when,
        ],
        props: {
          isExpanded,
          isHidden,
          'aria-level': level,
          'aria-posinset': posinset,
          'aria-setsize': node.children ? node.children.length : 0,
          icon: node.icon,
        },
      },
      ...childRows,
      ...buildRows(remainingNodes, level, posinset + 1, rowIndex + 1 + childRows.length, isHidden),
    ];
  };

  const onCollapse: OnTreeRowCollapse = (_event, rowIndex) => {
    const node = flattenedNodes[rowIndex];
    const isExpanded = expandedNodeNames.includes(node.id);
    setExpandedNodeNames((prevExpanded) => {
      const otherExpandedNodeNames = prevExpanded.filter((name) => name !== node.id);
      return isExpanded ? otherExpandedNodeNames : [...otherExpandedNodeNames, node.id];
    });
  };

  const variant = props.variant ? props.variant : 'compact';
  const borders = props.borders ? props.borders : 'compactBorderless';

  return (
    <div style={{ margin: '10px' }}>
      <button onClick={updateData}>
        <RedoIcon>Refresh</RedoIcon>
      </button>
      <button onClick={setDefaultExpandedNodeNames}>
        <CompressArrowsAltIcon>Compress</CompressArrowsAltIcon>
      </button>
      <button onClick={setAllExpandedNodeNames}>
        <ExpandArrowsAltIcon>Expand</ExpandArrowsAltIcon>
      </button>
      <Table
        isTreeTable
        aria-label="Tree table"
        variant={variant}
        borders={borders}
        cells={[
          {
            title: columnNames.name,
            cellTransforms: [treeRow(onCollapse)],
          },
          columnNames.desc,
          columnNames.status,
          columnNames.creation,
          columnNames.when,
        ]}
        rows={buildRows(data)}
      >
        <TableHeader />
        <TableBody />
      </Table>
    </div>
  );
};