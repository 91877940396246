import React from 'react';
import ReactDOMClient from 'react-dom/client';
import App from '@app/index';

// if (process.env.NODE_ENV !== 'production') {
//   const config = {
//     rules: [
//       {
//         id: 'color-contrast',
//         enabled: false,
//       },
//     ],
//   };
//   // eslint-disable-next-line @typescript-eslint/no-var-requires, no-undef
//   const axe = require('react-axe');
//   axe(React, ReactDOM, 1000, config);
// }

const rootElement = document.getElementById('root');
const root = ReactDOMClient.createRoot(rootElement);
root.render(<App callback={() => console.log('rendered')} />);
