import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import { useFetch } from 'use-http';
import {
  Alert,
  PageSection,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardBody,
  GridItem,
  Grid,
  CardTitle,
  Text,
  TextVariants,
  TextContent,
  Bullseye,
  Spinner,
} from '@patternfly/react-core';
import { XDCInfo as PortalXDC, GetXDCResponse, xDCTypeToJSON } from '@mergetb/api/portal/v1/xdc_types';

import { GRPCError } from '@app/lib/error';
import { Table, Thead, Tbody, Tr, Th, Td } from '@patternfly/react-table';
import { TaskStatusTable } from '@app/lib/TaskStatusTable';

const XDC: React.FunctionComponent = () => {
  const { pid, xid } = useParams<{ pid: string; xid: string }>();
  const { api } = React.useContext(GeneralSettingsContext);

  const st_url = `${api}/xdc/instance/${xid}/${pid}?statusMS=-1`;

  const options = { credentials: 'include' as const, cachePolicy: 'no-cache' as const };

  const { loading, error, get, response } = useFetch(st_url, options, []);
  const [loadError, setLoadError] = React.useState<GRPCError>();
  const [xdc, setXDC] = React.useState<PortalXDC>();

  const load = React.useCallback(async () => {
    const resp = await get();
    if (response.ok) {
      setXDC(GetXDCResponse.fromJSON(resp).xdc);
    } else {
      setLoadError(resp);
    }
  }, [get, response]);

  React.useEffect(() => {
    load();
  }, [load]);

  const crumbs = (
    <PageSection>
      <Breadcrumb>
        <BreadcrumbItem to="/project">Projects</BreadcrumbItem>
        <BreadcrumbItem to={'/project/' + pid}>{pid}</BreadcrumbItem>
        <BreadcrumbItem to="/xdcs">XDCs</BreadcrumbItem>
        <BreadcrumbItem>{xid}</BreadcrumbItem>
      </Breadcrumb>
    </PageSection>
  );

  const renderXDCDetails = (xdc: PortalXDC) => {
    const [xid, pid] = xdc.name.split('.');
    const [mrid, meid, mpid] = xdc.materialization.split('.');

    return (
      <Table aria-label="XDC Details" variant="compact" borders className="pf-u-box-shadow-md">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Project</Th>
            <Th>Type</Th>
            <Th>Attached</Th>
            <Th>URL</Th>
            <Th>SSH Name</Th>
            <Th>Creator</Th>
            <Th>Memory Limit</Th>
            <Th>CPU Limit</Th>
            <Th>Image</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>{xid}</Td>
            <Td>{pid}</Td>
            <Td>{xDCTypeToJSON(xdc.type)}</Td>
            <Td>
              <Link to={'/materializations/' + mpid + '/' + meid + '/' + mrid}>{xdc.materialization}</Link>
            </Td>
            <Td>
              <a href={xdc.url} target="_blank" rel="noopener noreferrer">
                Jupyter
              </a>
            </Td>
            <Td>{xdc.fqdn.split('.')[0]}</Td>
            <Td>{xdc.creator}</Td>
            <Td>{xdc.memlimit}</Td>
            <Td>{xdc.cpulimit}</Td>
            <Td>{xdc.image}</Td>
          </Tr>
        </Tbody>
      </Table>
    );
  };

  const st_getter = (data: GetXDCResponse) => {
    return data.status;
  };

  return (
    <React.Fragment>
      {crumbs}
      <PageSection>
        <Grid hasGutter>
          <GridItem>
            <Card className="pf-u-mb-xl">
              <CardHeader>
                <CardTitle>
                  <TextContent>
                    <Text component={TextVariants.h2}>XDC Information</Text>
                  </TextContent>
                </CardTitle>
              </CardHeader>
              <CardBody>
                {loading && (
                  <Bullseye>
                    <Spinner size="xl" />
                  </Bullseye>
                )}
                {error && loadError && (
                  <Alert variant="danger" title="Error loading XDC data" className="pf-u-mb-md">
                    {loadError.message}
                  </Alert>
                )}
                {xdc && renderXDCDetails(xdc)}
              </CardBody>
            </Card>
          </GridItem>
          <GridItem>
            <Card>
              <CardHeader>
                <CardTitle>
                  <TextContent>
                    <Text component={TextVariants.h2}>Status</Text>
                  </TextContent>
                </CardTitle>
              </CardHeader>
              <CardBody>
                {loading && (
                  <Bullseye>
                    <Spinner size="xl" />
                  </Bullseye>
                )}
                {error && loadError && (
                  <Alert variant="danger" title="Error loading status data" className="pf-u-mb-md">
                    {loadError.message}
                  </Alert>
                )}
                <TaskStatusTable
                  kind={`${xid}-tst`}
                  url={st_url}
                  getter={st_getter}
                  ongoingfrequency={2000}
                  completedfrequency={60000}
                  scalingfrequency={1.0 / 10.0}
                />
              </CardBody>
            </Card>
          </GridItem>
        </Grid>
      </PageSection>
    </React.Fragment>
  );
};

export { XDC };
