import React, { useRef, useState } from 'react';
import {
  Dropdown,
  DropdownList,
  DropdownItem,
  MenuToggle,
  MenuToggleElement,
} from '@patternfly/react-core';
import EllipsisVIcon from '@patternfly/react-icons/dist/esm/icons/ellipsis-v-icon';

interface KebabMenuProps {
  actions: {
    title: string;
    onClick: (event: React.MouseEvent<Element, MouseEvent> | undefined, rowIndex: number | null, row: any) => void;
  }[];
  row: any;
  isCardView: boolean;
} 

const KebabMenu: React.FC<KebabMenuProps> = ({ actions, row, isCardView }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const onSelect = () => {
    setIsOpen(false);
  };

  const position = isCardView ? 'right' : 'left';

  if (!actions || actions.length === 0) {
    return null;
  }

  return (
    <div ref={menuRef} style={{ position: 'relative' }}>
      <Dropdown
        isOpen={isOpen}
        onOpenChange={(isOpen: boolean) => setIsOpen(isOpen)}
        onSelect={onSelect}
        toggle={(toggleRef: React.Ref<MenuToggleElement>) => (
          <MenuToggle
            ref={toggleRef}
            onClick={() => setIsOpen(!isOpen)}
            isExpanded={isOpen}
            aria-label="Actions"
            variant="plain"
          >
            <EllipsisVIcon />
          </MenuToggle>
        )}
        popperProps={{
          position,
          appendTo: () => document.body,
          enableFlip: true,
          flipBehavior: [position, position === 'right' ? 'left' : 'right', 'top', 'bottom'],
          maxWidth: '300px'
        }}
      >
        <DropdownList>
          {actions.map((action, index) => (
            <DropdownItem
              key={index}
              onClick={(event) => {
                action.onClick(event, null, row);
                onSelect();
              }}
            >
              {action.title}
            </DropdownItem>
          ))}
        </DropdownList>
      </Dropdown>
    </div>
  );
};

export { KebabMenu };