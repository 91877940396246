import React from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Badge,
  Text,
  TextContent,
  TextVariants,
  Grid,
  GridItem,
  Button,
  Split,
  SplitItem,
  Flex,
  FlexItem,
  Label,
} from '@patternfly/react-core';
import { Link } from 'react-router-dom';
import { ProjectDiagramIcon, UserIcon, BuildingIcon, FlaskIcon } from '@patternfly/react-icons';
import { KebabMenu } from '@app/lib/KebabMenu';

interface ExperimentCardProps {
  data: any;
  actions: any[];
}

const ExperimentCard: React.FC<ExperimentCardProps> = ({ data, actions }) => {
  return (
    <Card isHoverable style={{ width: '350px', margin: '10px' }}>
      <CardHeader>
        <Split hasGutter>
          <SplitItem isFilled>
            <Badge isRead>{data.access_mode}</Badge>
          </SplitItem>
          <SplitItem>
            <KebabMenu actions={actions} row={data} isCardView={true} />
          </SplitItem>
        </Split>
      </CardHeader>
      <CardBody>
        <Grid hasGutter>
          <GridItem span={12}>
            <TextContent>
              <Text component={TextVariants.h3}>
                <Flex alignItems={{ default: 'alignItemsCenter' }}>
                  <FlexItem className="text-blue-500 mr-2">
                    <FlaskIcon/>
                  </FlexItem>
                  <FlexItem>{data.name.title}</FlexItem>
                </Flex>
              </Text>
            </TextContent>
          </GridItem>
          <GridItem span={12}>
            <Flex>
              <FlexItem>
                <Label color="blue">{data.project.title}</Label>
              </FlexItem>
            </Flex>
          </GridItem>
          <GridItem span={6}>
            <TextContent>
              <Text component={TextVariants.small}>
                <Flex alignItems={{ default: 'alignItemsCenter' }}>
                  <FlexItem className="text-blue-500 mr-2">
                    <UserIcon />
                  </FlexItem>
                  <FlexItem>Creator: {data.creator}</FlexItem>
                </Flex>
              </Text>
            </TextContent>
          </GridItem>
          <GridItem span={6}>
            <TextContent>
              <Text component={TextVariants.small}>
                <Flex alignItems={{ default: 'alignItemsCenter' }}>
                  <FlexItem className="text-blue-500 mr-2">
                    <ProjectDiagramIcon />
                  </FlexItem>
                  <FlexItem>Project: {data.project.title}</FlexItem>
                </Flex>
              </Text>
            </TextContent>
          </GridItem>
        </Grid>
      </CardBody>
      <CardFooter>
        <Button 
          variant="link" 
          component={(props) => <Link {...props} to={data.experimentLink} />}
          className="text-blue-600 hover:text-blue-800"
        >
          View Details
        </Button>
      </CardFooter>
    </Card>
  );
};

export { ExperimentCard };