import * as React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { AuthContext } from '@app/lib/AuthProvider';
import { News } from '@app/News/News';
import { ActiveExperiments } from '@app/Dashboard/ActiveExperiments';
import { InactiveExperiments } from '@app/Dashboard/InactiveExperiments';
import { UserSummary } from '@app/Dashboard/UserSummary';
import { TopResources } from '@app/Dashboard/TopResources';
import {
  Grid,
  GridItem,
  AlertProps,
  AlertGroup,
  Alert,
  AlertVariant,
  AlertActionCloseButton,
  PageSection,
  Card,
  CardBody,
  CardTitle,
  Flex,
  FlexItem,
  Spinner,
  Text,
} from '@patternfly/react-core';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import { useFetch } from 'use-http';
import { GetMaterializationsResponse, Materialization } from '@mergetb/api/portal/v1/materialize_types';
import { GetRealizationsResponse, GetResourcesResponse, Realization } from '@mergetb/api/portal/v1/realize_types';
import { GetUserResponse } from '@mergetb/api/portal/v1/workspace_types';
import ChartPieIcon from '@patternfly/react-icons/dist/esm/icons/chart-pie-icon';
import NewsIcon from '@patternfly/react-icons/dist/esm/icons/newspaper-icon';
import UserIcon from '@patternfly/react-icons/dist/esm/icons/user-icon';
import CubeIcon from '@patternfly/react-icons/dist/esm/icons/cube-icon';
import BoxesIcon from '@patternfly/react-icons/dist/esm/icons/boxes-icon';
import { FlaskIcon } from '@patternfly/react-icons';

import { ExpandableSection } from '@patternfly/react-core';

import { css } from '@patternfly/react-styles';

const customToggleStyles = css`
  color: black;
  text-decoration: none;
  transition: all 0.3s ease;

  &:hover {
    color: #0066cc;
    text-decoration: underline;
  }
`;

const Dashboard: React.FunctionComponent = () => {
  const [alerts, setAlerts] = React.useState<Partial<AlertProps>[]>([]);
  const { isAuthenticated, session, user } = React.useContext(AuthContext);
  const [reload, setReload] = React.useState<boolean>(false);
  const [isNewsExpanded, setIsNewsExpanded] = React.useState(false);
  const [isActiveExpExpanded, setIsActiveExpExpanded] = React.useState(false);
  const [isInactiveExpExpanded, setIsInactiveExpExpanded] = React.useState(false);
  const [isTopResourcesExpanded, setIsTopResourcesExpanded] = React.useState(false);
  const [isUserSummaryExpanded, setIsUserSummaryExpanded] = React.useState(false);

  const history = useHistory();

  React.useEffect(() => {
    if (!isAuthenticated || !session) {
      history.push('/login');
    }
  }, [isAuthenticated, session, history]);

  const { api } = React.useContext(GeneralSettingsContext);
  const options = { credentials: 'include', cachePolicy: 'no-cache' };
  const {
    get: mzGet,
    response: mzResp,
    loading: mzLoading,
    error: mzError,
  } = useFetch(api + '/materialize/materializations', options, []);
  const {
    get: rzGet,
    response: rzResp,
    loading: rzLoading,
    error: rzError,
  } = useFetch(api + '/realize/realizations', options, []);
  const {
    get: resGet,
    response: resResp,
    loading: resLoading,
    error: resError,
  } = useFetch(api + '/realize/resources', options, []);

  const [mtz, setMtz] = React.useState<GetMaterializationsResponse>();
  const [rlz, setRlz] = React.useState<GetRealizationsResponse>();
  const [resources, setResouces] = React.useState<GetResourcesResponse>();

  const mzLoad = React.useCallback(async () => {
    const resp = await mzGet();
    if (mzResp.ok) {
      setMtz(GetMaterializationsResponse.fromJSON(resp));
    } else {
      // setLoadError(resp);
    }
  }, [mzGet, mzResp]);

  React.useEffect(() => {
    mzLoad();
  }, [mzLoad, reload]);

  const rzLoad = React.useCallback(async () => {
    const resp = await rzGet();
    if (rzResp.ok) {
      setRlz(GetRealizationsResponse.fromJSON(resp));
    } else {
      // setLoadError(resp);
    }
  }, [rzGet, rzResp]);

  React.useEffect(() => {
    rzLoad();
  }, [rzLoad, reload]);

  const resLoad = React.useCallback(async () => {
    const resp = await resGet();
    if (rzResp.ok) {
      setResouces(GetResourcesResponse.fromJSON(resp));
    } else {
      // setLoadError(resp);
    }
  }, [resGet, rzResp]);

  React.useEffect(() => {
    resLoad();
  }, [resLoad, reload]);

  if (!isAuthenticated || !session) {
    return <Redirect to="/login" />;
  }

  const addAlert = (title: string, variant: AlertProps['variant']): void => {
    const key = new Date().getTime();
    setAlerts((prevAlerts) => [...prevAlerts, { title, variant, key }]);
  };

  const removeAlert = (key: React.Key) => {
    setAlerts((prevAlerts) => [...prevAlerts.filter((alert) => alert.key !== key)]);
  };

  const doReload = () => setReload(!reload);

  const renderCardContent = (loading: boolean, error: any, content: React.ReactNode) => {
    if (loading) return <Spinner size="lg" />;
    if (error) return <Text color="danger">Error loading data. Please try again.</Text>;
    return content;
  };

  return (
    <PageSection>
      <AlertGroup isToast isLiveRegion></AlertGroup>
      <Grid hasGutter>
        <GridItem span={12}>
          <ExpandableSection
            toggleText={
              <Flex>
                <FlexItem>
                  <NewsIcon />
                </FlexItem>
                <FlexItem>
                  <span className={customToggleStyles}>News</span>
                </FlexItem>
              </Flex>
            }
            onToggle={() => setIsNewsExpanded(!isNewsExpanded)}
            isExpanded={isNewsExpanded}
          >
            <Card>
              <CardBody>
                <News />
              </CardBody>
            </Card>
          </ExpandableSection>
        </GridItem>
        <GridItem span={12}>
          <Card>
            <CardTitle>
              <Flex>
                <FlexItem>
                  <FlaskIcon />
                </FlexItem>
                <FlexItem>Active Experiments</FlexItem>
              </Flex>
            </CardTitle>
            <CardBody>
              {renderCardContent(
                mzLoading,
                mzError,
                <ActiveExperiments mtzs={mtz} addAlert={addAlert} reload={doReload} />
              )}
            </CardBody>
          </Card>
        </GridItem>
        <GridItem span={12}>
          <Card>
            <CardTitle>
              <Flex>
                <FlexItem>
                  <FlaskIcon />
                </FlexItem>
                <FlexItem>Inactive Experiments</FlexItem>
              </Flex>
            </CardTitle>
            <CardBody>
              {renderCardContent(
                mzLoading || rzLoading,
                mzError || rzError,
                <InactiveExperiments mtzs={mtz} rlzs={rlz} addAlert={addAlert} reload={doReload} />
              )}
            </CardBody>
          </Card>
        </GridItem>
        <GridItem span={12}>
          <Card>
            <CardTitle>
              <Flex>
                <FlexItem>
                  <ChartPieIcon />
                </FlexItem>
                <FlexItem>Top Resources</FlexItem>
              </Flex>
            </CardTitle>
            <CardBody>
              {renderCardContent(resLoading, resError, <TopResources count={5} resources={resources} />)}
            </CardBody>
          </Card>
        </GridItem>
        <GridItem span={12}>
          <Card>
            <CardTitle>
              <Flex>
                <FlexItem>
                  <UserIcon />
                </FlexItem>
                <FlexItem>User Summary</FlexItem>
              </Flex>
            </CardTitle>
            <CardBody>
              {renderCardContent(user === undefined, undefined, <UserSummary user={user} reload={doReload} />)}
            </CardBody>
          </Card>
        </GridItem>
      </Grid>
    </PageSection>
  );
};

export { Dashboard };
