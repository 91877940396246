// This component renders the content of a specific tutorial in the sidebar.
// It handles the step-by-step progression through the tutorial.
// Key features:
// 	• Displays tutorial steps
// 	• Tracks completed steps
// 	• Provides interactive elements (e.g., checkboxes for step completion)
// 	• Uses the eventEmitter to listen for specific actions and provide feedback
// 	• Handles tutorial completion and cleanup





import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Title,
  Progress,
  Stack,
  StackItem,
  ExpandableSection,
  Checkbox,
  Text,
  Flex,
  FlexItem,
  Button,
  TextContent,
  TextVariants,
  Label,
  Alert,
  AlertActionCloseButton,
} from '@patternfly/react-core';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { LightbulbIcon, CopyIcon, CheckIcon, PlayIcon, RepositoryIcon, TimesIcon, RedoIcon } from '@patternfly/react-icons';
import { Tutorial, TutorialStep } from './Types';
import confetti from 'canvas-confetti';
import { eventEmitter } from './EventEmitter';


interface TutorialContentProps {
  tutorial: Tutorial;
  onClose: () => void;
  onProgressChange: () => void;
  onComplete: () => void;
}

interface TutorialProgress {
  completedSteps: number[];
  expandedSteps: number[];
}

const TutorialContent: React.FC<TutorialContentProps> = ({ tutorial, onClose, onProgressChange, onComplete }) => {
  const [completedSteps, setCompletedSteps] = useState<Set<number>>(new Set());
  const [expandedSteps, setExpandedSteps] = useState<Set<number>>(new Set());
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState<'Start' | 'In Progress' | 'Completed'>('Start');
  const history = useHistory();
  const [feedback, setFeedback] = useState<{ type: 'success' | 'info' | 'warning'; message: string } | null>(null);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);



const handleAction = (action: string, data: any) => {

  const stepIndex = tutorial.steps.findIndex(step => 
    step.expectedAction === action || 
    step.additionalAction === action ||
    step.additionalActions?.includes(action)
  );

  if (stepIndex !== -1) {
    const step = tutorial.steps[stepIndex];

    let successMessage;
    if (action === step.expectedAction) {
      successMessage = step.successFeedback;
      setCurrentStepIndex(stepIndex + 1);
    } else if (action === step.additionalAction) {
      successMessage = step.additionalSuccessFeedback;
    } else if (step.additionalActions?.includes(action)) {
      successMessage = step.additionalFeedback?.[action];
    }

    if (successMessage) {
      successMessage = successMessage.replace(
        /{(\w+)}/g,
        (match, p1) => data[p1] || match
      );
    } else {
      successMessage = `Great job! You've completed this action.`;
    }

    setFeedback({ 
      type: 'success', 
      message: successMessage
    });
  } else {
    const currentStep = tutorial.steps[currentStepIndex];
    const failureMessage = currentStep && currentStep.failureFeedback 
      ? currentStep.failureFeedback 
      : "It looks like you haven't completed this step yet. Need any help?";
    setFeedback({ type: 'info', message: failureMessage });
  }
};

useEffect(() => {
  if (progress === 100 && currentStepIndex === tutorial.steps.length - 1) {
    celebrateCompletion();
    onComplete();
  } else if (progress > 0) {
    onProgressChange();
  }
}, [progress, currentStepIndex, onProgressChange, onComplete, tutorial.steps.length]);

  useEffect(() => {
    const actionHandlers = {
      experimentCreated: 'Experiment created event received',
      realizationCreated: 'Realization created event received',
      materializationCreated: 'Materialization created event received',
      xdcCreated: 'XDC created event received',
      xdcAttached: 'XDC attached event received',
      xdcDetached: 'XDC detached event received',
      dematerialized: 'Dematerialization started event received',
      relinquished: 'Realization relinquished event received',
      modelPushed: 'Model pushed event received',
      experimentDeleted: 'Experiment deleted event received'  
    };

    Object.entries(actionHandlers).forEach(([action, logMessage]) => {
      const handler = (data) => {
        console.log(logMessage);
        handleAction(action, data);
      };
      eventEmitter.on(action, handler);
      return () => eventEmitter.off(action, handler);
    });
  }, [tutorial.steps, completedSteps, currentStepIndex]);


  // Load progress from localStorage when component mounts
  useEffect(() => {
    const savedProgress = localStorage.getItem(`tutorial_progress_${tutorial.id}`);
    if (savedProgress) {
      const { completedSteps, expandedSteps } = JSON.parse(savedProgress) as TutorialProgress;
      setCompletedSteps(new Set(completedSteps));
      setExpandedSteps(new Set(expandedSteps));
      setProgress((completedSteps.length / tutorial.steps.length) * 100);
    }
  }, [tutorial.id, tutorial.steps.length]);

  // Save progress to localStorage whenever it changes
  useEffect(() => {
    const progressData: TutorialProgress = {
      completedSteps: Array.from(completedSteps),
      expandedSteps: Array.from(expandedSteps),
    };
    localStorage.setItem(`tutorial_progress_${tutorial.id}`, JSON.stringify(progressData));
  }, [completedSteps, expandedSteps, tutorial.id]);



  const celebrateCompletion = () => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 }
    });
  };

  const resetTutorial = () => {
    setCompletedSteps(new Set());
    setExpandedSteps(new Set());
    setProgress(0);
    localStorage.removeItem(`tutorial_progress_${tutorial.id}`);
    onProgressChange();
  };
  

  const handleStepToggle = (stepIndex: number) => {
    setCompletedSteps(prev => {
      const newCompletedSteps = new Set(prev);
      if (newCompletedSteps.has(stepIndex)) {
        newCompletedSteps.delete(stepIndex);
      } else {
        newCompletedSteps.add(stepIndex);
        setExpandedSteps(prev => {
          const newExpandedSteps = new Set(prev);
          newExpandedSteps.delete(stepIndex);
          return newExpandedSteps;
        });
      }
      const newProgress = (newCompletedSteps.size / tutorial.steps.length) * 100;
      setProgress(newProgress);
  
      // Check if all steps are completed
      if (newCompletedSteps.size === tutorial.steps.length) {
        celebrateCompletion();
        onComplete();
      }
  
      return newCompletedSteps;
    });
  };

  const handleExpand = (stepIndex: number, isExpanded: boolean) => {
    setExpandedSteps(prev => {
      const newExpandedSteps = new Set(prev);
      if (isExpanded) {
        newExpandedSteps.add(stepIndex);
      } else {
        newExpandedSteps.delete(stepIndex);
      }
      return newExpandedSteps;
    });
  };

  const CustomCheckbox = ({ isChecked, onChange, label }) => (
    <div
      onClick={onChange}
      style={{
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
        borderRadius: '5px',
        backgroundColor: isChecked ? '#E7F1FF' : 'transparent',
        transition: 'background-color 0.3s ease',
      }}
    >
      <div
        style={{
          width: '24px',
          height: '24px',
          borderRadius: '50%',
          border: isChecked ? '2px solid #0066CC' : '2px solid #CCCCCC',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginRight: '10px',
          transition: 'all 0.3s ease',
        }}
      >
        {isChecked && <CheckIcon style={{ color: '#0066CC' }} />}
      </div>
      <span style={{ fontWeight: isChecked ? 'bold' : 'normal' }}>{label}</span>
    </div>
  );

  const InlineIcon = ({ icon: Icon, text }) => (
    <span style={{ display: 'inline-flex', alignItems: 'center', marginRight: '5px' }}>
      <Icon style={{ marginRight: '5px' }} /> {text}
    </span>
  );

  const CodeBlock = ({ code, language = 'python' }) => {
    const [copied, setCopied] = useState(false);

    const copyToClipboard = () => {
      navigator.clipboard.writeText(code);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    };

    return (
      <div style={{ position: 'relative', marginBottom: '1rem' }}>
        <Button
          variant="plain"
          onClick={copyToClipboard}
          style={{
            position: 'absolute',
            top: '0.5rem',
            right: '0.5rem',
            zIndex: 1,
            color: 'white',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            padding: '0.25rem 0.5rem',
            borderRadius: '4px',
          }}
          icon={<CopyIcon />}
        >
          {copied ? 'Copied!' : 'Copy'}
        </Button>
        <SyntaxHighlighter language={language} style={tomorrow} customStyle={{ fontSize: '0.9em' }}>
          {code.trim()}
        </SyntaxHighlighter>
      </div>
    );
  };

  const TipBox = ({ children, variant = 'blue' }) => (
    <div
      style={{
        backgroundColor: variant === 'blue' ? '#E7F1FF' : '#FFF8E1',
        borderLeft: `4px solid ${variant === 'blue' ? '#0066CC' : '#FFD700'}`,
        padding: '1rem',
        marginBottom: '1rem',
        display: 'flex',
        alignItems: 'flex-start',
      }}
    >
      <LightbulbIcon
        color={variant === 'blue' ? '#0066CC' : '#FFD700'}
        style={{ marginRight: '0.5rem', flexShrink: 0 }}
      />
      <div>{children}</div>
    </div>
  );

  const renderContentWithLinks = (content: string) => {
    const parts = content.split(/(\[LINK:[^\]]+\]|\[ICON:[^\]]+\])/g);
    return parts.map((part, index) => {
      if (part.startsWith('[LINK:')) {
        const linkText = part.slice(6, -1);
        return (
          <span
            key={index}
            style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() => handleNavigation(linkText)}
          >
            {linkText}
          </span>
        );
      } else if (part.startsWith('[ICON:')) {
        const iconName = part.slice(6, -1).toLowerCase();
        return renderIcon(iconName, index);
      }
      return part; // Return other parts as plain text
    });
  };

  const renderIcon = (iconName: string, key: number) => {
    switch (iconName) {
      case 'compile':
        return <PlayIcon key={key} />;
      case 'push':
        return <RepositoryIcon key={key} />;
      default:
        return null;
    }
  };
  const handleNavigation = (destination: string) => {
    switch (destination.toLowerCase()) {
      case 'projects':
        history.push('/project');
        break;
      case 'experiments':
        history.push('/experiment');
        break;
      case 'model editor':
        history.push('/models');
        break;
      case 'revisions':
        history.push('/experiment');
        break;
      case 'realizations':
        history.push('/realizations');
        break;
      case 'xdc':
      case 'xdcs':
        history.push('/xdcs');
        break;
      case 'materialization':
      case 'materializations':
        history.push('/materializations');
        break;
      case 'resources':
        history.push('/resources');
        break;
      default:
        console.log(`Navigation to ${destination} not implemented`);
    }
  };


  const formatContent = (content: string) => {
    const codeBlockRegex = /\[CODE_BLOCK\]([\s\S]*?)\[\/CODE_BLOCK\]/;
    const parts = content.split(codeBlockRegex);
    let currentNumber = 0;

    return parts.map((part, index) => {
      if (index % 2 === 1) {
        // This is the code block content
        return <CodeBlock key={`code-block-${index}`} code={part.trim()} />;
      } else {
        // This is regular content
        const [formattedContent, lastNumber] = formatContentPart(part, currentNumber);
        currentNumber = lastNumber;
        return formattedContent;
      }
    });
  };

  const formatContentPart = (content: string, startNumber: number) => {
    const paragraphs = content.split('\n\n');
    let currentNumber = startNumber;
  
    const formattedParagraphs = paragraphs
      .map((paragraph, index) => {
        if (paragraph.trim() === '') return null;
  
        if (paragraph.startsWith('Quick Navigation Tip:')) {
          return (
            <TipBox key={`tip-${index}`} variant="blue">
              {renderContentWithLinks(paragraph.substring(21).trim())}
            </TipBox>
          );
        }
  
        if (paragraph.startsWith('Tip:')) {
          return (
            <TipBox key={`tip-${index}`} variant="yellow">
              {renderContentWithLinks(paragraph.substring(4).trim())}
            </TipBox>
          );
        }
  
        if (/^\d+\./.test(paragraph)) {
          const lines = paragraph.split('\n');
          return (
            <div key={`list-item-${index}`} style={{ marginBottom: '1rem' }}>
              {lines.map((line, lineIndex) => {
                if (/^\d+\./.test(line)) {
                  return (
                    <Text key={`main-item-${lineIndex}`}>
                      {renderContentWithLinks(line)}
                    </Text>
                  );
                } else if (line.trim().startsWith('•') || line.trim().startsWith('*')) {
                  return (
                    <ul key={`sub-list-${lineIndex}`} style={{ marginTop: '0.5rem', marginLeft: '2rem', marginBottom: '0.5rem' }}>
                      <li>{renderContentWithLinks(line.trim().replace(/^[•*]\s*/, ''))}</li>
                    </ul>
                  );
                } else {
                  return (
                    <Text key={`additional-${lineIndex}`} style={{ marginLeft: '1.5rem' }}>
                      {renderContentWithLinks(line.trim())}
                    </Text>
                  );
                }
              })}
            </div>
          );
        }
  
        return (
          <Text key={`text-${index}`} style={{ marginBottom: '1rem' }}>
            {renderContentWithLinks(paragraph)}
          </Text>
        );
      })
      .filter(Boolean);
  
    return [formattedParagraphs, currentNumber];
  };

  const renderStep = (step: TutorialStep, index: number) => (
    <ExpandableSection
      toggleText={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              width: '16px',
              height: '16px',
              borderRadius: '50%',
              backgroundColor: completedSteps.has(index) ? '#0066CC' : '#CCCCCC',
              marginRight: '10px',
            }}
          />
          <span style={{ fontWeight: completedSteps.has(index) ? 'bold' : 'normal' }}>{step.title}</span>
        </div>
      }
      isExpanded={expandedSteps.has(index)}
      onToggle={(_, isExpanded) => handleExpand(index, isExpanded)}
      key={index}
    >
      <Stack hasGutter>
        <StackItem>
          <div className="tutorial-content" style={{ padding: '0 20px' }}>
            {formatContent(step.content)}
          </div>
        </StackItem>
        <StackItem>
          <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
            <FlexItem>
              <CustomCheckbox
                isChecked={completedSteps.has(index)}
                onChange={() => handleStepToggle(index)}
                label="Mark as complete"
              />
            </FlexItem>
          </Flex>
        </StackItem>
      </Stack>
    </ExpandableSection>
  );


    return (
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <div className="tutorial-header">
          <Flex justifyContent={{ default: 'justifyContentSpaceBetween' }} alignItems={{ default: 'alignItemsCenter' }}>
            <FlexItem>
              <Title headingLevel="h2" size="xl">
                {tutorial.title}
              </Title>
            </FlexItem>
            <FlexItem>
              <Button variant="plain" aria-label="Close tutorial" onClick={onClose} icon={<TimesIcon />} />
            </FlexItem>
          </Flex>
          <Progress
            value={progress}
            title="Tutorial Progress"
            size="sm"
            measureLocation="outside"
            style={{ marginTop: '1rem' }}
          />
        </div>
        {feedback && (
          <Alert
            variant={feedback.type}
            title={feedback.message}
            actionClose={<AlertActionCloseButton onClose={() => setFeedback(null)} />}
            style={{ margin: '1rem 0' }}
          />
        )}
        <div className="sidebar-content" style={{ flex: 1, overflowY: 'auto' }}>
          <Stack hasGutter>
            {tutorial.steps.map(renderStep)}
          </Stack>
        </div>
        {progress === 100 && (
          <div className="tutorial-completion" style={{ marginTop: 'auto', padding: '1rem' }}>
            <Text>Congratulations! You've completed the tutorial.</Text>
            <Button variant="primary" onClick={resetTutorial} icon={<RedoIcon />}>
              Restart Tutorial
            </Button>
          </div>
        )}
      </div>
    );
};

export default TutorialContent;