import * as React from 'react';
import { useParams } from 'react-router-dom';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import { useFetch } from 'use-http';
import { RealizationNodes } from '@app/Realization/Realization';
import { LocalizeDate } from '@app/lib/TaskStatusUtils';

import {
  PageSection,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Tabs,
  Tab,
  TabTitleText,
  Spinner,
  Bullseye,
  Alert,
  AlertGroup,
  AlertVariant,
  AlertActionCloseButton,
  Form,
  FormGroup,
  TextInput,
} from '@patternfly/react-core';
import { Table /* data-codemods */, headerCol, sortable, Thead, Tbody, Tr, Td, Th } from '@patternfly/react-table';
import { ActionList } from '@app/lib/ActionList';
import { TaskStatusTable } from '@app/lib/TaskStatusTable';
import { GRPCError } from '@app/lib/error';

import { useTranslation } from 'react-i18next';

import {
  RebootMaterializationMode,
  RebootMaterializationRequest,
  Materialization as PortalMtz,
  Ingresses,
  GetMaterializationResponse,
} from '@mergetb/api/portal/v1/materialize_types';
import { TaskSummary } from '@mergetb/api/mergetb/tech/reconcile/taskstatus';
import { toTitleCase } from '@app/lib/util';
// import { Ingress } from '@mergetb/api/portal/v1/workspace_types';

const Materialization: React.FunctionComponent = () => {
  const { pid, eid, rid } = useParams();
  const conf = React.useContext(GeneralSettingsContext);
  const [activeTab, setActiveTab] = React.useState(0);

  const options = { credentials: 'include' };

  const mz_url = conf.api + '/materializev2/materializations/' + pid + '/' + eid + '/' + rid;
  const st_url = mz_url + '?statusMS=-1';

  const { t } = useTranslation();

  const [loadError, setLoadError] = React.useState<GRPCError>();
  const [mtz, setMtz] = React.useState<PortalMtz>();
  const [ingresses, setIngresses] = React.useState<Ingresses>();
  const [summary, setSummary] = React.useState<TaskSummary>();
  const { loading, error, get, response } = useFetch(mz_url, options, []);

  const load = React.useCallback(async () => {
    const resp = await get();
    if (response.ok) {
      const mtzResp = GetMaterializationResponse.fromJSON(resp);
      setMtz(mtzResp.materialization);
      setIngresses(mtzResp.ingresses);
      setSummary(mtzResp.status);
    } else {
      setLoadError(resp);
    }
  }, [get, response]);

  React.useEffect(() => {
    load();
  }, [load]);

  const crumbs = (
    <PageSection>
      <Breadcrumb>
        <BreadcrumbItem to="/project">Projects</BreadcrumbItem>
        <BreadcrumbItem to={'/project/' + pid}>{pid}</BreadcrumbItem>
        <BreadcrumbItem to="/experiment">Experiments</BreadcrumbItem>
        <BreadcrumbItem to={'/project/' + pid + '/experiment/' + eid}>{eid}</BreadcrumbItem>
        <BreadcrumbItem to="/realizations">{toTitleCase(t('realizations'))}</BreadcrumbItem>
        <BreadcrumbItem to={'/realizations/' + pid + '/' + eid + '/' + rid}>{rid}</BreadcrumbItem>
        <BreadcrumbItem to="/materializations">{toTitleCase(t('materializations'))}</BreadcrumbItem>
        <BreadcrumbItem>
          {rid}.{eid}.{pid}
        </BreadcrumbItem>
      </Breadcrumb>
    </PageSection>
  );

  const tabClick = (event, index) => {
    setActiveTab(index);
  };

  const getter = (data) => {
    return data.status;
  };

  return (
    <React.Fragment>
      {crumbs}
      <PageSection>
        <Card id={rid + '-card-id'}>
          <CardBody>
            {error && !loadError && (
              <Alert variant="danger" title="Error">
                Error loading
              </Alert>
            )}
            {error && loadError && (
              <Alert variant="danger" title="Response Error">
                <pre>{loadError.message}</pre>
              </Alert>
            )}
            {loading && (
              <Bullseye>
                <Spinner size="sm" />
              </Bullseye>
            )}
            {mtz && (
              <div>
                <Tabs activeKey={activeTab} onSelect={tabClick}>
                  <Tab eventKey={0} title={<TabTitleText>Status</TabTitleText>}>
                    <TaskStatusTable
                      kind={rid + '-tst'}
                      url={st_url}
                      getter={getter}
                      ongoingfrequency={5000}
                      completedfrequency={30000}
                      scalingfrequency={1.0 / 10.0}
                    />
                  </Tab>
                  <Tab eventKey={1} title={<TabTitleText>Details</TabTitleText>}>
                    <MaterializationNodes mtz={mtz} ingresses={ingresses} summary={summary} active={true} />
                  </Tab>
                </Tabs>
              </div>
            )}
          </CardBody>
        </Card>
      </PageSection>
    </React.Fragment>
  );
};

type MtzNodesProps = {
  mtz: PortalMtz;
  ingresses: Ingresses | undefined;
  summary: TaskSummary | undefined;
  active: boolean;
};

const MaterializationNodes: React.FunctionComponent<MtzNodesProps> = ({ mtz, ingresses, summary, active }) => {
  const conf = React.useContext(GeneralSettingsContext);
  type alertProps = {
    title: string;
    variant: AlertVariant;
  };
  const [alerts, setAlerts] = React.useState<Array<alertProps>>([]);

  const rid = mtz.rid;
  const eid = mtz.eid;
  const pid = mtz.pid;

  const nodecells = [
    { title: 'Name', key: 'name', transforms: [sortable] },
    { title: 'Experiment Addresses', key: 'experimentAddresses' },
    { title: 'Infranet Address', key: 'infranetAddress' },
    { title: 'Kind', key: 'kind' },
    { title: 'Resource', key: 'resource' },
    { title: 'Image', key: 'image' },
    { title: 'Facility', key: 'facility' },
  ];

  const formatAddrs = (sockets) => {
    let val: string[] = [];
    sockets.forEach((s) => {
      val = [...val, ...s.addrs];
    });
    return val.join(', ');
  };

  const metals = mtz.metal.map((x) => [
    x.model?.id,
    formatAddrs(x.model?.sockets),
    x.infranetAddr,
    'BareMetal',
    x.resource,
    x.model?.image?.value,
    x.facility,
  ]);

  const vms = mtz.vms.map((x) => [
    x.vmAlloc?.node,
    formatAddrs(x.vmAlloc?.model?.sockets),
    x.infranetAddr,
    'VirtualMachine',
    x.vmAlloc?.resource,
    x.vmAlloc?.model?.image?.value,
    x.vmAlloc?.facility,
  ]);

  const noderows = [...metals, ...vms].map((x) => ({
    name: x[0],
    experimentAddresses: x[1],
    infranetAddress: x[2],
    kind: x[3],
    resource: x[4],
    image: x[5],
    facility: x[6],
  }));

  const nodeactions = React.useMemo(() => {
    // helper function to reboot nodes
    const doReboot = (nids: string[], mode: RebootMaterializationMode, message: string) => {
      const req = RebootMaterializationRequest.fromJSON({
        project: pid,
        experiment: eid,
        realization: rid,
        hostnames: nids,
        allNodes: false,
        mode: mode,
      });

      fetch(conf.api + '/materialize/reboot/' + pid + '/' + eid + '/' + rid, {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(req),
      })
        .then((resp) => {
          if (!resp.ok) {
            return resp
              .json()
              .catch(() => {
                // could not parse json
                addAlert(resp.statusText, AlertVariant.danger);
              })
              .then((json) => {
                addAlert(json.message, AlertVariant.danger);
              });
          }
          // success
          addAlert(message, AlertVariant.success);
          return;
        })
        .catch((error) => {
          addAlert(error.message, AlertVariant.danger);
        });
    };

    return [
      {
        title: 'Warm Reboot',
        onClick: (_event, _rowID, rowData) => {
          doReboot([rowData.name], RebootMaterializationMode.Reboot, rowData.name + ': Warm reboot requested');
        },
      },
      {
        title: 'Power Cycle',
        onClick: (_event, _rowID, rowData) => {
          doReboot([rowData.name], RebootMaterializationMode.Cycle, rowData.name + ': Power cycle requested');
        },
      },
      {
        title: 'Reimage',
        onClick: (_event, _rowID, rowData) => {
          doReboot([rowData.name], RebootMaterializationMode.Reimage, rowData.name + ': Reimage requested');
        },
      },
      {
        isSeparator: true,
      },
      {
        title: 'Console',
        disabled: true,
      },
    ];
  }, [conf.api, rid, eid, pid]);

  const addAlert = (t: string, v: AlertVariant) => {
    setAlerts((prev) => [...prev, { title: t, variant: v, key: new Date().getTime() }]);
  };

  /*
  const removeAlert = (key) => {
    setAlerts((prev) => [...alerts.filter((el) => el.key !== key)]);
  };
  */

  const removeAlert = (key) => {
    setAlerts([...alerts.filter((el) => el.key !== key)]);
  };

  const notifications = (
    <AlertGroup isToast>
      {alerts.map((p, i) => (
        <Alert
          variant={p.variant}
          title={p.title}
          timeout={8000}
          key={i}
          actionClose={
            <AlertActionCloseButton
              title={p.title}
              variantLabel={`{p.variant} alert`}
              onClose={() => removeAlert(p.key)}
            />
          }
        />
      ))}
    </AlertGroup>
  );

  const details = (
    <Card id="deetsCard">
      <CardHeader>
        <CardTitle id="deetsCardTitle">Details</CardTitle>
      </CardHeader>
      <CardBody>
        <Form isHorizontal>
          <FormGroup fieldId="Created" label="Created">
            <TextInput value={LocalizeDate(summary?.FirstUpdated?.toString())} aria-label="Created" isDisabled />
          </FormGroup>
        </Form>
      </CardBody>
    </Card>
  );

  const nodes = (
    <Card>
      <CardHeader>
        <CardTitle id="nodesTitles">Nodes</CardTitle>
      </CardHeader>
      <CardBody>
        <ActionList
          reload={0}
          kind="Materialization"
          columns={nodecells}
          rows={noderows}
          actions={active ? nodeactions : []}
          isCardView={false}
          CardComponent={null}
          searchTerm=""
          setSearchTerm={() => {}}
          reloadTrigger={0}
          onSearchResults={() => {}}
        />
      </CardBody>
    </Card>
  );

  const ingcols = {
    protocol: 'Protocol',
    hostname: 'Hostname',
    hostport: 'Host Port',
    hostaddr: 'Host Address',
    gateway: 'Gateway',
    gatewayport: 'Gateway Port',
    ingress: 'Ingress',
  };

  const ingressesCard = (
    <Card>
      <CardHeader>
        <CardTitle id="ingressTitle">Ingresses</CardTitle>
      </CardHeader>
      <CardBody>
        <Table aria-label="ingresses" variant="compact" borders={false}>
          <Thead>
            <Tr>
              <Th>{ingcols.ingress}</Th>
              <Th>{ingcols.protocol}</Th>
              <Th>{ingcols.hostname}</Th>
              <Th>{ingcols.hostport}</Th>
              <Th>{ingcols.hostaddr}</Th>
              <Th>{ingcols.gateway}</Th>
              <Th>{ingcols.gatewayport}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {ingresses &&
              ingresses.ingresses.map((ing, i) => {
                return (
                  <Tr key={i}>
                    <Td dataLabel={ingcols.ingress}>
                      {ing.protocol === 'http' || ing.protocol === 'https' ? (
                        <a href={ing.ingress + '/'}>{ing.ingress}/</a>
                      ) : (
                        ing.ingress
                      )}
                    </Td>
                    <Td dataLabel={ingcols.protocol}>{ing.protocol}</Td>
                    <Td dataLabel={ingcols.hostname}>{ing.hostname}</Td>
                    <Td dataLabel={ingcols.hostport}>{ing.hostport}</Td>
                    <Td dataLabel={ingcols.hostaddr}>{ing.hostaddr}</Td>
                    <Td dataLabel={ingcols.gateway}>{ing.gateway}</Td>
                    <Td dataLabel={ingcols.gatewayport}>{ing.gatewayport}</Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  );

  return (
    <React.Fragment>
      {alerts.length != 0 && notifications}
      {details}
      {nodes}
      {ingresses && ingressesCard}
    </React.Fragment>
  );
};

export { Materialization };
